import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/components/sevice/alert.service';
import { NotificationService } from 'src/app/components/sevice/notification.service';

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class ListNotificationsComponent implements OnInit {

  settings = {
    columns: {
      notificationId: {
        title: 'Notification Id',
        filter: true,
      },
      notificationType: {
        title: 'Notification Type',
        filter: true,
      },
      notificationName:{
        title: 'Notification Name',
        filter: true,
      },
      isPublished:{
        title: 'Status',
        filter: true,
        type:'html',
        valuePrepareFunction:(value) =>{
          if(value)
            return 'Published';
          else
            return "Unpublished";  
        }
      },
      Date:{
        title: 'Updated Date',
        filter: true,
      }
    },
    actions: {
      columnTitle: 'More',
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      filter: false,
      custom: [{ name: 'verify',type: 'html', title: '<i class="fa fa-eye grid-icons" title ="Verfiy"></i>' }]
    }
  };
  notificationData:any;
  constructor(private notificationService: NotificationService,private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }

  ngOnInit(): void {
    this.notificationData=[];
    this.getNotifications();

  }
  getNotifications(){
    this.notificationService.getsavedNotificationAlerts("All",null,null).subscribe((response:any) =>{
      if(response['data'] != null || response['data'].size != 0){
        this.notificationData = response['data'].reverse();
        for(let i=0;i < this.notificationData.length;i++){
            let  date = new Date(this.notificationData[i]['updatedDate']);
            if(date.getDate() < 10)
            this.notificationData[i]['Date'] = "0"+date.getDate()+'-' + (date.getMonth()+1) + '-'+date.getFullYear();
            else
            this.notificationData[i]['Date'] = date.getDate()+'-' + (date.getMonth()+1) + '-'+date.getFullYear();

        }
      }else{
        this.toastr.showSuccess("", "");
      }
    },error =>{
      this.toastr.showError("Failed to retrive data", "");
    })

  }



  onCustomAction(event) {
    switch (event.action) {
      case 'verify':
            this.router.navigate(['/edit-notifications',event.data.notificationId]);
        break;

    }
  }

}
