<div class="container-fluid" *ngIf="showVerifyContent">
  <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">

    <div class="container-fluid">

      <div class="row">
        <div (click)="Back()" class="col-md-auto">
          <button class="btn border jh-btn-primary jh-back-btn">Back</button>
        </div>
        <div class="text-center jh-heading col">
          Verify Details
        </div>

      </div>










    </div>

    <div class="row">
      <div class="col-md-3 px-3 shadow py-3">
        <div class="col-md-12 text-center">
          <!-- <form action="upload.php" method="post" enctype="multipart/form-data">
            <label for="fileToUpload">
              <div class="profile-pic shadow"
                style="background-image: url('https://widgetwhats.com/app/uploads/2019/11/free-profile-photo-whatsapp-4.png')">
                <span class="glyphicon glyphicon-camera"></span>
                <span>Change Image</span>
              </div>
            </label>
            <input type="File" name="fileToUpload" id="fileToUpload">
          </form> -->
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Salutation</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.salutation" placeholder="First Name"
            autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">First Name</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.firstName" placeholder="First Name"
            autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Last Name</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.lastName" placeholder="Last Name"
            autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Email</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.email" placeholder="Email"
            autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4">Member Id</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.memberId" autocomplete="off"
            disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Mobile Number</div>
          <input type="number" class="form-control" id="" [(ngModel)]="registerMember.mobileNumber"
            placeholder="Mobile number" autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Whatsapp Number</div>
          <input type="number" class="form-control" id="" [(ngModel)]="registerMember.whatsappNumber"
            placeholder="Whatsapp number" autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Plot No</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.plotNo" placeholder="Plot number"
            autocomplete="off" disabled>
        </div>
        <div class="col-md-12 my-3 jh-login-input">
          <div class="form-label text-start ms-4 required">Road No</div>
          <input type="text" class="form-control" id="" [(ngModel)]="registerMember.roadNo" placeholder="Road number"
            autocomplete="off" disabled>
        </div>


      </div>
      <div class="col-md-8 px-4 shadow ms-4 py-3">
        <div class="col-md-11 mx-auto">
          <div class="row">
            <div class="container-fluid search-row">
              <div class="row">
                <div class="col-md-7 my-3 jh-search">
                  <div class="row">
                    <div class="jh-search-text col-lg-6 border border-dark col-md-3 col-sm-12 pe-0 sharp-border">
                      <input type="text" class="form-control search-slt border-0 my-1" [(ngModel)]="searchValue"
                        placeholder="Search">
                    </div>
                    <div class="dropdown border border-dark col-lg-3 px-0">
                      <select class='select-option' style="width:123px;height:42px;border:none; border-radius: 0px !important;"
                        [(ngModel)]="selectedSearchFilter">
                        <option value=''>Search by</option>
                        <option value='firstName'>First Name</option>
                        <option value='lastName'>Last Name</option>
                        <option value='mobileNumber'>Mobile Number</option>
                        <option value='email'>Email</option>
                        <option value='plotNo'>Plot No</option>
                        <option value='roadNo'>Road No</option>
                        <option value='memberId'>Member Id</option>
                      </select>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                      <button type="button"
                        class="btn border border-dark jh-btn-primary wrn-btn sharp-border jh-search-btn "
                        (click)="onSerachChange()">Search</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 my-3 jh-external-btns">
                  <div class="row">
                    <div class="col-md-5" *ngIf="showApproveButton">
                      <button class="btn jh-btn-green ms-2" (click)="open(approveModal)">Approve </button>
                    </div>
                    <div class="col-md-5" *ngIf="showRejectButton">
                      <button class="btn jh-btn-third ms-2" (click)="open(rejectModal)">Reject</button>
                    </div>
                    <div class="col-md-7 my-0" *ngIf="showInsertButton">
                      <button class="btn jh-btn-green ms-2" (click)="openForm(registerMember)">Create New</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">

              <div class="col-md-8 offset-md-1" *ngIf="showDetailsForm">
                <div (click)="Clearform()" class="col text-end">
                  <button class="btn border jh-btn-primary jh-clear-btn bg-secondary text-white">Clear</button>
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Salutation</div>
                  <select class='select-option col-md-5' [(ngModel)]="existMember.salutation">
                    <option value=''>Select</option>
                    <option value='Mr'>Mr</option>
                    <option value='Mrs'>Mrs</option>
                    <option value='Mrs'>Ms</option>
                  </select>
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">First Name</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.firstName"
                    placeholder="First Name" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Last Name</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.lastName"
                    placeholder="Last Name" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Email</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.email" placeholder="Email"
                    autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Member Type</div>
                  <select class='select-option col-md-5' [(ngModel)]="existMember.memberType">
                    <option value=''>Select</option>
                    <option value='Member_resident'>Member_resident</option>
                    <option value='Member_non_resident'>Member_non_resident</option>
                    <option value='Non_member_resident'>Non_member_resident</option>
                    <option value='Waitlisted_member'>Waitlisted_member</option>
                  </select>
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Member Id</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.memberId" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Mobile Number</div>
                  <input type="text" class="form-control" maxlength="10" id="" [(ngModel)]="existMember.mobileNumber"
                    placeholder="Mobile number" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Whatsapp Number</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.whatsappNumber"
                    placeholder="Whatsapp number" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 required">Address</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.address"
                    placeholder="Address" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4">Plot No</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.plotNo"
                    placeholder="Plot number" autocomplete="off">
                </div>
                <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4">Road No</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.roadNo"
                    placeholder="Road number" autocomplete="off">
                </div>
                <!-- <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4">Transferred Date</div>
                  <input class="form-control" id="" (click)="d.toggle()" placeholder="yyyy-mm-dd" ngbDatepicker
                    #d="ngbDatepicker" [(ngModel)]="dateModel.transferredDate">
                  <div class="input-group-append jh-dt-icon">
                    <button class="btn btn-outline-secondary calendar" type="button"></button>
                  </div>
                </div> -->
                <!-- <div class="col-md-12 my-3 jh-login-input">
                  <div class="form-label text-start ms-4 ">Transferred from</div>
                  <input type="text" class="form-control" id="" [(ngModel)]="existMember.transferredFrom"
                    placeholder="Transferred from" autocomplete="off">
                </div> -->
              </div>

              <div class="col-md-auto">
                <ng2-smart-table [settings]="settings" *ngIf="showGrid" [source]="source"
                  (userRowSelect)="onRowSelect($event)">
                </ng2-smart-table>
                <div class="col text-center mt-5 jh-search-error">
                  <h3 *ngIf="showLabel">Please search to fetch member
                    details.</h3>
                  <h3 *ngIf="showNoRecord">No matching records found.</h3>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>



    </div>

  </div>
  <ng-template #approveModal let-modal>
    <div class="modal-header jh-member-approve">
      <h4 class="modal-title" id="modal-basic-title">Are you sure</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to approve the member?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark"
        (click)="UpdateStatus('Approve');modal.close('Save click')">Ok</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
  </ng-template>
  <ng-template #rejectModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Are you sure</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      Do you want to reject the member?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark"
        (click)="UpdateStatus('Reject');modal.close('Save click')">Ok</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
  </ng-template>
</div>
<div class="d-flex justify-content-center" *ngIf="showLoader" style="
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
transform: translate(0%, 50%);
">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>