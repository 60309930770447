<div class="container-fluid">
    <div class="row">
      <div class="col-md-2 my-3">
        <side-nav-bar></side-nav-bar>
      </div>
      <div class="col-md-10 my-3">  
        <div class="container-fluid px-0">
            <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
                <div class="row">
                    <div class="col-md-12 "> 
                        <div class="text-center jh-heading" >Transfer Member</div> 
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="btn border jh-btn-primary jh-back-btn" (click)="open(approveModal)">Transfer Request</button> 
                    </div>
                </div>   
                <div class="row mt-5">
                    <div class="col-md-6" >
                        <div class="card p-3" style="box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);">
                            <h5>Member Transfer From</h5>
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Search" [(ngModel)]="permanentSearchValue">
                                </div>
                                <select name="" id="" class="col-md-4" [(ngModel)]="permanentSelectedSearchFilter">
                                    <option value="">Search By</option>
                                    <option value="firstName">First Name</option>
                                    <option value="lastName">Last Name</option>
                                    <option value="mobileNumber">Mobile Number</option>
                                    <option value="email">Email</option>
                                    <option value="plotNumber">Plot Number</option>
                                    <option value="roadNumber">Road Number</option>
                                    <option value="memberId">Member Id</option>
                                </select>
                                <div class="col-md-4">
                                    <button class="btn btn-primary" (click)="onPermanentSearchChange()">Search</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 px-3 py-3" *ngIf="permanentShowDetailsForm">
                                    <div class="col-md-12 text-center">

                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Salutation</div>
                                        <input type="text" class="form-control" id=""
                                            placeholder="Salutation" autocomplete="off" [(ngModel)]="registerMember.salutation">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">First Name</div>
                                        <input type="text" class="form-control" id="" 
                                            placeholder="First Name" autocomplete="off" [(ngModel)]="registerMember.firstName">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Last Name</div>
                                        <input type="text" class="form-control" id="" 
                                            placeholder="Last Name" autocomplete="off" [(ngModel)]="registerMember.lastName">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Email</div>
                                        <input type="text" class="form-control" id="" placeholder="Email"
                                            autocomplete="off" [(ngModel)]="registerMember.email">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input" >
                                        <div class="form-label text-start ms-4 required">Member Type</div>
                                        <select class='select-option col-md-5 form-control m-1 ' style="border-radius: 20px; " [(ngModel)]="registerMember.memberType">
                                            <option value=''>Select</option>
                                            <option value='Member_resident'>Member_resident</option>
                                            <option value='Member_non_resident'>Member_non_resident</option>
                                            <option value='Non_member_resident'>Non_member_resident</option>
                                            <option value='Waitlisted_member'>Waitlisted_member</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4">Member Id</div>
                                        <input type="text" class="form-control" id=""
                                            autocomplete="off" [(ngModel)]="registerMember.memberId">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Mobile Number</div>
                                        <input type="number" class="form-control" id=""
                                            placeholder="Mobile number" autocomplete="off" [(ngModel)]="registerMember.mobileNumber">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Whatsapp Number</div>
                                        <input type="number" class="form-control" id=""
                                            placeholder="Whatsapp number" autocomplete="off" [(ngModel)]="registerMember.whatsappNumber">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Address</div>
                                        <input type="text" class="form-control" id="" 
                                            placeholder="Address" autocomplete="off" [(ngModel)]="registerMember.address">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Plot No</div>
                                        <input type="text" class="form-control" id=""
                                            placeholder="Plot number" autocomplete="off" [(ngModel)]="registerMember.plotNo">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Road No</div>
                                        <input type="text" class="form-control" id=""
                                            placeholder="Road number" autocomplete="off" [(ngModel)]="registerMember.roadNo">
                                    </div>
                                </div>
                                <div class="col-md-auto">
                                    <ng2-smart-table [settings]="settings" *ngIf="permanentShowGrid" [source]="permanentSource"
                                        (userRowSelect)="onPermanentRowSelect($event)">
                                    </ng2-smart-table>
                                    <!-- <ng2-smart-table [settings]="settings" *ngIf="permanentShowGrid" [source]="permanentSource"
                                         (custom)="onCustomAction($event)" (userRowSelect)="onUserRowSelect($event)">
                                    </ng2-smart-table> -->
                                    <div class="col text-center mt-5 jh-search-error">
                                        <h6 *ngIf="permanentShowLabel">Please search to fetch member
                                            details.</h6>
                                        <h6 *ngIf="permanentShowNoRecord">No matching records found.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div class="card p-3" style="box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);">
                            <h5>Member Transfer To</h5>
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchValue">
                                </div>
                                <select name="" id="" class="col-md-4" [(ngModel)]="selectedSearchFilter">
                                    <option value="">Search By</option>
                                    <option value="firstName">First Name</option>
                                    <option value="lastName">Last Name</option>
                                    <option value="mobileNumber">Mobile Number</option>
                                    <option value="email">Email</option>
                                    <option value="plotNo">Plot Number</option>
                                    <option value="roadNo">Road Number</option>
                                    <option value="memberId">Member Id</option>
                                </select>
                                <div class="col-md-4">
                                    <button class="btn btn-primary" (click)="onSearchChange()">Search</button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 px-3 py-3" *ngIf="showDetailsForm">
                                    <div class="col-md-12 text-center">

                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Salutation</div>
                                        <input type="text" class="form-control" id=""
                                            placeholder="Salutation" autocomplete="off" [(ngModel)]="existMember.salutation">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">First Name</div>
                                        <input type="text" class="form-control" id="" 
                                            placeholder="First Name" autocomplete="off" [(ngModel)]="existMember.firstName">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Last Name</div>
                                        <input type="text" class="form-control" id="" 
                                            placeholder="Last Name" autocomplete="off" [(ngModel)]="existMember.lastName">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Email</div>
                                        <input type="text" class="form-control" id="" placeholder="Email"
                                            autocomplete="off" [(ngModel)]="existMember.email">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Member Type</div>
                                        <select class='select-option col-md-5 form-control m-1' style="border-radius: 20px; " [(ngModel)]="existMember.memberType">
                                            <option value=''>Select</option>
                                            <option value='Member_resident'>Member_resident</option>
                                            <option value='Member_non_resident'>Member_non_resident</option>
                                            <option value='Non_member_resident'>Non_member_resident</option>
                                            <option value='Waitlisted_member'>Waitlisted_member</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4">Member Id</div>
                                        <input type="text" class="form-control" id=""
                                            autocomplete="off" [(ngModel)]="existMember.memberId">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Mobile Number</div>
                                        <input type="number" class="form-control" id=""
                                            placeholder="Mobile number" autocomplete="off" [(ngModel)]="existMember.mobileNumber">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Whatsapp Number</div>
                                        <input type="number" class="form-control" id=""
                                            placeholder="Whatsapp number" autocomplete="off" [(ngModel)]="existMember.whatsappNumber">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Address</div>
                                        <input type="text" class="form-control" id="" 
                                            placeholder="Address" autocomplete="off" [(ngModel)]="existMember.address">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Plot No</div>
                                        <input type="text" class="form-control" id=""
                                            placeholder="Plot number" autocomplete="off" [(ngModel)]="existMember.plotNo">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4 required">Road No</div>
                                        <input type="text" class="form-control" id=""
                                            placeholder="Road number" autocomplete="off" [(ngModel)]="existMember.roadNo">
                                    </div>
                                    <div class="col-md-12 my-3 jh-login-input">
                                        <div class="form-label text-start ms-4">Transferred Date</div>
                                        <input class="form-control" id="" (click)="d.toggle()" placeholder="yyyy-mm-dd"
                                            ngbDatepicker #d="ngbDatepicker" [(ngModel)]="dateModel.transferredDate">
                                        <div class="input-group-append jh-dt-icon" style="
                                            position: absolute;
                                            right: 680px;
                                            bottom: -923px;
                                            width: -1px;
                                            /* height: 25px; */
                                        ">
                                            <button class="btn btn-outline-secondary calendar" style="
                                                height: 23px;
                                            " type="button"></button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 px-3 py-3" *ngIf="showDetailsForm">

                                </div> -->
                                <div class="col-md-auto">
                                    <ng2-smart-table [settings]="settings" *ngIf="showGrid" [source]="source"
                                        (userRowSelect)="onRowSelect($event)">
                                    </ng2-smart-table>
                                    <div class="col text-center mt-5 jh-search-error">
                                        <h6 *ngIf="showLabel">Please search to fetch member
                                            details. </h6>
                                        <h6 *ngIf="showNoRecord">No matching records found. </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </div>
        </div>
    </div>
    <ng-template #approveModal let-modal>
        <div class="modal-header jh-member-approve">
            <h4 class="modal-title" id="modal-basic-title">Are you sure</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            Do you want to Transfer the member?
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark"
                (click)="UpdateStatus('Approve');modal.close('Save click')">Ok</button>
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        </div>
    </ng-template>
    <ng-template #rejectModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Are you sure</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            Do you want to reject the member?
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark"
                (click)="UpdateStatus('Reject');modal.close('Save click')">Ok</button>
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        </div>
    </ng-template>
</div>
<div class="d-flex justify-content-center" *ngIf="showLoader" style="
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(0%, 50%);
  ">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>