<div class="container-fluid">
    <div class="row align-items-start text-center">

        <div class="col-md-2 order-md-1 js-hr-ads ">
            <div class="col my-5">
              <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded " *ngIf="showfirst">
            <div class="container-fluid vision-div">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center jh-heading">Vision &amp; Mission</div>

                    </div>
                    <div class="row">
                        <div class="col-md-6 jh-vision">
                            <p>The principal mission of the society is to promote. protect and safeguard the interests of its residents by providing a transparent administration and by ensuring a safe, secure and eco-friendly environment where everyone feels a sense of ownership and accountability.</p>
                        </div>
                        <div class="d-none vission_mission_img ">
                            <!-- <img class="d-none" src="https://www.jhchbs.com/wp-content/uploads/2021/04/our-mission-vision.png" width="100%"> -->
                            <img class="d-none" src="/assets/images/our-mission-vision.png" width="100%">
                        </div>
                        <div class="col-md-6 mission jh-vision bg-warning text-dark">
                            <p>The vision of the society is to transform Jubilee Hills into world class community with the best infrastructure where every resident believes they are part of a larger eco-system and takes pride in participating and contributing to the development of the community.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded " *ngIf="showsecond">
            <div class="container-fluid vision-div">
              <div class="col-md-12">
                <div class="text-center jh-heading">Office Bearers</div>

               </div>
                <div class="col-md-10">
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/c2.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Ravindranath Bollineni
                                <span>B.E (Mechanical)</span>
                            </h3>
                            <h4>President</h4>
                            <p>A self made Entrepreneur who has successfully launched a few start-ups, Ravindranath owns and manages a major media house in the news broadcasting space. It has created a niche for itself and is touted as a case study for many budding entrepreneurs. He believes that there is still potential for lot of growth and sustenance in Jubilee Hills and it should be protected and preserved for our future generations.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/c4.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Suneela Reddy
                                <span>B.Sc (C.Sc &amp; Engg), MCA</span>
                            </h3>
                            <h4>Vice President</h4>
                            <p>A vivacious go-getter and a fierce advocate of equal justice, Suneela Reddy is an entrepreneur who takes any task head-on with a never-say-die attitude. She has assisted in writing the book “The Court and the Constitution of India: Summits and Shallows” which is a comprehensive and reader-friendly narrative of the strength and the fragility of the Supreme Court of India. She believes that we can all unite and restore our society back to its glory.</p>
                        </div>
                    </div>
                
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/c5.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Adala Himabindu Reddy
                                <span>B.com, MBA</span>
                            </h3>
                            <h4>Additional Secretary</h4>
                            <p>An entrepreneur and social activist, Himabindu Reddy also contributes to an in-house charitable trust co-ordinating and supervising its activities. Her family has been at the forefront of leading the fight in uplifting downtrodden communities through various philanthropic activities. She believes that Jubilee Hills due to its strategic location and eminent residents has immense potential to propel itself into a world class society.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/c3.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Pathapati Nagaraju</h3>
                            <h4>Treasurer</h4>
                            <p>An industrialist managing a diverse portfolio of businesses, Nagaraju and his family are involved in spearheading a lot of philanthropic activities through an NGO. They are instrumental in leading the fight for respectable pay for home guards and many such activities. He believes and advocates that the new management committee and the society members can together strive for safeguarding the assets in a transparent manner.</p>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded " *ngIf="showthird">
            <div class="container-fluid vision-div">
              <div class="col-md-12">
                <div class="text-center jh-heading">Committee Members</div>
               </div>
                <div class="col-md-12">
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/ma.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>M. Anand Kumar
                                <span>B.Com (Honours)</span>
                            </h3>
                            <h4>Educationalist</h4>
                            <p>Anand Kumar comes from a family of businessmen and industrialists and has decided to dedicate and pursue his passion of imparting and facilitation quality education to the society. He is serving as an executive board member of a leading educational academy in the state. He believes that every resident has equal responsibility in contributing to the development and he envisages Jubilee Hills becoming great again with 360 degree development.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/kkj.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Jetti Kusum Kumar
                                <span>B.B.A</span>
                            </h3>
                            <h4>Social Activist and Businessman</h4>
                            <p>Jetti Kusum Kumar is a businessman and seasoned politician with a political career stretching over 3 decades. Known and respects in his party circles for unflinching loyalty and dedication, he hails from a family of freedom fighters. He belies that the foundation we lay will pave a pathway and guide for future generations and it is our responsibility to give them a safe and prosperous tomorrow.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/mrd.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Dr. Madhava Reddy
                                <span>
                                    M.B.B.S</span>
                            </h3>
                            <h4>Doctor</h4>
                            <p>A Doctor by profession, Madhava Reddy has extensively served in the rural parts of India, conducting several medical camps and touching many souls. He currently runs a nursing home which has become an integral part of the local community. He is also on the board of director of a Hyderabad based software company. He believes that  Jubilee Hills still has a lot of potentials and wishes to see it transform and transcend into one of the best housing societies.</p>
                        </div>
                    </div>
                    <hr>
                    <!-- <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/c1.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>A. Murali Mukund
                                <span>M.Sc, LLB</span>
                            </h3>
                            <h4>Entrepreneur</h4>
                            <p>A successful entrepreneur and educationalist, Murali Mukund with a career in financial services and investments, is passionate about setting high standards in the field of education. He is the founding member and one of the managing committee members of a top school in Hyderabad. He envisions a transparent and committed housing committee which will work towards the protection of green belts and progressive development of the area.</p>
                        </div>
                    </div>
                    <hr> -->
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/np.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>K. Nagendra Prasad
                                <span>MA, B.Ed</span>
                            </h3>
                            <h4>Builder and Entrepreneur</h4>
                            <p>Nagendra Prasad is an established builder who has developed townships in and around Hyderabad. He believes in providing selfless service and that as a team we can transform Jubilee Hills into a better place that we can all be proud of.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/om.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Dr. Om Prakash Agarwal
                                <span>MBBS, MS (Ortho), AO (Swis)</span>
                            </h3>
                            <h4>Surgeon</h4>
                            <p>A strong believer in the saying “Service to mankind is service to God”, an Orthopaedic surgeon by profession, Dr. Om Prakash Agarwal has been associated with ‘Agarwal Siksha Samithi’ which runs several schools, catering to the educational needs of underprivileged children. He has also been managing the family hospital for the last 30 years. He also services as a correspondent in the ‘Nanakram Bhagwan Das Science college’</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/rp.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Rajendra Prasad
                                <span>M.A (Economics)</span>
                            </h3>
                            <h4>Businessman &amp; Philanthropist</h4>
                            <p>Rajendra Prasad is an entrepreneur with a diverse portfolio spread across Telangana and Andhra Pradesh. Hi company, VVC motors, well known in the automobile industry is involved across the two Telugu states for these charitable activities. He is a strong advocate of the belief that change begins at home and aspires to bring about this change to inspire others and make them contribute to the betterment of society.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/sathish.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Satish Chandra Salguti
                                <span>Masters in Computers (USA)</span>
                            </h3>
                            <h4>IT Professional</h4>
                            <p>An IT professional who leads the Premium support team for the EMEA and APAC regions for a US-based multinational company, Satish Chandra Reddy comes from a family of politicians and statesmen who have made it their way of live to serve the society. He believes that every resident of our society should have the freedom to participate &amp; contribute, making Jubilee Hills an ideal place to live in that others can look up to.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/sg.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Sreenivasu Garikapati
                                <span>Industrialist – B.E</span>
                            </h3>
                            <h4>Industrial Production Engineering</h4>
                            <p>A resident of Jubilee Hills for the past 4 decades, Sreenivasu Garikapati is an industrialist by profession. He is the promotor of Ratna Group of Industries. He and his family are donors of an old age home which has been functioning for the last 3 decades in Sunder Nagar. He believes that a progressive society should always work for the development and well being of its members by embracing changes for the betterment of its members.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/sr.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Sri Laxmi Reddy Madadi
                                <span>B.A</span>
                            </h3>
                            <h4>Homemaker</h4>
                            <p>Srilaxmi Reddy Madadi has been a resident of Jubilee Hills for more than 2 decades. She is a homemaker who assists her husband in his business ventures. She finds it disheartening to see the once beautiful landscape of Jubilee Hills has now given way to illegal constructions and the disappearance of green zones.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row pt-3 pb-3 commitee_member">
                        <div class="col-md-3"><img src="assets/images/MC Pictures/ss.jpg" width="100%"></div>
                        <div class="col-md-9">
                            <h3>Sujatha Seelam
                                <span>M.A (Anthropology)</span>
                            </h3>
                            <h4>Entrepreneur and Social Activist</h4>
                            <p>Sujatha Seelam is an entrepreneur and social activist who takes pride in the social activities and rehabilitation camps taken up by her organisation. She has participated in various international seminars world over while assisting her husband, a former central minister.  She believes that Jubilee Hills should transform itself by realigning its growth plans and aspire to become a major economic power house while inspiring other housing societies with its green initiatives and development plans.</p>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded " *ngIf="showforth">
            <div class="container-fluid vision-div">
              <div class="col-md-12">
                <div class="text-center jh-heading">Jubilee Hills</div>

            </div>
                <div class="col-md-12">
                    <!-- <img src="https://www.jhchbs.com/wp-content/uploads/photo-gallery/g6.jpg?bwg=1618222880" alt="" width="100%"> -->
                    <img src="/assets/images/g6.jpg?bwg=1618222880" alt="" width="100%">
                </div>
            </div>
        </div>


        <div class="col-md-2 order-md-3 js-hr-ads ">
            <div class="col my-5">
              <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>


    </div>
</div>


