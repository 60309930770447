import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class DbServiceService {

  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  saveDataToDb(url, data){
    return this.http.post(this.globalUrl.SERVER_URL+ url,data);
  }
  getDataFromDb(url){
    return this.http.get(this.globalUrl.SERVER_URL+url);
  }
}
