<div class="container-fluid">
    <div class="row align-items-start text-center">

        <div class="col-md-2 order-md-1 js-hr-ads">
            <div class="col my-5">
                <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded ">
            <div class="container-fluid vision-div">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center jh-heading">Contact Us</div>

                    </div>
                    <section id="contact">

                        <!-- <h1 class="section-header">Contact</h1> -->

                        <div
                            class="contact-wrapper">

                            <!-- Left contact page -->

                            <form id="contact-form" class="form-horizontal" role="form">

                                <div class="form-group">
                                    <div class="fs-5 my-3 fw-bold">Submit Your Message </div>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" id="name" placeholder="NAME" name="name" value="" required="required" [(ngModel)] = "name"></div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <input type="email" class="form-control" id="email" placeholder="EMAIL" name="email" value="" required="required" [(ngModel)] = "email"></div>
                                        </div>

                                        <textarea class="form-control" rows="10" placeholder="MESSAGE" name="message" required="required" [(ngModel)] = "message"></textarea>

                                        <button class="btn btn-primary send-button" id="submit" type="submit" value="SEND" [disabled] = "email == '' ||email == undefined ||email == null " (click)="sendNotification()">
                                            <div class="alt-send-button">
                                                <i class="fa fa-paper-plane"></i>
                                                <div class="spinner-border text-danger ms-3 p-1" role="status" *ngIf="showLoader" style="width: 1rem; height: 1rem;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="send-text" >SEND
                                                    <div class="spinner-border text-danger ms-3 p-1" role="status" *ngIf="showLoader" style="width: 1rem; height: 1rem;">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </span>
                                            </div>
                                            

                                        </button>

                                    </form>

                                    <!-- Left contact page -->

                                    <div class="direct-contact-container text-start">
                                        <div class="fs-5 my-3 fw-bold text-center">Address</div>
                                        <ul class="contact-list">
                                            <li class="list-item">
                                                <i class="fa fa-map-marker fa-2x">
                                                    <span class="contact-text place ms-3">Jubilee Hills Co-Operative House Building Society
                                                      Road No. 17/A, Jubilee Hills, Hyderabad-500033, Telangana.</span>
                                                </i>
                                            </li>

                                            <li class="list-item">
                                                <i class="fa fa-phone fa-2x ">
                                                    <span class="contact-text ms-3">
                                                        <a href="tel:040 23540642" title="Give me a call">040 23540642</a>
                                                    </span>
                                                </i>
                                            </li>

                                            <li class="list-item">
                                                <i class="fa fa-envelope fa-2x">
                                                    <span class="contact-text ms-3">
                                                        <a href="mailto:#" title="Send me an email">info@jhchbs.com</a>
                                                    </span>
                                                </i>
                                            </li>

                                        </ul>


                                    </div>

                                </div>
                                <div class="col-md-12 my-4">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30453.575101533177!2d78.40533052508793!3d17.426328235525542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91c5ec95c9df%3A0x1148891774952370!2sJubilee%20Hills%20Cooperative%20House%20Building%20Society%20Ltd!5e0!3m2!1sen!2sin!4v1617781756257!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy" width="100%" height="300"></iframe>
                                </div>

                            </section>


                        </div>
                    </div>
                </div>
                <div class="col-md-2 order-md-3 js-hr-ads">
                    <div class="col my-5">
                        <app-horizontal-add2></app-horizontal-add2>
                    </div>
                </div>
            </div>
        </div>

