import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-ads',
  templateUrl: './horizontal-ads.component.html',
  styleUrls: ['./horizontal-ads.component.scss']
})
export class HorizontalAdsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
