import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { MembersService } from '../../sevice/members.service';
import { AlertService } from '../../sevice/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartsService } from '../../sevice/charts.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private chartService: ChartsService, private memberService: MembersService, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
  memberDetails: any;
  membersTypeChart: any;
  requestsData: any;
  isAdmin:boolean=false;
  isMember:boolean=false;
  maintananeData:any;
  memberId;
  user_id;
  monthAndYear;
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

  ngOnInit(): void {
    this.memberId=localStorage.getItem("authenticateId");
    this.user_id = localStorage.getItem('authenticatemainId')
    if(localStorage.getItem("role") == "member")
      this.isMember=true;
     else
     this.isAdmin=true; 
    this.monthAndYear=""+(this.monthNames[((new Date()).getMonth())]+","+((new Date()).getFullYear()))
    am4core.useTheme(am4themes_material);
    am4core.useTheme(am4themes_animated);

    let memberDetailschart = am4core.create("memberDetails", am4charts.PieChart);
    let memberTypesChart = am4core.create("memberTypesChart", am4charts.PieChart);
    let maintananeDataChart = am4core.create("maintananceData",am4charts.XYChart);


    maintananeDataChart.width = am4core.percent(100);
    maintananeDataChart.height = am4core.percent(94);
    memberDetailschart.events.on('hit', function(ev){
      this.navigateTo("memberDetails");
    },this)

    memberTypesChart.events.on('hit', function(ev){
      this.navigateTo("memberTypesChart");
    },this)
    maintananeDataChart.events.on('hit', function(ev){
      this.navigateTo("maintananceData");
    },this)
 
    let membertitle = memberDetailschart.titles.create();
        membertitle.text = "Registrations";
        membertitle.fontSize = 25;
        membertitle.marginBottom = 30;

    let memberTyoe = memberTypesChart.titles.create();
        memberTyoe.text = "Members";
        memberTyoe.fontSize = 25;
        memberTyoe.marginBottom = 30;   


    let maintananceTitle = maintananeDataChart.titles.create();
        maintananceTitle.text = "Maintanance Requests Details";
        maintananceTitle.fontSize = 25;
        maintananceTitle.marginBottom = 30;        
  

    // Add and configure Series
    let memberTypesChartpieSeries = memberTypesChart.series.push(new am4charts.PieSeries());
    memberTypesChartpieSeries.dataFields.value = "count";
    memberTypesChartpieSeries.dataFields.category = "details";
    memberTypesChartpieSeries.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
  
    ];
    memberTypesChartpieSeries.slices.template.stroke = am4core.color("#fff");
    memberTypesChartpieSeries.slices.template.strokeOpacity = 1;
    // This creates initial animation
    memberTypesChartpieSeries.hiddenState.properties.opacity = 1;
    memberTypesChartpieSeries.hiddenState.properties.endAngle = -90;
    memberTypesChartpieSeries.hiddenState.properties.startAngle = -90;
    memberTypesChartpieSeries.labels.template.maxWidth = 130;
    memberTypesChartpieSeries.labels.template.wrap = true;
    memberTypesChart.hiddenState.properties.radius = am4core.percent(0);
    memberTypesChart.radius = am4core.percent(50);

    // Add and configure Series
    let memberDetailspieSeries = memberDetailschart.series.push(new am4charts.PieSeries());
    memberDetailspieSeries.dataFields.value = "count";
    memberDetailspieSeries.dataFields.category = "details";
    memberDetailspieSeries.slices.template.stroke = am4core.color("#fff");
    memberDetailspieSeries.slices.template.strokeOpacity = 1;
    memberDetailspieSeries.colors.list = [
      am4core.color("#47d147"),
      am4core.color("#ff3300"),
      am4core.color("#ff9900"),
   
  
    ];
    // This creates initial animation
    memberDetailspieSeries.hiddenState.properties.opacity = 1;
    memberDetailspieSeries.hiddenState.properties.endAngle = -90;
    memberDetailspieSeries.hiddenState.properties.startAngle = -90;
    memberDetailschart.hiddenState.properties.radius = am4core.percent(0);
    memberDetailspieSeries.labels.template.maxWidth = 130;
    memberDetailspieSeries.labels.template.wrap = true;
    memberDetailschart.radius = am4core.percent(50);
    maintananeDataChart.hiddenState.properties.opacity = 0;
    

    this.createCharts("membersDetails").then(data => {
      this.memberDetails = data;
      memberDetailschart.data = this.memberDetails;
    })
    this.createCharts("memberTypes").then(data => {
      this.membersTypeChart = data;
      memberTypesChart.data = this.membersTypeChart;
    })
 
    this.createCharts("requestsChart").then(data => {
      this.maintananeData=data;
      maintananeDataChart.data=this.maintananeData;
    })  

    let categoryMaintananceAxis = maintananeDataChart.xAxes.push(new am4charts.CategoryAxis());
    categoryMaintananceAxis.renderer.grid.template.location = 0;
    categoryMaintananceAxis.dataFields.category = "details";
    categoryMaintananceAxis.renderer.minGridDistance = 40;
    categoryMaintananceAxis.fontSize = 11;
    categoryMaintananceAxis.renderer.labels.template.dy = 5;
    categoryMaintananceAxis.renderer.cellStartLocation = 0.2;
    categoryMaintananceAxis.renderer.cellEndLocation = 0.8;
    categoryMaintananceAxis.renderer.grid.template.disabled = true;
    categoryMaintananceAxis.renderer.labels.template.rotation = -45;
    categoryMaintananceAxis.renderer.labels.template.horizontalCenter = "right";
    categoryMaintananceAxis.renderer.labels.template.verticalCenter = "middle";

    let valueMaintananceAxis = maintananeDataChart.yAxes.push(new am4charts.ValueAxis());
    valueMaintananceAxis.min = 0;
    valueMaintananceAxis.maxPrecision = 0;
    valueMaintananceAxis.renderer.minGridDistance = 30;
    valueMaintananceAxis.renderer.baseGrid.disabled = true;
    valueMaintananceAxis.renderer.grid.template.disabled=true;
    

    let Maintananceseries = maintananeDataChart.series.push(new am4charts.ColumnSeries());
    Maintananceseries.dataFields.categoryX = "details";
    Maintananceseries.dataFields.valueY = "count";
    Maintananceseries.columns.template.tooltipText = "{valueY.value}";
    Maintananceseries.columns.template.tooltipY = 0;
    Maintananceseries.columns.template.strokeOpacity = 0;
    // Maintananceseries.columns.template.strokeWidth = 0;
    
    Maintananceseries.columns.template.width = am4core.percent(60);
     // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
     Maintananceseries.columns.template.adapter.add("fill", function (fill,target){
      return maintananeDataChart.colors.getIndex(target.dataItem.index);
    });
    maintananeDataChart.colors.list = [
      am4core.color("#ffd11a"),
      am4core.color("#ff6600"),
      am4core.color("#009933"),
      am4core.color("#1a53ff"),
    ];
    function createGrid2(value) {
      var range = valueMaintananceAxis.axisRanges.create();      
      range.setValue = value;
      range.label.text = "{value}";

      var range1 = categoryMaintananceAxis.axisRanges.create();      
      range1.setValue = value;
      range1.label.text = "{value}";
    }
    createGrid2(0);
    createGrid2(25);
    createGrid2(100);

    let requestschart = am4core.create("requestsData", am4charts.XYChart);
       

    requestschart.events.on('hit', function(ev){
     this.navigateTo("requestsData")
    },this)
    let requestTitle = requestschart.titles.create();
        requestTitle.text = "Request Details";
        requestTitle.fontSize = 25;
        requestTitle.marginBottom = 30;

    this.createCharts("requests").then(data => {
      this.requestsData = data;
      requestschart.data = this.requestsData;
    })
 
  
    let categoryAxis = requestschart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "details";
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.fontSize = 11;
  
    categoryAxis.renderer.labels.template.dy = 5;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.baseGrid.disabled = true;
    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    
    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;

    // categoryAxis.events.on("sizechanged", function(ev) {
    //   let axis = ev.target;
    //     var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
    //     if (cellWidth < axis.renderer.labels.template.maxWidth) {
    //       axis.renderer.labels.template.rotation = -45;
    //       axis.renderer.labels.template.horizontalCenter = "right";
    //       axis.renderer.labels.template.verticalCenter = "middle";
    //     }
    //     else {
    //       axis.renderer.labels.template.rotation = 0;
    //       axis.renderer.labels.template.horizontalCenter = "middle";
    //       axis.renderer.labels.template.verticalCenter = "top";
    //     }
    //   });

    let valueAxis = requestschart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.maxPrecision = 0;
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.grid.template.disabled=true;
    

    function createGrid(value) {
      var range = valueAxis.axisRanges.create();      
      range.setValue = value;
      range.label.text = "{value}";

      var range1 = categoryAxis.axisRanges.create();      
      range1.setValue = value;
      range1.label.text = "{value}";

    }

    createGrid(0);
    createGrid(25);
    createGrid(100);

    requestschart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    let series = requestschart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "details";
    series.dataFields.valueY = "count";
    series.columns.template.tooltipText = "{valueY.value}";
    series.columns.template.tooltipY = 0;
    series.columns.template.strokeOpacity = 0;
    // series.columns.template.strokeWidth = 0;
    series.columns.template.width = am4core.percent(40);
    requestschart.colors.list = [
      am4core.color("#ffd11a"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#ff3300"),
      am4core.color("#ff6600"),
      am4core.color("#009933"),
      am4core.color("#1a53ff")
    ];

   

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function (fill, target) {
      return requestschart.colors.getIndex(target.dataItem.index);
    });
  }


  navigateTo(to){
    if(to == "memberDetails")
    this.router.navigate(['/newRegistrations']);
    if(to == "memberTypesChart")
    this.router.navigate(['/members']);
    if(to == "maintananceData")
    this.router.navigate(['/requests']);
    if(to == "requestsData")
    this.router.navigate(['/requests']);
  }
 

  async createCharts(selection) {
    this.memberId=null;
    let data = await this.chartService.create(selection,this.memberId).toPromise();
    return data;
  }



}
