import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../sevice/auth.service';
import { NotificationService } from '../sevice/notification.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isAdmin: boolean = false;
  isLogedin: boolean = false;
  isMember:  boolean = false;
  status:string
  notificationsData
  constructor(public router: Router,private authService:AuthService,private notificationService: NotificationService) {
    authService.isUserLogin.subscribe(status => this.setDetails(status));

   }

   private setDetails(status: string): void {
    if (localStorage.getItem("role") == "" || localStorage.getItem("role") == null ||   localStorage.getItem("role") == undefined ) {
      this.isAdmin = false;
      this.isMember = false;
    }
    if (localStorage.getItem("role") == "admin") {
      this.isAdmin = true;
      this.isMember = false;
    }  
    if (localStorage.getItem("role") == "member"){
      this.isMember = true;
      this.isAdmin = false
    }
    if (localStorage.getItem("isLoggedIn") == "true") {
      this.isLogedin = true;
    } else {
      this.isLogedin = false
    }

    this.status = status;
    this.getLatestNews();
}

  ngOnInit(): void {
    if (this.router.url.endsWith('/home') || this.router.url.endsWith('/staffLogin') || this.router.url.endsWith('/memberLogin')) {
      localStorage.setItem('isLoggedIn', "false");
      localStorage.setItem('role', "");
      localStorage.setItem("authenticateUser","");
    }
    if (localStorage.getItem("role") == "" || localStorage.getItem("role") == null ||   localStorage.getItem("role") == undefined ) {
      this.isAdmin = false;
      this.isMember = false;
    }
    if (localStorage.getItem("role") == "admin") {
      this.isAdmin = true;
      this.isMember = false;
    }  
    if (localStorage.getItem("role") == "member"){
      this.isMember = true;
      this.isAdmin = false
    }

    if (localStorage.getItem("isLoggedIn") == "true") {
      this.isLogedin = true;
    } else {
      this.isLogedin = false
    }
    this.getLatestNews();
  }
  getLatestNews()
  {
    this.notificationService.getsavedNotificationAlerts("All",null,null).subscribe((response:any) =>{
      this.notificationsData=response['data'].filter(notification => notification['notificationType'] =="Latest News" && notification['isPublished']).reverse();
    })
  }
  showNews(notificationName,notificationId){
    this.router.navigate(['/latest-news',notificationName,notificationId])
  }
  navigateTo(selection) {
    if (selection == 'none') {
      return;
  }

  if ((this.isLogedin)&&(selection == 'overview' || selection == 'officebearers' || selection == 'committee-members' || selection == 'jubilee-hills')){
  this.router.navigate(['/aboutus', selection]);
  return;
  }

    this.isMember = false;
    this.isAdmin = false;
    this.isLogedin = false;
    if(selection == "home")
    this.router.navigate(['/home']);

    if (selection == 'overview' || selection == 'officebearers' || selection == 'committee-members' || selection == 'jubilee-hills')
      this.router.navigate(['/aboutus', selection]);

     if (selection == 'logout' && localStorage.getItem("role") == "admin") 
      this.router.navigate(['/staffLogin']);
    

    if (selection == 'logout' && localStorage.getItem("role") != "admin") 
      this.router.navigate(['/memberLogin']);
    

    if (selection == 'MemberLogin') 
      this.router.navigate(['/memberLogin']);
    

    if (selection == 'StaffLogin') 
      this.router.navigate(['/staffLogin']);
    


    localStorage.clear();  
    localStorage.setItem("isLoggedIn","false");
    localStorage.setItem('role', "");
    localStorage.setItem("authenticateUser","");
  }
}
