

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="assets/images/img (1).png" class="d-block w-100" alt="first-image">
            <div class="carousel-caption d-none d-md-block">
                <h1><b>WELCOME TO JUBILEE HILLS CO-OPERATIVE HOUSE BUILDING SOCIETY LTD</b></h1>
                <p></p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/images/IMG_2084.jpg" class="d-block w-100" alt="second-image">
        </div>
        <div class="carousel-item">
            <img src="assets/images/IMG_2089.jpg" class="d-block w-100" alt="third-image">
        </div>
        <div class="carousel-item">
          <img src="assets/images/IMG_2091.jpg" class="d-block w-100" alt="forth-image">
        </div>
        <div class="carousel-item">
          <img src="assets/images/IMG_2204.jpg" class="d-block w-100" alt="fifth-image">
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>

<div class="container-fluid">
    <div class="row align-items-start text-center">
        <div class="col-md-2 order-md-1 js-hr-ads ">
            <div class="col my-5">
                <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded ">

            <div class="col-md-12 text-start">
                <div class="text-center jh-heading">JUBILEE HILLS CO-OPERATIVE HOUSE BUILDING SOCIETY LTD</div>
                <div class="row">
                    <p>
                        Jubilee Hills is synonymous with wealth and fame and most of its residents are eminent personalities in their respective fields. Sandwiched between the affluent Banjara Hills and the bustling tech hub of HITECH City, rests one of Asia’s most expensive residential and commercial pockets which is home to the rich and elite of South India. It is also known for its quiet by-lanes and unique contours of rock formations along with lush greenery.
                    </p>

                    <p>
                        Jubilee Hills cooperative house building society was established in 1962 with an extent of 1398 acres out of which some land was allotted to neighboring societies, leaving 1167.27 acres for Jubilee Hills. It had no amenities whatsoever and was way beyond city limits for a long time. It has now become the center of business and entertainment activities in Hyderabad. This is the only society where there is a restriction of G+2 floors to protect its ecology. It is one of the largest housing societies in India. Due to its strategic location, it has become one of the most sought-after residential locality for Hyderabadis. As on date the society has about 5000 members.
                    </p>
                    <p>
                        Jubilee Hills is home to the Telugu film industry hub of Film Nagar and studios such as Padmalaya studios, Ramanaidu studios are located here. Some of its famous landmarks are KBR National Park, Ambedkar Open University and Peddamma temple. The Indian Heart Association, an international non-profit organization focused on expanding South Asian cardiac health awareness is headquartered in Jubilee Hills.
                    </p>
                </div>
            </div>
            <div class="col-md-12 mx-auto">
                <div class="row">

                    <div class="col mx-2 shadow  rounded scroller ">

                        <div class="col-md-12 mx-auto p-2 my-2 fixed">
                            <div class="row">
                                <h6 class="px-2 text-start fw-bold p-2 col-6 my-0">Notices</h6>
                                <a class="text-end col-6 py-2 jh-cursor" routerLink="/notice-board">more</a>
                            </div>
                        </div>
                        <div class="col-md-11 mx-auto border-bottom blogShort my-2 shadow-sm p-3"  *ngFor= "let notice of noticesData">
                            <article class="row">
                                <div class="row nt-div">
                                    <div class="my-auto col-md-4 text-center">
                                        <!-- <img src="https://picsum.photos/100/100" alt="post img" class="px-2 img-responsive img-thumbnail"> -->
    
                                            <p class="fw-bolder m-0 p-1">{{notice.month}} {{notice.date}}, {{notice.year}}</p>
    
                                    </div>
                                    <div class="px-2 text-center fw-bolder col-md-7 my-auto">{{notice.notificationName}}</div>
                    
                                    <!-- <a class="btn text-start" href="#">READ MORE</a> -->
                                </div>
                            </article>
                        </div>
           
                    </div>

                    <div class="col-md-6 mx-2 shadow  rounded scroller">
                        <div class="col-md-12 mx-auto fixed p-2 my-2">
                            <div class="row">
                                <h6 class="px-2 text-start fw-bold p-2 col-6 my-0">Latest News</h6>
                                <a class="text-end col-6 py-2 jh-cursor" routerLink="/latest-news">more</a>
                            </div>
                        </div>
                        <div class="col-md-11 mx-auto border-bottom blogShort my-2 shadow-sm p-3" *ngFor= "let notification of notificationsData">
                            <article class="row">


                                <div class="row nt-div">
                                    <div class="my-auto col-md-4 text-center">
                                        <!-- <img src="https://picsum.photos/100/100" alt="post img" class="px-2 img-responsive img-thumbnail"> -->
    
                                            <p class="fw-bolder m-0 p-1">{{notification.month}} {{notification.date}}, {{notification.year}}</p>
    
                                    </div>
                                    <div class="px-2 text-center fw-bolder col-md-8 my-auto">{{notification.notificationName}}</div>
                    
                                    <!-- <a class="btn text-start" href="#">READ MORE</a> -->
                                </div>
                            </article>
                        </div>
              
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 order-md-3 js-hr-ads ">
            <div class="col my-5">
                <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>
    </div>

</div>
