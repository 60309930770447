import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from './alert.service';
import { DbServiceService } from './db-service.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
   /**************************************************Attributes starts here********************************************** */  

   showLoader:boolean = false;
   @Output() isUserLogin: EventEmitter<any> = new EventEmitter();
  /**************************************************Attributes ends here********************************************** */

  constructor(private userService: UsersService, private toastr: AlertService, public route: ActivatedRoute, public router: Router, private db_service: DbServiceService) { }


  login(userDetails,title){
    this.userService.login(userDetails).subscribe((response: any) => {
      this.showLoader=false;
      if (response.status == "200" && response['data'] != null) {
        if(response['data']['loginId'] != null) {
          this.generateToken(response['data']['loginId'],userDetails.password, response,title);
        }
        else {
          this.generateToken(response['data']['memberId'],userDetails.password, response,title);
        }
        //this.generateToken(response,title);
      
      } else {
        this.toastr.showError( response.message, "");
        this.showLoader=false;
        this.router.navigate(['/'+title]);
      }
    }, error => {
      this.toastr.showError('An error has occurred while retriving  data.' ,"");
      this.showLoader=false;
      this.router.navigate(['/'+title]);
    })
  }

  generateToken(username, password,response,selection){
    var index = "/authenticate";
    var data = {
      username: username,
      password:password
    }
    this.db_service.saveDataToDb(index, data).subscribe(reponse => {
      localStorage.setItem("accesstoken", btoa(reponse["token"])); 
      // localStorage.setItem("accesstoken", reponse["token"]);
      if(response['data']['userType'] == "admin" && selection == "staffLogin"){
        localStorage.setItem("authenticateUser","admin");
        localStorage.setItem("role","admin");
        localStorage.setItem("isLoggedIn","true");
        this.router.navigate(['/dashboard']);
        this.toastr.showSuccess(response.message,"");
        this.isUserLogin.emit("loggedIn");
      }else if(response['data']['userType'] == "Member" && selection == "memberLogin"){
        localStorage.setItem("authenticateUser",response['data']['firstName']+" "+response['data']['lastName']);  
        localStorage.setItem("authenticateId",response['data']['memberId']); 
        localStorage.setItem("authenticatemainId",response['data']['id']);
        localStorage.setItem("isLoggedIn","true");
        localStorage.setItem("role","member");
        this.router.navigate(['/Dashboard']);
        this.toastr.showSuccess(response.message,"");
        this.isUserLogin.emit("loggedIn");
      }else{
        localStorage.setItem("authenticateUser","");  
        localStorage.setItem("authenticateId",""); 
        localStorage.setItem("authenticatemainId","");
        localStorage.setItem("role",""); 
        this.router.navigate(['/'+selection]);
        this.toastr.showError("Invalid user.","");
        this.isUserLogin.emit("loggedIn");
      }
    })
  }

  navigate(){
    localStorage.clear();  
    localStorage.setItem("isLoggedIn","false");
    localStorage.setItem('role', "");
    localStorage.setItem("authenticateUser",""); 
    this.isUserLogin.emit("loggedIn");
  }
  action(){
    this.isUserLogin.emit("loggedIn");
  }
}

