<div class="container-fluid">
    <div class="row align-items-start text-center">
      <div class="col-md-2 order-md-1 my-auto ">
        <div class="js-hr-ads col my-5">
            <app-horizontal-ads></app-horizontal-ads>
          </div>
      </div>
      <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded jh-forgot-password-form ">
         <div class="text-center jh-heading" >Forgot Password</div>
         <div class="col-md-5 mx-auto">
            <div class="my-4 jh-forgot-password-form jh-login-input">
                <div class="form-label text-start ms-4">Member id</div>
                <input type="text" class="form-control" [(ngModel)] ="forgotPasswordModel.loginId" [ngModelOptions]="{standalone: true}"  id="exampleFormControlInput1" placeholder="id" autocomplete="off">
            </div>

            <div class="my-4 jh-forgot-password-form-input jh-login-input">
                <div class="form-label text-start ms-4">Registered Mobile No.</div>
                <input type="text"  pattern="[0-9]*" maxlength="10" class="form-control" [(ngModel)] ="forgotPasswordModel.mobile" [ngModelOptions]="{standalone: true}"  id="exampleFormControlInput1" placeholder="mobile" autocomplete="off">
            </div>
              <div class="col-auto">
                  <button type="submit" class="btn jh-btn-primary submit-btn my-4 shadow col-auto px-3" (click) ="validateUser()">Submit
                    <div class="spinner-border text-success" role="status" *ngIf ="showLoader">
                        <span class="sr-only">Loading...</span>
                      </div>
                  </button>
              </div>
         </div>

      </div>
      <div class="col-md-2 order-md-3 my-auto">
        <div class="js-hr-ads col my-5">
            <app-horizontal-add2></app-horizontal-add2>
          </div>
      </div>
    </div>

  </div>
