<div class="container-fluid">
    <div class="row align-items-start text-center">

        <div class="col-md-2 order-md-1 js-hr-ads">
            <div class="col my-5">
                <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded ">
            <div class="container-fluid vision-div">
                <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-auto" style="position: absolute;">
                        <div class="p-3" *ngIf = 'isLoggedin' (click)=" navigateTo()">
                        
                        </div>

                          </div>
                        <div class="text-center jh-heading">Photo Gallery</div>
                        <div class="col text-end ">
                            <button (click)="openAddPopUp()" *ngIf="isAdmin" class="jh-btn-primary">Add Album</button>
                        </div>

                    </div>
                    <div class="container-fluid mt-3">
                        <div class="row">
                            <div class="col-md-4 text-center" *ngFor="let gal of galleries">
                                <img src="{{gal.images[0]?.img_url}}" (click)="showPopUp(gal.id)" alt="" width="100%" height="180px">
                                <h5 class="my-3">{{gal.galleryName}}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-2 order-md-3 js-hr-ads">
            <div class="col my-5">
                <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="staticBackdrop" *ngIf="isPopUpshow" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h5 class="modal-title col text-center" id="staticBackdropLabel">{{gallery.galleryName}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModel()"></button>
            </div>
            <div class="modal-body">
                <div id="carousel-thumb" class="jh-photogallery jh-photo-gallery-preview carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let photo of gallery.images; let i=index" [ngClass]="(i === 0) ? 'active' : ''">
                            <img src="{{photo.img_url}}" class="d-block w-100" alt="...">
                        </div>

                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carousel-thumb" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carousel-thumb" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>

                </div>
            </div>


        </div>
    </div>
</div>


<modal *ngIf="isAdd" class="jh-photogallery-modal">
    <ng-template #modalHeader>
        <h4>Add New Album</h4>
    </ng-template>
    <ng-template #modalBody>
        <div class="text-center" style="margin-top: 20px;">
            <div class="col">
                <div class="row">
                    <div class="col-md-3">
                        <h6>Album name:</h6>

                    </div>
                    <div class="col-md-3">
                        <input class="col-auto" name="galleryName" id="galleryName" [(ngModel)]="galleryName">
                    </div>
                    <div class="col-md-auto">
                        <input type="file" accept=".png,.jpg,.jpeg" multiple class="file-input" (change)="onFileSelected($event)" #fileUpload>
                    </div>
                </div>


                <div class="p-4">
                    {{fileName || "No file uploaded yet."}}</div>
            </div>


            <div class="h-100 col rounded ">
                <div class="container-fluid vision-div">
                    <div class="row">

                        <div class="col-md-12 my-2">
                            <article class="d-flex mb-3" *ngFor="let file of images">
                                <div class="">
                                    <div class="px-2 text-start fw-bolder ms-3 fs-6">{{file.name}}</div>
                                    <div class="col-md-3 text-start ms-3">
                                        <img src="{{file.img_url}}" width="100%" height="auto">
                                    </div>

                                </div>
                            </article>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</modal>
<div class="modal fade" id="staticBackdrop" *ngIf="isAdd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content p-3">

            <div class="col-md-12">
                <div class="d-flex">
                    <h4 class="col">Add New Album</h4>
                    <button type="button" class="btn-close float-end p-2" data-bs-dismiss="modal" aria-label="Close" (click)="closeModel()"></button>

                </div>
            </div>
            <div class="text-center" style="margin-top: 20px;">
                <div class="col">
                    <div class="row">
                        <div class="col-md-3">
                            <h6>Album name:</h6>
                        </div>
                        <div class="col-md-3">
                            <input class="col-auto" name="galleryName" id="galleryName" [(ngModel)]="galleryName">
                        </div>
                        <div class="col-md-auto">
                            <input type="file" accept=".png,.jpg,.jpeg" multiple class="file-input" (change)="onFileSelected($event)" #fileUpload>
                        </div>
                    </div>
                </div>


                <div class="h-100 col rounded ">
                    <div class="container-fluid vision-div">
                        <div class="row">
                            <div class="col-md-12 my-2">
                                <div class="row mb-3">
                                    <div class="col-md-3" *ngFor="let file of images">

                                        <div class="col-md-11 mx-auto img-fluid text-center">
                                            <img src="{{file.img_url}}" width="100%" height="150px">
                                        </div>
                                        <div class="text-cemter fw-bolder">{{file.name}}</div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button (click)="saveGallery()" class="jh-btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>
