import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { LandingpageComponent } from './components/landingpage/landingpage.component';
import { LatestNewsComponent } from './components/latest-news/latest-news.component';
import { ForgotPasswordComponent } from './components/login/Forgot-password/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { CreatePasswordComponent } from './components/login/Password-Verify/create-password/create-password/create-password.component';
import { ChangePasswordComponent } from './components/Member/change-password/change-password.component';
import { DashboardComponent } from './components/Member/dashboard/dashboard.component';
import { MemberDashboardComponent } from './components/Member/member-dashboard/member-dashboard.component';
import { MemberProfileComponent } from './components/Member/member-profile/member-profile.component';
import { MembersComponent } from './components/Member/members/members.component';
import { MinutesOfMeetingComponent } from './components/Member/minutes-of-meeting/minutes-of-meeting.component';
import { NewRegistrationComponent } from './components/Member/new-registration/new-registration.component';
import { CreateNotificationsComponent } from './components/Member/notifications/create-notifications/create-notifications.component';
import { ListNotificationsComponent } from './components/Member/notifications/list-notifications/list-notifications.component';
import { PollManagementComponent } from './components/Member/poll-management/poll-management.component';
import { RequestsComponent } from './components/Member/requests/requests.component';
import { TransferMemComponent } from './components/Member/transfer-mem/transfer-mem.component';
import { TransferMemberComponent } from './components/Member/transfer-member/transfer-member.component';
import { VerifyComponent } from './components/Member/verify/verify.component';
import { NoticeBoardComponent } from './components/notice-board/notice-board.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { PresidentDeskComponent } from './components/president-desk/president-desk.component';
import { RegisterComponent } from './components/register/register.component';
import { AuthguardService } from './components/sevice/authguard.service';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { Phase4Component } from './phase4/phase4.component';



export const routes: Routes = [
  { path: '',pathMatch: 'full', redirectTo: 'home'},

  { path: 'home', component: HomeComponent },
 { path: 'welcome', component: HomeComponent },
 { path: 'welcome_jhchbs', component: HomeComponent },
 { path: 'launch', component: HomeComponent },
 // { path: 'login/:selection', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'memberLogin', component: LoginComponent },
  { path: 'staffLogin', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'create-password/:memberId', component: CreatePasswordComponent},
  { path: 'newRegistrations', component: NewRegistrationComponent,canActivate : [AuthguardService],data:{role :"admin"} },
  { path: 'transfer-member/:memberId', component: TransferMemberComponent,canActivate : [AuthguardService],data:{role :"admin"} },
  { path: 'member-transfer', component: TransferMemComponent, canActivate : [AuthguardService],data:{role :"admin"} },
  { path: 'poll-management', component: PollManagementComponent , canActivate : [AuthguardService],data:{role :"all"} },
  { path: 'members', component: MembersComponent,canActivate : [AuthguardService],data:{role :"admin"} },
  { path: 'dashboard', component: DashboardComponent,canActivate : [AuthguardService],data:{role :"admin"} },
  { path: 'Dashboard', component: MemberDashboardComponent,canActivate : [AuthguardService],data:{role :"member"} },
  { path: 'requests', component: RequestsComponent,canActivate : [AuthguardService],data:{role :"all"} },
  
  // { path: 'requests', component: RequestsComponent},
  { path: 'member-verify/:memberId', component: VerifyComponent,canActivate : [AuthguardService],data:{role :"admin"}},
  { path: 'create-notifications', component: CreateNotificationsComponent,canActivate : [AuthguardService],data:{role :"admin"}},
  { path: 'edit-notifications/:notificationId', component: CreateNotificationsComponent,canActivate : [AuthguardService],data:{role :"admin"}},
  { path: 'list-notifications', component: ListNotificationsComponent,canActivate : [AuthguardService],data:{role :"admin"}},
  { path: 'member-profile/:memberId', component: MemberProfileComponent,canActivate : [AuthguardService],data:{role :"all"} },
  { path: 'create-member', component: MemberProfileComponent,canActivate : [AuthguardService],data:{role :"admin"} },
  // { path: 'member-verify', component: VerifyComponent,canActivate : [AuthguardService] },
  { path: 'aboutus/:selection', component: AboutusComponent},
  { path: 'president-desk', component: PresidentDeskComponent},
  { path: 'our-services', component: OurServicesComponent},
  { path: 'latest-news', component: LatestNewsComponent},
  { path: 'latest-news/:postName/:notificationId', component: LatestNewsComponent},
  { path: 'minutes-of-meeting', component: MinutesOfMeetingComponent},
  { path: 'minutes-of-meeting/:postName/:notificationId', component: MinutesOfMeetingComponent},
  { path: 'notice-board', component: NoticeBoardComponent},
  { path: 'notice-board/:postName/:notificationId', component: NoticeBoardComponent},
  { path: 'photo-gallery', component: PhotoGalleryComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'termsandconditions', component: TermsandconditionsComponent},
  { path: 'change-password/:memberId', component: ChangePasswordComponent,canActivate : [AuthguardService],data:{role :"member"}},
  { path: 'phase4', component: Phase4Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
