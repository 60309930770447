import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons,NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../sevice/alert.service';
import { MembersService } from '../../sevice/members.service';
import { NotificationService } from '../../sevice/notification.service';
import { Globalconfig } from '../../utility/global.config';
import { LocalDataSource } from 'ng2-smart-table';
import  * as _ from 'lodash';
// import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

export class registeredMember {
	id;
	firstName: string;
	lastName;
	mobileNumber;
	whatsappNumber;
	email;
	msNo;
	plotNo;
	roadNo;
	loginCreated;
	memberId;
	memberStatus;
	salutation;
}
export class registerMember {
	id;
	memberId;
	salutation;
	firstName;
	lastName;
	mobileNumber;
	whatsappNumber;
	designation;
	email;
	address;
	approvedBy;
	approvedDate;
	updatedDate;
	updateApprovedBy;
	activeStatus;
	transferredFrom;
	transferredTo;
	transferredDate;
	password;
	passwordUpdated;
	userType;
	plotNo;
	roadNo;
	plotarea;
	plotadmdate;
	memberType;
  profileImage;
}
export class existMember {
	id;
	memberId;
	salutation;
	firstName;
	lastName;
	mobileNumber;
	whatsappNumber;
	designation;
	email;
	address;
	approvedBy;
	approvedDate;
	updatedDate;
	updateApprovedBy;
	activeStatus;
	transferredFrom;
	transferredDate;
	password;
	passwordUpdated;
	userType;
	plotNo;
	roadNo;
	plotarea;
	plotadmdate;
  profileImage;
	memberType;
}
export class permanentExistMember {
	id;
	memberId;
	salutation;
	firstName;
	lastName;
	mobileNumber;
	whatsappNumber;
	designation;
	email;
	address;
	approvedBy;
	approvedDate;
	updatedDate;
	updateApprovedBy;
	activeStatus;
	transferredFrom;
	transferredDate;
	password;
	passwordUpdated;
	userType;
	plotNo;
	roadNo;
	plotarea;
	plotadmdate;
  profileImage;
	memberType;
	showLoader:boolean =false;
}



export class dateModel {
	transferredDate;
}

@Component({
  selector: 'app-transfer-mem',
  templateUrl: './transfer-mem.component.html',
  styleUrls: ['./transfer-mem.component.scss']
})
export class TransferMemComponent implements OnInit {

  constructor(private notificationService: NotificationService, private config: Globalconfig, private memberService: MembersService, private modalService: NgbModal, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
	/**************************************************Attributes starts here********************************************** */
	private sub: any;
	private permanentSub: any;
	registerMember: registerMember;
	searchValue: string = "";
	permanentSearchValue: string = "";
	showDetailsForm: boolean = false;
	permanentShowDetailsForm: boolean = false;
	showGrid: boolean = false;
	permanentShowGrid: boolean = false;
	showInsertButton: boolean = true;
	permanentShowInsertButton: boolean = true;
	showLabel: boolean = true;
	permanentShowLabel: boolean = true;
	selectedSearchFilter: string = "";
	permanentSelectedSearchFilter: string = ""
	showNoRecord: boolean = false;
	permanentShowNoRecord: boolean = false;
	showRejectButton: boolean = true;
	permanentShowRejectButton: boolean = true;
	showApproveButton: boolean = false;
	permanentShowApproveButton: boolean = false;
	existMember: existMember;
	permanentExistMember: permanentExistMember;
	showVerifyContent: boolean = false;
	permanentShowVerifyContent: boolean = false;
	showLoader:boolean =false;
	permanentShowLoader:boolean =false;
	OldMemberName;
	permanentOldMemberName;
 	OldMemberId;
 	permanentOldMemberId;
	 closeResult: string;
	 dateModel: dateModel;
	 membersData = [];
	 selectedRows :any = [];
	id: string ;


	/**************************************************Attributes ends here********************************************** */
	/************************************************* Table settings starts here **************************************  */
	source: LocalDataSource;
	permanentSource: LocalDataSource;
	settings = {
		actions: false,
		columns: {
			memberId: {
				title: 'Member Id',
				filter: false,
			},
			firstName: {
				title: 'First Name',
				filter: false,
			},
			// email: {
			// 	title: 'Email',
			// 	filter: false,
			// },
			plotNo: {
				title: 'Plot number',
				filter: false,
			},
			roadNo: {
				title: 'Road number',
				filter: false,
			}
		},
	};
  ngOnInit(): void {
		this.registerMember = new registerMember(); ////Initalizing the class from registerMember model
		this.existMember = new existMember();///Initializing the class from existMember model
		this.permanentExistMember = new permanentExistMember();///Initializing the class from existMember model

		this.existMember.salutation = "";///Initialized salution for dropdown content
		this.permanentExistMember.salutation = "";///Initialized salution for dropdown content

		this.existMember.memberType="";//Initialized salution for dropdown content
		this.permanentExistMember.memberType="";//Initialized salution for dropdown content

		this.dateModel = new dateModel();///Initializing the class from Date model;
		///Parameters to get the route path value from URL 
		this.sub = this.route.params.subscribe(params => {
			this.getMemberDetails("existMember", params['memberId']);
		})
		this.permanentSub = this.route.params.subscribe(params => {
			this.getPermanentMemberDetails("registerMember", params['memberId']);
		})
		this.getAllMemberDetails();
  }
  getAllMemberDetails() {
    this.memberService.getMemberDetails("existMembers").subscribe((response: any) => {
      if (response['data'].length != 0){
        this.membersData = response['data'].reverse();
        this.memberService.allMembers = this.membersData
      }
      else {
        this.membersData = []
      }
    })
  }
  /******************************************************Method to retrive the member detaisl
	 * @Param selection inputs like ("newRegistered","existMember")
	 * memberId retriving from the URL path from ngOnInit()
	 */
	getMemberDetails(selection,id) {
		this.memberService.getMemberDetailsByMemberId(selection,id).subscribe(async (response: any) => {
				 if(response != null && response['data'] != null){	
					       this.registerMember = response['data'];
						   this.showVerifyContent = true;
				 }	   
		}, error => {
			this.toastr.showError("Failed to retrive data", "");
			this.router.navigate(['/members']);
		})

	}

	/*****************************************Convert Transfer Date input formtat to SQL string format
	 * @Param Date;
	 */
	 dateToString(date) {
		var formattedDate = "";
	
		if (date.month < 10)
			date.month = "0" + date.month;
		if (date.day < 10)
			date.day = "0" + date.day;
		formattedDate = date.year + "-" + date.month + "-" + date.day;
		var IsDate = new Date(formattedDate).toISOString();
		return IsDate;
	}
	/*****************************************Convert Transfer Date input formtat from SQL string format to Input format
	 * @Param Date string format
	 */
	 StringToDate(value: string) {
		if (!value)
			return null
		let parts = value.split('-');
		return { year: +parts[0], month: +parts[1], day: +parts[1] } as NgbDateStruct;
	}
  	/**********************************************Method to search Data input given in search box */
	onSearchChange() {
		if (this.searchValue.length == 0) {
			this.toastr.showError("Please enter value in search.", "");
			this.showDetailsForm = false;
			this.showGrid = false;
			this.showInsertButton = true;
			this.showLabel = true;
			return;
		}
		if (this.selectedSearchFilter == null || this.selectedSearchFilter == undefined || this.selectedSearchFilter == "") {
			this.toastr.showError("Please select search by", "");
			this.showDetailsForm = false;
			this.showGrid = false;
			this.showInsertButton = true;
			this.showLabel = true;
			
			return;
		}
		var captalizedValue = this.capitalize(this.searchValue);
		this.memberService.searchMember(captalizedValue, this.selectedSearchFilter,"Transferscreen").subscribe((response: any) => {
			if (response['data'] == null || response['data'].length == 0) {
				this.showDetailsForm = false;
				this.showGrid = false;
				this.showInsertButton = true;
				this.showLabel = false;
				this.showNoRecord = true;
				this.showRejectButton = true;
				this.showApproveButton = false;
			} else {
				this.showDetailsForm = false;
				this.showGrid = true;
				this.showLabel = false;
				this.showNoRecord = false;
				if (response['data'] == "Approved") {
					this.showApproveButton = false;
					this.showRejectButton = false;
					this.showInsertButton = false;
				} else if (response['data'] == "Rejected") {
					this.showApproveButton = false;
					this.showRejectButton = false;
					this.showInsertButton = false;
				} else {
					this.showApproveButton = false;
					this.showRejectButton = true;
					this.showInsertButton = true;
				}
				this.source = new LocalDataSource(response['data']);
			}
		}, error => {
			this.toastr.showError("Failed to retrive data", "");
			this.router.navigate(['/members'])
		})
	}
	/*************************************************Method to capitalize the Input string given in search box
	 * @Param searchValue;
	 */
	 capitalize(searchValue) {
		if (typeof searchValue !== 'string') return ''
		return searchValue.charAt(0).toUpperCase() + searchValue.slice(1)
	}
		/************************************************Show Details when click on Grid ***************************************
	 * @description:- On rowselect event triggers when we click the selection on Grid.
	 * @Param :- selected event will be the param for Onrow select method 
	*/
	onRowSelect(event) {
		this.existMember = event.data;
    	this.OldMemberId=this.existMember.memberId;
    	this.OldMemberName= this.existMember.firstName + this.existMember.lastName;
		if(this.existMember.memberType == undefined || this.existMember.memberType == null ||this.existMember.memberType == "")
		this.existMember.memberType = ""
		this.openForm("existMember");
		
	}

	/******************************************Method to open Form when click on Create New 
	 * @description :- open form will show the form when we select to show the exist memeber or new registered member details in the form
	 * @param :- selection (Ex: existMember, newRegisteredMember)
	 * 
	*/
	openForm(selection) {
		if (selection != "existMember") {
			this.existMember.salutation=this.registerMember.salutation;
			this.existMember.firstName = this.registerMember.firstName;
			this.existMember.lastName = this.registerMember.lastName;
			this.existMember.email = this.registerMember.email;
			this.existMember.memberId = this.registerMember.memberId;
			this.existMember.mobileNumber = this.registerMember.mobileNumber;
			this.existMember.whatsappNumber = this.registerMember.whatsappNumber;
			this.existMember.plotNo = this.registerMember.plotNo;
			this.existMember.roadNo = this.registerMember.roadNo;
		}
		this.showGrid = false;
		this.showDetailsForm = true;
		this.showInsertButton = false;
		this.showApproveButton = true;
		this.showRejectButton = true;
		this.showLabel = false;
		this.showNoRecord= false;
	}
	/***********************************To clear form details ***************************************************************** */
	Clearform() {
		this.existMember = new existMember();
	}
	async checkUserLogin(){
		var response = await this.memberService.checkUserLogin(this.OldMemberId).toPromise();
		if (response['data'])
			this.UpdateStatus("Approve");
		   else{
			 this.toastr.showError("Action can perform only on login created members","");
		   } 
	  }
/**************Update status for member profile **************************************************
	 * @description :- updateStatus is to update the member and member registration based on status and matching from table data
	 * @param:- selected status is the input to update the member and member registration tables
	*/
	UpdateStatus(status) {
		this.showLoader=true;
	if(status != "Reject"){
		if ((this.existMember.salutation == "" || this.existMember.salutation == undefined || this.existMember.salutation == null)) {
			this.toastr.showError("Please select salutation.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.firstName == "" || this.existMember.firstName == undefined || this.existMember.firstName == null) {
			this.toastr.showError("Please enter firstname.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.memberId == "" || this.existMember.memberId == undefined || this.existMember.memberId == null) {
			this.toastr.showError("Please enter memberId.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.lastName == "" || this.existMember.lastName == undefined || this.existMember.lastName == null) {
			this.toastr.showError("Please enter lastname.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.email == "" || this.existMember.email == undefined || this.existMember.email == null) {
			this.toastr.showError("Please enter email.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.mobileNumber == "" || this.existMember.mobileNumber == undefined || this.existMember.mobileNumber == null) {
			this.toastr.showError("Please enter mobile number.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.memberType == "" || this.existMember.memberType == undefined || this.existMember.memberType == null || this.existMember.memberType == "Non_member_resident") {
			this.toastr.showError("Please select permanent member type.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.whatsappNumber == "" || this.existMember.whatsappNumber == undefined || this.existMember.whatsappNumber == null) {
			this.toastr.showError("Please enter whatsapp number.", "")
			this.showLoader=false;
			return;
		}
		if (this.existMember.address == "" || this.existMember.address == undefined || this.existMember.address == null) {
			this.toastr.showError("Please enter address.", "")
			this.showLoader=false;
			return;
		}
		if ((this.existMember.plotNo == "" || this.existMember.plotNo == undefined || this.existMember.plotNo == null) && this.existMember.memberType != "Waitlisted_member") {
			this.toastr.showError("Please enter enter plot number.", "")
			this.showLoader=false;
			return;
		}
		if ((this.existMember.roadNo == "" || this.existMember.roadNo == undefined || this.existMember.roadNo == null) && this.existMember.memberType != "Waitlisted_member") {
			this.toastr.showError("Please enter road number.", "")
			this.showLoader=false;
			return;
		}
		if ((this.dateModel.transferredDate == "" || this.dateModel.transferredDate == undefined || this.dateModel.transferredDate == null) && this.dateModel.transferredDate != "Waitlisted_member") {
			this.toastr.showError("Please enter Transferred date.", "")
			this.showLoader=false;
			return;
		}

		if(this.existMember.memberType == "Non_member_resident" && !this.existMember.memberId.endsWith("-T")) {
			this.toastr.showError("Member Id should end with '-T' for Non Member residents", "")
			this.showLoader=false;
			return;
		}
	
		if(this.existMember.memberType != "Non_member_resident" && isNaN(this.existMember.memberId)) {
		  this.toastr.showError("Member Id should be a numberic value for members", "")
		  this.showLoader=false;
		  return;
	  }
	  if(this.existMember.memberType != "Non_member_resident" && isNaN(this.existMember.memberId)) {
		  this.toastr.showError("Member Id should be a numberic value for members", "")
		  this.showLoader=false;
		  return;
	  }
    this.existMember.transferredFrom= this.registerMember.firstName+ this.registerMember.lastName +"("+this.registerMember.memberId+")";
    this.registerMember.transferredFrom= this.existMember.firstName+ this.existMember.lastName +"("+this.existMember.memberId+")";
    
	this.existMember.transferredDate = this.dateToString(this.dateModel.transferredDate);
    // this.existMember.profileImage= this.registerMember.profileImage;
	this.registerMember.activeStatus = "Transferred";

	this.existMember.address = this.registerMember.address;
	this.existMember.plotNo = this.registerMember.plotNo;
	this.existMember.roadNo = this.registerMember.roadNo;
		// this.existMember.memberId = id;
		// this.registerMember.memberId = 0;
		
		
		this.existMember.memberId = this.registerMember.memberId;
	

    this.memberService.transferMember(this.existMember,this.OldMemberId,this.existMember.memberId).subscribe(response =>{
		this.toastr.showSuccess("Member Transferred Successfully", ""); 

		this.router.navigate(['/members']);
    })
	this.memberService.transferMember(this.registerMember,this.permanentOldMemberId,this.registerMember.memberId).subscribe(response =>{
	});
	
    console.log(this.existMember);
	console.log(this.registerMember);
	}	
	
}	



	/**********************************************Permanent Setting ******************************/


	/******************************************************Method to retrive the member detaisl
	 * @Param selection inputs like ("newRegistered","existMember")
	 * memberId retriving from the URL path from ngOnInit()
	 */
	 getPermanentMemberDetails(selection,id) {
		this.memberService.getMemberDetailsByMemberId(selection,id).subscribe(async (response: any) => {
				 if(response != null && response['data'] != null){	
					       this.registerMember = response['data'];
						   this.permanentShowVerifyContent = true;
				 }	   
		}, error => {
			this.toastr.showError("Failed to retrive data", "");
			this.router.navigate(['/members']);
		})

	}

	

	/**********************************************Method to search Data input given in search box */
	onPermanentSearchChange() {
		const filterData = []
		if (this.permanentSearchValue.length == 0) {
			this.toastr.showError("Please enter value in search.", "");
			this.permanentShowDetailsForm = false;
			this.permanentShowGrid = false;
			this.permanentShowInsertButton = true;
			this.permanentShowLabel = true;
			return;
		}
		if (this.permanentSelectedSearchFilter == null || this.permanentSelectedSearchFilter == undefined || this.permanentSelectedSearchFilter == "") {
			this.toastr.showError("Please select search by", "");
			this.permanentShowDetailsForm = false;
			this.permanentShowGrid = false;
			this.permanentShowInsertButton = true;
			this.permanentShowLabel = true;
			
			return;
		}
		var permanentCaptalizedValue = this.permanentCapitalize(this.permanentSearchValue);
		
				 _.map(this.memberService.allMembers, (registerMember:any) => {
					 switch(this.permanentSelectedSearchFilter) {
						 
						case("memberId") : {

							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.memberId, permanentCaptalizedValue))){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
						case("firstName") : {
							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.firstName.toUpperCase(), permanentCaptalizedValue)) ){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
						case("lastName") : {
							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.lastName.toUpperCase(), permanentCaptalizedValue)) ){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
						case("mobileNumber") : {
							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.mobileNumber, permanentCaptalizedValue)) ){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
						case("email") : {
							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.email.toUpperCase(), permanentCaptalizedValue)) ){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
						case("plotNumber") : {
							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.plotNo, permanentCaptalizedValue)) ){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
						case("roadNumber") : {
							if((!_.includes(registerMember.memberId, '-T')) && (!_.includes(registerMember.activeStatus, 'Transferred')) && (_.includes(registerMember.roadNo, permanentCaptalizedValue)) ){
								filterData.push(registerMember);
								this.permanentShowGrid = true;
								this.permanentShowLabel = false;
								this.permanentShowNoRecord = false;
								this.permanentShowDetailsForm = false;
								
							}else if(filterData.length == 0) {
								this.permanentShowNoRecord = true;
								this.permanentShowLabel = false;
								this.permanentShowGrid = false;
								this.permanentShowDetailsForm = false;
							} 
						}
						break;
					 }
				} ) 
				this.permanentSource = new LocalDataSource(filterData);

	}

	
	/*************************************************Method to capitalize the Input string given in search box
	 * @Param searchValue;
	 */
	 permanentCapitalize(permanentSearchValue) {
		if (typeof permanentSearchValue !== 'string') return ''
		return permanentSearchValue.toUpperCase()
	}

		/************************************************Show Details when click on Grid ***************************************
	 * @description:- On rowselect event triggers when we click the selection on Grid.
	 * @Param :- selected event will be the param for Onrow select method 
	*/
	// navigateTo(id){
	// 	if(id != null) 
	// 	this.router.navigate(['/member-profile',id]);
	// 	else
	// 	this.router.navigate(['/create-member']);
	//    }
	// onCustomAction(event) {
	// 	switch (event.action) {
	// 	  case 'verify':
	// 			this.navigateTo(event.data.id);
	// 		break;
	
	// 	}
	//   }
	//   onUserRowSelect(event) {
	// 	this.selectedRows = event.selected;
	//   }
	onPermanentRowSelect(event) {
		// console.log("abcd");
		this.registerMember = event.data;
    	this.permanentOldMemberId=this.registerMember.memberId;
    	this.permanentOldMemberName= this.registerMember.firstName + this.registerMember.lastName;
		// if(this.registerMember.memberType == undefined || this.registerMember.memberType == null || this.permanentExistMember.memberType == "")
		// this.registerMember.memberType = ""
		this.openPermanentForm("registerMember");
	}
	openPermanentForm(selection) {
		if (selection != "permanentExistMember") {
			this.permanentExistMember.salutation=this.registerMember.salutation;
			this.permanentExistMember.firstName = this.registerMember.firstName;
			this.permanentExistMember.lastName = this.registerMember.lastName;
			this.permanentExistMember.email = this.registerMember.email;
			this.permanentExistMember.memberId = this.registerMember.memberId;
			this.permanentExistMember.mobileNumber = this.registerMember.mobileNumber;
			this.permanentExistMember.whatsappNumber = this.registerMember.whatsappNumber;
			this.permanentExistMember.plotNo = this.registerMember.plotNo;
			this.permanentExistMember.roadNo = this.registerMember.roadNo;
		}
		this.permanentShowGrid = false;
		this.permanentShowDetailsForm = true;
		this.showInsertButton = false;
		this.showApproveButton = true;
		this.showRejectButton = true;
		this.showLabel = false;
		this.showNoRecord = false;
	}
	// onRowSelect(event) {
	// 	this.existMember = event.data;
    // 	this.OldMemberId=this.existMember.memberId;
    // 	this.OldMemberName= this.existMember.firstName + this.existMember.lastName;
	// 	if(this.existMember.memberType == undefined || this.existMember.memberType == null ||this.existMember.memberType == "")
	// 	this.existMember.memberType = ""
	// 	this.openForm("existMember");
	// }



	
	/*************************************Methods to perform the actions on Model popup for confirmation and rejection popups starts here
	 *
	 */
	 open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
		// alert("Member Transferred SuccessFully")
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}

