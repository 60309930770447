<div class="container-fluid">
    <div class="row align-items-start text-center">

        <div class="col-md-2 order-md-1 js-hr-ads ">
            <div class="col my-5">
              <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded ">
            <div class="container-fluid vision-div">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class=""></h1>
                        <div class="text-center jh-heading">Phase IV</div>

                    </div>

                    <div class="col-md-12 commitee_member president_desk">
                       <p>Meeting for members of Jubilee Hills Co-operative House Building Society held on 22nd January to discuss allotment options for members without plots (Waitlisted Members). </p>
                       <p>In the meeting held on 22-01-23 it was decided to form a committee to give shape to the initiative taken up by Society to allot flats to members without any prior allotments. Those Interested and  keen to contribute as a Committee member can send in their details along with a short writeup of your experience and expertise.</p>
                       <!-- <br> -->
                       <p>Click on the below link to share your details </p>
                       <!-- <br>  -->

                        <a href="https://forms.gle/6Zac13wW5RY5mDJq6" target="_blank">Link to google form</a>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 order-md-3 js-hr-ads">
            <div class="col my-5">
              <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>
    </div>
</div>

