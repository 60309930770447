<div class="container-fluid">
    <div class="row align-items-start text-center">

        <div class="col-md-2 order-md-1 js-hr-ads">
            <div class="col my-5">
              <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded ">
            <div class="container-fluid vision-div">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class=""></h1>
                        <div class="text-center jh-heading">Our Services</div>
                    </div>

                    <!-- <ul class="text-start col-md-6 mx-auto">
                      <li>Membership Transfer through sale</li>
                      <li>Membership Transfer through legal heir</li>
                      <li>Membership Transfer through gift deed</li>
                      <li>Membership Transfer through nomination</li>
                      <li>NOC for Building Permission</li>
                      <li>Issue of xerox copies of documents</li>
                      <li>Issue of No dues certificates/mortgage letter</li>
                      <li>Issue of Accounts Statement (member)</li>
                      <li>Issue of duplicate share certificate</li>
                      <li>Demarcation of plot</li>
                      <li>Club application endorsement charges</li>
                      <li>Registration of Plot</li>
                  </ul> -->

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-3 jh-services-div ">
                        <div class="col-md-12 shadow p-4 my-3 ">
                          Membership Transfer through sale
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div ">
                        <div class="col-md-12 shadow p-4 my-3 ">
                          Membership Transfer through legal heir
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Membership Transfer through gift deed
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Membership Transfer through nomination
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          NOC for Building Permission
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Issue of xerox copies of documents
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Issue of No dues certificates/mortgage letter
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Issue of Accounts Statement (member)
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Issue of duplicate share certificate
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div  ">
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Demarcation of plot
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Club application endorsement charges
                        </div>
                      </div>
                      <div class="col-md-3 jh-services-div" >
                        <div class="col-md-12 shadow  p-4 my-3 ">
                          Registration of Plot
                        </div>
                      </div>

                    </div>
                  </div>





                    <div class="col-md-12 commitee_member services mt-4">
                      <h5 class="text-center">Service Details</h5>
                        <table width="764" class="table table-striped ">
                            <tbody>
                                <tr>
                                    <td colspan="4" width="100%">
                                        <strong>Administrative Charges</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="66">
                                        <strong>S. No</strong>
                                    </td>
                                    <td width="350">
                                        <strong>Work Description</strong>
                                    </td>
                                    <td width="161">
                                        <strong>Charges</strong>
                                    </td>
                                    <!-- <td width="188">
                                        <strong>Issue Period</strong>
                                    </td> -->
                                </tr>
                                <tr>
                                    <td width="66">1</td>
                                    <td width="350">Issue of xerox copies of documents</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1,000</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                                                        <!-- <td width="188"></td> -->

                                </tr>
                                <tr>
                                    <td width="66">2</td>
                                    <td width="350">Issue of No dues certificates/mortgage letter</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1,000</td>
                                    <!-- <td width="188">5 Working Dyas</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">3</td>
                                    <td width="350">Issue of Members Accounts Statement</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1,000</td>
                                    <!-- <td width="188">5 Working Dyas</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">4</td>
                                    <td width="350">Issue of duplicate share certificate</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5,000</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">5</td>
                                    <td width="350">Demarcation of Plot</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 10,000</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">6</td>
                                    <td width="350">NOC for Building Permission</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5,000</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">7</td>
                                    <td width="350">Club application endorsement</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2,500</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">8</td>
                                    <td width="350">Regiatration of Plot</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 25,000</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">9</td>
                                    <td width="350">Sale permission Certificate</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2,50,000</td>
                                    <!-- <td width="188">7 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">10</td>
                                    <td width="350">Transfer of membership (through sale deed)</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5,00,000</td>
                                    <!-- <td rowspan="2" width="188">14 Working Days</td> -->
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66">11</td>
                                    <td width="350">Transfer of membership (through gift deed)</td>
                                    <td width="161">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2,00,000</td>
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td width="66"></td>
                                    <td width="350"></td>
                                    <td width="161"></td>
                                    <!-- <td width="188"></td> -->
                                </tr>
                                <tr>
                                    <td colspan="2" width="416">
                                        <strong>Note: Subject to change from time to time. GST of 18% will be applied to the above charges</strong>
                                    </td>
                                    <td width="161"></td>
                                    <!-- <td width="188"></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 order-md-3 js-hr-ads">
            <div class="col my-5">
              <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>
    </div>
</div>


