import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { routes } from 'src/app/app-routing.module';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService  implements CanActivate {
   roleAs ="";
   @Output() isUserLogin: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router,private toastr: AlertService,private authService:AuthService ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {      
    if (this.isLoggedIn()) { 
       const userRole = this.getRole();
       if(route.data.role == "all")     
            return true;
       if(route.data.role && route.data.role == userRole)   
            return true;   
       
          
             this.toastr.showError("Required access permissions for requested path", "");  
             this.authService.navigate();
             this.router.navigate(['/memberLogin']);   

            return false;   
           

    }      
    // navigate to login page as user is not authenticated      
 this.router.navigate(['/staffLogin']);      
return false;      
}      
public isLoggedIn(): boolean {      
 let status = false;      
 if (localStorage.getItem('isLoggedIn') == "true") {      
    status = true;      
 }    
 else {      
    status = false;      
    }      
 return status;      
 } 
 getRole() {
   this.roleAs = localStorage.getItem('role')
   return  this.roleAs ;
 }
}