import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poll-management',
  templateUrl: './poll-management.component.html',
  styleUrls: ['./poll-management.component.scss']
})
export class PollManagementComponent implements OnInit {
  memberId: string;
  memberMainId: string;
  isAdmin: boolean = false;
  settings: any;
  adminGridDetails: any;
  isMember: boolean = false;
  memberGridDetails: any;
  servicesData: any[];

  constructor() { }

  ngOnInit(): void {
    this.memberId = localStorage.getItem("authenticateId");
    this.memberMainId = localStorage.getItem("authenticatemainId");
    if (localStorage.getItem("role") == "admin") {
      this.isAdmin = true;
      this.isMember = false;
      this.settings = this.adminGridDetails;
    } else {
      this.isMember = true;
      this.isAdmin = false;
      this.settings = this.memberGridDetails;
    } this.servicesData = [];
  }

}
