import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../sevice/alert.service';
import { DbServiceService } from '../sevice/db-service.service';
import { FileUploadService } from '../sevice/file-upload.service';
import { Globalconfig } from '../utility/global.config';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  fileName = '';
  imgUrl: string;

  galleryName: string;
  images: any;

  galleries: any;
  gallery: any;

  isLoggedin: boolean = false;

  isPopUpshow: boolean = false;
  isAdmin: boolean = false;

  isAdd: boolean = false;

  constructor(private globals : Globalconfig,private upload_service: FileUploadService, private db_service: DbServiceService, private toastr: AlertService, public router: Router) { }

  ngOnInit(): void {
    this.getAllGalleries();
    if (localStorage.getItem("role") == "admin") {
      this.isAdmin = true;
    }
    if (localStorage.getItem("isLoggedIn")) {
      this.isLoggedin = true;
    }
    else {
      this.isLoggedin = false;
    }
  }

  navigateTo(){
    if(this.isLoggedin && this.isAdmin){
      this.router.navigate(['/newRegistrations']);
    }
    if(this.isLoggedin && !this.isAdmin){
      this.router.navigate(['/requests']);

    }
  }

  onFileSelected(event) {
    if (event.target.files.length > 1) {
      this.toastr.showError("Please select only one image.", "");
    }
    else {
      for (let i = 0; i < event.target.files.length; i++) {
        const file: File = event.target.files[i];
        this.upload(i, file);

      }
    }

  }


  upload(idx, file) {

    // this.images[idx] = { value: 0, fileName: file.name };
    const fileExtension = file.name.split('.').pop();

    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG"];

    if (this.isInArray(allowedExtensions, fileExtension)) {

      if (this.images == undefined) {
        this.images = [];
        this.images.push({ value: 0, name: file.name, createdBy: 123, lastUpdatedBy: 123 });
        this.fileName = file.name;
      } else {
        this.images.push({ value: this.images.length, name: file.name, createdBy: 123, lastUpdatedBy: 123 });
      }
      this.upload_service.uploadFileToS3(file, this.galleryName).subscribe(
        response => {

          this.imgUrl = this.globals.cf_url+'/' + response.Key;
          this.images[this.images.length - 1]["img_url"] = this.imgUrl;

          this.toastr.showSuccess("Image uploaded successfully.", "");

          // if (idx + 1 == this.images.length) {
          //   this.saveGallery();
          // }
        },
        error => {
          console.log('An error occured while uploading the image.');
        })
    } else {
      this.toastr.showError("Please upload only images.", "");
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  saveGallery() {
    var gallerydata = {
      "galleryName": this.galleryName,
      "createdBy": 123,
      "lastUpdatedBy": 123,
      "images": this.images
    }

    var index = "/api/gallery/"
    this.db_service.saveDataToDb(index, gallerydata).subscribe(
      response => {
        this.toastr.showSuccess("Gallery details saved successfully.", "");
        this.getAllGalleries();
        this.isAdd = false;
      },
      error => {
        this.toastr.showError("An error occured while saving gallery data","");
      }
    )
  }

  getAllGalleries() {
    var index = "/api/gallery/"
    this.db_service.getDataFromDb(index).subscribe(
      response => {
        this.galleries = response["data"];
      },
      error => {
        this.toastr.showError("An error occured while saving gallery data","");
      }
    )
  }

  showPopUp(id) {
    this.gallery = this.galleries.find(ga => ga.id == id);
    this.isPopUpshow = true;
  }
  closeModel() {
    this.isPopUpshow = false;
    this.isAdd = false;
  }

  openAddPopUp() {
    this.isAdd = true;
    this.images = [];
    this.galleryName = "";
  }
}
