<div class="container-fluid">
    <div class="row align-items-start text-center">
        <div class="col-md-2 order-md-1 my-auto ">
            <div class="js-hr-ads col my-5">
                <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded jh-login-form">
            <div class="text-center jh-heading">{{headerTitle}}</div>
            <div class="col-md-5 mx-auto">
                <form>
                <div class="my-4 jh-login-input">
            
                    <div class="form-label text-start ms-4">{{usernameLabel}}</div>
                    <input type="email" class="form-control" [(ngModel)]="loginDetails.loginId"
                        [ngModelOptions]="{standalone: true}" id="exampleFormControlInput1" placeholder="id"
                        autocomplete="off">
                </div>

                <div class="my-4 jh-login-input">
                    <div class="form-label text-start ms-4">Password</div>
                    <input type="password" class="form-control" [(ngModel)]="loginDetails.password"
                        [ngModelOptions]="{standalone: true}" id="exampleFormControlInput2" placeholder="Password"
                        autocomplete="off">
                </div>
                <div class="col-auto jh-center-btn">
                    <button type="submit" class="btn jh-btn-primary submit-btn my-4 shadow col-auto px-3"
                        (click)="loginUser()" [disabled] = "loginDetails.loginId == '' ||loginDetails.loginId == undefined||loginDetails.loginId == null">Submit
                        <div class="spinner-border text-success ms-3" role="status" *ngIf="showLoader">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </form>
            </div>
            <div class="col-md-6 mx-auto jh-mem-log-reg" *ngIf = "showForgetAndMemberRegistration">
                <div class="row">
                    <div class="col-md-6 text-center jh-cursor" routerLink="/forgot-password">Forgot Password</div>
                    <div class="col-md-6 text-center jh-cursor" routerLink="/register">New Member Registration</div>
                </div>
            </div>
        </div>

        <div class="col-md-2 order-md-3 my-auto">
            <div class="js-hr-ads col my-5">
                <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>
    </div>

</div>