<div class="container-fluid">
    <div class="row align-items-start text-center">

        <div class="col-md-2 order-md-1 js-hr-ads ">
            <div class="col my-5">
              <app-horizontal-ads></app-horizontal-ads>
            </div>
        </div>
        <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded ">
            <div class="container-fluid vision-div">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class=""></h1>
                        <div class="text-center jh-heading">President Desk</div>

                    </div>

                    <div class="col-md-12 commitee_member president_desk">
                       <p> Dear Members, </p>
                      <p>  As a newly elected President, I would like to take this opportunity to welcome all the Hon’ble Members to the General Body Meeting.</p>
                        
                      <p>  On behalf of the Managing Committee I extend my sincere thanks to everyone for electing us to serve you. We are deeply humbled by your support and we want to assure you that all your concerns will be heard and addressed.
                    </p>  
                     <p>   I would also like to take this opportunity to clarify a few things on the recent happenings which are detrimental to the interest of our society.
                    </p>   
                     <p>  JIC or Jubilee Hills Club as we know it was & is a part of JHCHBS, formed primarily as a recreational facility for society members, their families & residents of Jubilee Hills. The past committee members of JHCHBS are now at the helm of affairs in the club and just a week after assuming office have passed a resolution to delink club from the society by making NOC from the society which was mandatory as no longer needed. This act is assumed to make the society defunct and is detrimental to the interest of the society. The same people while running the society had issued notices to the club for taking permission from the society for smallest of things like building of steps, renovation of kitchen, elevation of Guest rooms etc. So why this Double Talk? Why now & not in all the years when the same people were running society and club?
                    </p>      
                    <p>   I had strongly objected to these amendments in the JIC Governing Council meeting but the same were not noted. EGM was called on 24th October for member approval of these amendments where a lot of members included me have objected to these amendments and the meeting was called to close without discussion or voting on the key amendments. But in the minutes of the meeting circulated to the members it was mentioned that the amendments were approved by 90% of members, which is totally false.
                    </p>   
                    <p>    We have recently passed a no confidence motion against the Secretary, Sri. A. Murali Mukund as he was acting in a unilateral manner and had taken control of the files pertaining to Jubilee Hills Public School of which he has been an office bearer. He was not attending to the affairs of the society for about 6 weeks during which he had locked the record room and was not handing over the keys to the president even after repeated requests. He has frozen the bank accounts, withdrawn the civil case filed by society and blocked the website. This had greatly hampered the functioning of the society due to which we had to pass a No-Confidence motion and remove him as the Secretary. Even after the No-confidence motion he approached various courts and kept the record room closed for 6 weeks with mala fide intention.
                    </p>    
                    <p>    The managing committee of the society is fully committed for the welfare of the society members  and though we have hit a few hurdles along the way we are confident that we will be successful in our efforts and will make Jubilee Hills a better place for all of us to live in.
                    </p>    
                        <div class="sign">
                            <div class="text-end">
                              <!-- <img loading="lazy"  src="https://www.jhchbs.com/wp-content/uploads/2021/04/sig.jpg" width="152" height="107"> -->
                              <img loading="lazy"  src="/assets/images/sig.jpg" width="152" height="107">
                            </div>
                            <span class="float-end">Ravindranath Bollineni</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 order-md-3 js-hr-ads">
            <div class="col my-5">
              <app-horizontal-add2></app-horizontal-add2>
            </div>
        </div>
    </div>
</div>

