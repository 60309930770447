import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/components/sevice/alert.service';
import { MembersService } from 'src/app/components/sevice/members.service';
import { NotificationService } from 'src/app/components/sevice/notification.service';
import { UsersService } from 'src/app/components/sevice/users.service';
import { Globalconfig } from 'src/app/components/utility/global.config';

export class createPasswordModel {
	id;
	memberId;
	password_token;
	password:String;
	confirmPassword:String;
}


@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  constructor(private notificationService: NotificationService,private config : Globalconfig,private memberService: MembersService,private userService: UsersService, private modalService: NgbModal, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
  createPasswordModel: createPasswordModel;
  sub:any
  ngOnInit(): void {
    this.createPasswordModel = new createPasswordModel();
    this.sub = this.route.params.subscribe(params => {
			this.getMemberDetailsByPasswordToken(params['memberId']);
		})
  }


  /******************************************************Method to retrive the member detaisl
	 * @Param selection inputs like ("newRegistered","existMember")
	 * memberId retriving from the URL path from ngOnInit()
	 */
   getMemberDetailsByPasswordToken( memberId) {
		this.memberService.getMemberDetailsByPasswordToken(memberId).subscribe((response: any) => {
				if(response['data'] == null) {
					this.toastr.showError( response['message'],"Error");	
					this.router.navigate(['/memberLogin']);
				} 
				else {
					this.createPasswordModel = response['data'];
					// console.log(this.createPasswordModel);
				}

		}, error => {		
			this.toastr.showError("Error retrieving data","");
		  })

	}

  verifyUser(){

	if(this.createPasswordModel.password == "" || this.createPasswordModel.password == null || this.createPasswordModel.password == undefined || this.createPasswordModel.password.length < 6){
        this.toastr.showError("Password should be entered and should have minimum 6 characters","");
		if(this.createPasswordModel.confirmPassword == "" || this.createPasswordModel.confirmPassword == null || this.createPasswordModel.confirmPassword == undefined || this.createPasswordModel.confirmPassword.length < 6){
			this.toastr.showError(" Confirm Password should be entered and should have minimum 6 characters","");
			return;
		}
        return;
    }
	if(this.createPasswordModel.confirmPassword != this.createPasswordModel.password) {
		this.toastr.showError("Password does not match with confirm password","");
		return;
	}
	  
	
    this.userService.createPassword(this.createPasswordModel).subscribe((response:any) =>{
      if((response['status'] == "200")){
		if(response['data'] == null) {
			this.toastr.showError( response['message'],"Error");	
		} 
		else {
      		this.toastr.showSuccess("Password created successfully.","");
      		this.router.navigate(['/memberLogin']);
		}
      }
    },error =>{
      this.router.navigate(['/memberLogin']);
    })
      
  }
}
