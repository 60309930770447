import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../sevice/alert.service';
import { MembersService } from '../../sevice/members.service';
import { NotificationService } from '../../sevice/notification.service';
import { Globalconfig } from '../../utility/global.config';
import { DatePipe } from '@angular/common'
import { FileUploadService } from '../../sevice/file-upload.service';
import { Pattern } from '@amcharts/amcharts4/core';
export class existMember {
  id;
  memberId;
  salutation;
  firstName;
  lastName;
  mobileNumber;
  whatsappNumber;
  designation;
  email;
  address;
  approvedBy;
  approvedDate;
  updatedDate;
  updateApprovedBy;
  activeStatus;
  transferredFrom;
  transferredDate;
  password;
  passwordUpdated;
  userType;
  plotNo;
  roadNo;
  plotarea;
  plotadmdate;
  memberType;
  profileImage;
}
export class dateModel {
  transferredDate;
}

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileComponent implements OnInit {

  constructor(private notificationService: NotificationService, private config: Globalconfig,private upload_service: FileUploadService, private memberService: MembersService, private modalService: NgbModal, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
  /**************************************************Attributes starts here********************************************** */
  private sub: any;
  existMember: existMember;
  dateModel: dateModel;
  isNew:boolean =false;
  showUpdateMember: boolean = false;
  isAdmin: boolean = false;
  ngOnInit(): void {
    this.existMember = new existMember();///Initializing the class from existMember model
    this.dateModel = new dateModel();///Initializing the class from Date model;
    if (localStorage.getItem("role") == "admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.sub = this.route.params.subscribe(params => {
     if(params['memberId'] != null){
       this.isNew=false; 
      if (this.isAdmin)
        this.getMemberDetails("existMember", params['memberId']);
      else
        this.getMemberDetailsByMemberId("existMember", params['memberId']);
     }else{
       this.isNew=true;
       this.existMember = new existMember();
       this.existMember.salutation="";
       this.existMember.memberType="";
      this.existMember.profileImage="/assets/images/images/Profile2.png";
       
     }  
    })
  }
  /******************************************************Method to retrive the member detaisl
     * @Param selection inputs like ("newRegistered","existMember")
     * memberId retriving from the URL path from ngOnInit()
     */
  getMemberDetails(selection, memberId) {
    this.memberService.getMemberDetailsById(selection, memberId).subscribe((response: any) => {
      this.existMember = response['data'];
      if(this.existMember.profileImage == null || this.existMember.profileImage == undefined || this.existMember.profileImage == "")
      this.existMember.profileImage="/assets/images/images/Profile2.png";

      if (this.existMember.activeStatus == undefined || this.existMember.activeStatus == null || this.existMember.activeStatus == "")
        this.showUpdateMember = false;
      else
        this.showUpdateMember = true;
      if (this.existMember.memberType == undefined || this.existMember.memberType == null || this.existMember.memberType == "")
        this.existMember.memberType = ""
   




      this.dateModel.transferredDate = this.StringToDate(this.existMember.transferredDate);

    }, error => {
      this.toastr.showError("Failed to retrive data", "");
    })

  }

  getMemberDetailsByMemberId(selection, memberId) {
    this.memberService.getMemberDetailsByMemberId(selection, memberId).subscribe((response: any) => {
      this.existMember = response['data'];
      if(this.existMember.profileImage == null || this.existMember.profileImage == undefined || this.existMember.profileImage == "")
      this.existMember.profileImage="/assets/images/images/Profile2.png";
      if (this.existMember.activeStatus == undefined || this.existMember.activeStatus == null || this.existMember.activeStatus == "")
        this.showUpdateMember = false;
      else
        this.showUpdateMember = true;

      if (this.existMember.memberType == undefined || this.existMember.memberType == null || this.existMember.memberType == "")
        this.existMember.memberType = ""
     

      this.dateModel.transferredDate = this.StringToDate(this.existMember.transferredDate);

    }, error => {
      this.toastr.showError("Failed to retrive data", "");
    })

  }

  /***************Update member details *
   */
  updateMember() {
    if (this.existMember.salutation == "" || this.existMember.salutation == undefined || this.existMember.salutation == null) {
      this.toastr.showError("Please select salutation.", "")
      return;
    }
    if (this.existMember.firstName == "" || this.existMember.firstName == undefined || this.existMember.firstName == null) {
      this.toastr.showError("Please enter firstname.", "")
      return;
    }
    if (this.existMember.lastName == "" || this.existMember.lastName == undefined || this.existMember.lastName == null) {
      this.toastr.showError("Please enter lastname.", "")
      return;
    }
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    if ((this.existMember.email == "" || this.existMember.email == undefined || this.existMember.email == null) &&  (this.existMember.id == "" || this.existMember.id == undefined || this.existMember.id == null ) ) {
      this.toastr.showError("Please enter valid email.", "");
      // var mailformat = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
      
      // if(existMember.email !=== Pattern)
      return;
    }
    if (this.existMember.memberId == "" || this.existMember.memberId == undefined || this.existMember.memberId == null) {
      this.toastr.showError("Please enter Member Id", "")
      return;
    }
    if (this.existMember.mobileNumber == "" || this.existMember.mobileNumber == undefined || this.existMember.mobileNumber == null || this.existMember.mobileNumber.value.length < 10) {
      this.toastr.showError("Please enter mobile number.", "")
      return;
    }
    if (this.existMember.memberType == "" || this.existMember.memberType == undefined || this.existMember.memberType == null) {
      this.toastr.showError("Please select member type.", "")
      return;
    }
    if (this.existMember.whatsappNumber == "" || this.existMember.whatsappNumber == undefined || this.existMember.whatsappNumber == null) {
      this.toastr.showError("Please enter whatsapp number.", "")
      return;
    }
    if (this.existMember.address == "" || this.existMember.address == undefined || this.existMember.address == null) {
      this.toastr.showError("Please enter address.", "")
      return;
    }
    if ((this.existMember.plotNo == "" || this.existMember.plotNo == undefined || this.existMember.plotNo == null) && this.existMember.memberType != "Waitlisted_member") {
			this.toastr.showError("Please enter enter plot number.", "")
			return;
		}
		if ((this.existMember.roadNo == "" || this.existMember.roadNo == undefined || this.existMember.roadNo == null) && this.existMember.memberType != "Waitlisted_member") {
			this.toastr.showError("Please enter road number.", "")
			return;
		}

    if(this.existMember.memberType == "Non_member_resident" && !this.existMember.memberId.endsWith("-T")) {
        this.toastr.showError("Member Id should end with '-T' for Non Member residents", "")
        return;
    }

    if(this.existMember.memberType != "Non_member_resident" && isNaN(this.existMember.memberId)) {
      this.toastr.showError("Member Id should be a numberic value for members", "")
      return;
  }

    this.existMember.updateApprovedBy = localStorage.getItem("authenticateUser");
    this.existMember.userType = "Member";
    if(!this.isNew){
    this.memberService.updateMember(this.existMember).subscribe((response: any) => {
      if (this.isAdmin) {
        if (response['status'] == "200" && response['data'] != null) {

          this.toastr.showSuccess("Member details updated successfully.", "");
          this.router.navigate(['/members'])
        } else {
          this.toastr.showError(response['message'], "");
          // this.router.navigate(['/members'])
        }
      } else {
        if (response['status'] == "200" && response['data'] != null) {

          this.toastr.showSuccess("Profile details updated successfully.", "");
          this.router.navigate(['/Dashboard'])
        } else {
          this.toastr.showSuccess("Please try again.", "");
          this.router.navigate(['/Dashboard'])
        }
      }
    }, error => {
      this.toastr.showError("Failed to update data", "");
      if (this.isAdmin)
        this.router.navigate(['/members'])
      else
        this.router.navigate(['/Dashboard'])
    })
  }else{
    this.existMember.activeStatus = "Approved";
    this.existMember.approvedBy = localStorage.getItem("authenticateUser");
    this.memberService.approveRegisteredMember(null,this.existMember).subscribe((response: any) => {
      if (response['status'] == "200" && response['data'] != null) {
        let emailModel = {};
        emailModel['templateCode'] = "Jhchbs_IE_001";
        emailModel['id'] = response["data"]["id"];
        emailModel['link'] = this.config.CURRENT_SERVER_URL + "create-password/";
        
        this.notificationService.sendResetPasswordEmail(emailModel).subscribe((response: any) => {
          this.router.navigate(['/members'])
          
        }, error => {
          this.router.navigate(['/members'])
        })

      }else{
        this.toastr.showError(response['message'], "");
        // this.router.navigate(['/newRegistrations'])
      }
    }, error => {
      this.toastr.showError("Failed to update the data", "");
      this.router.navigate(['/newRegistrations'])
    })
  }

  }

  onFileSelected(event) {

    const file: File = event.target.files[0];

    const fileExtension = file.name.split('.').pop();

    var allowedExtensions = ["pdf","PDF","txt","ico","TXT","jpg", "jpeg", "png", "JPG", "JPEG", "JFIF", "BMP","doc","docx","Doc"];

    if (this.isInArray(allowedExtensions, fileExtension)) {
    this.upload_service.uploadFileToS3(file, "Services").subscribe(
      response => {

        this.existMember.profileImage = this.config.cf_url+'/' + response.Key;
        // this.images[idx]["img_url"] = this.imgUrl;
        this.toastr.showSuccess("Profile pic uploaded successfully.", "");

      },
      error => {
        console.log('An error occured while uploading the image.');

      })
    }else{
      this.toastr.showError("Please upload only valid extension document.", "");
      this.existMember.profileImage= "";
    
    }


  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  /*****To convert Date to SQL string and SQL string to Date to match with Ngb Datepicker  */
  dateToString(date) {
    var formattedDate = "";

    if (date.month < 10)
      date.month = "0" + date.month;
    if (date.day < 10)
      date.day = "0" + date.day;
    formattedDate = date.year + "-" + date.month + "-" + date.day;
    var IsDate = new Date(formattedDate).toISOString();
    return IsDate;
  }
  StringToDate(value: string) {
    if (!value)
      return null
    let parts = value.split('-');
    return { year: +parts[0], month: +parts[1], day: +parts[2].substring(0,parts[2].indexOf('T')) } as NgbDateStruct;
  }
}
