import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  private API = "/api/requests/";
  private serviceAPI = "/api/services/";
  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  allRequests = [];
  getRequests(memberId,selection,status){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+memberId+"/"+selection+"/"+status);
  }
  getServices(selection,status,serviceType){
    return this.http.get(this.globalUrl.SERVER_URL+this.serviceAPI+selection+"/"+status+"/"+serviceType);
  }
  serviceById(id){
    return this.http.get(this.globalUrl.SERVER_URL+this.serviceAPI+id);
  }
  saveRequest(requestModel){
    return this.http.post(this.globalUrl.SERVER_URL+this.API,requestModel);
  }

}
