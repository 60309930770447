import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../sevice/alert.service';
import { MembersService } from '../../sevice/members.service';
import { UsersService } from '../../sevice/users.service';
import { Globalconfig } from '../../utility/global.config';

export class loginDetails {
  loginId;
  password;
  activeStatus;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  loginDetails: loginDetails;
  private sub: any;
  member: any;
  current_password: string;
  new_password: string;
  confirm_password: string;
  memberId: string;
  showLoader:boolean = false;
  constructor(private config: Globalconfig, private memberService: MembersService, private toastr: AlertService, public route: ActivatedRoute,private userService: UsersService, public router: Router) { }

  ngOnInit(): void {
    this.loginDetails = new loginDetails();
    this.sub = this.route.params.subscribe(params => {
      this.memberId = params['memberId'];
			this.getMemberDetails("existMember", params['memberId']);
		})
  }


  getMemberDetails(selection, memberId) {
    this.memberService.getMemberDetailsByMemberId(selection, memberId).subscribe((response: any) => {
      this.member = response["data"];
    }, error => {
      this.toastr.showError("Failed to retrive data", "");
    })

  }

   //**Method for validating user and calling backend API */
   validateUser() {
    if(this.current_password == null) {
      this.toastr.showError("Enter current password","");
      return;
    }
    if(this.new_password == null ) {
      this.toastr.showError("Enter new password","");
      return;
    }
    if(this.confirm_password == null ) {
      this.toastr.showError("Confirm new password","");
      return;
    }
    if(this.new_password != this.confirm_password){
      this.toastr.showError("New password and confirm password is not matched.","");
      return;
    }

    if(this.current_password == this.confirm_password){
      this.toastr.showError("Current password and new password should not be same.","");
      return;
    }
    this.loginDetails.loginId = this.memberId;
    this.loginDetails.password = this.current_password;
    this.loginDetails.activeStatus = true;
    this.userService.login(this.loginDetails).subscribe((response: any) => {
      this.showLoader=false;
      if (response.status == "200") {
      this.updatePassword();
      } else {
        this.toastr.showError('Please provide valid current passord.' ,"");
      }
    }, error => {
      this.toastr.showError('Please provide valid current passord.' ,"");
      this.showLoader=false;
    })

  }
  updatePassword(){
    var data = {
      id: this.member["id"],
      memberId: this.memberId,
      password: this.new_password,
      confirmPassword: this.confirm_password
    }
    this.userService.createPassword(data).subscribe((response: any) => {
      this.showLoader=false;
      if (response.status == "200") {
        this.toastr.showSuccess('Password updated successfully..' ,"")
        this.router.navigate(['/Dashboard']);
      }
    }, error => {
      this.toastr.showError('An error has occurred while retriving  data.' ,"");
      this.router.navigate(['/Dashboard']);
      this.showLoader=false;
    })
  }
}
