import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../sevice/alert.service';
import { MembersService } from '../sevice/members.service';
import { Globalconfig } from '../utility/global.config';


export class registerDetails{
   id;
   salutation;
   firstName;
	 lastName;
	 mobileNumber;
	 whatsappNumber;
	 email;
	 msNo;
	 plotNo;
	 roadNo;
	 loginCreated;
   memberStatus;
   memberId;

}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  selected:boolean = false;
  registerDetails: registerDetails;
  constructor(private memberService :MembersService,private globals: Globalconfig,private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }

  ngOnInit(): void {
    this.registerDetails = new registerDetails();
    this.registerDetails.salutation='';
  }
  goto(){
    window.open(this.globals.CURRENT_SERVER_URL+"termsandconditions",'_blank');
  }
  register(){
      let duration = 3000;
      if (this.registerDetails.salutation == null || this.registerDetails.salutation == undefined || this.registerDetails.salutation == "") {
        this.toastr.showError('Please select salutation.', '');
        return;
      }
      if (this.registerDetails.firstName == null || this.registerDetails.firstName == undefined || this.registerDetails.firstName == "") {
        this.toastr.showError('Please enter first name.', '');
        return;
      }
      if (this.registerDetails.lastName == null || this.registerDetails.lastName == undefined || this.registerDetails.lastName == "") {
        this.toastr.showError('Please enter last nName.', '');
        return;
      }
      if (this.registerDetails.mobileNumber == null || this.registerDetails.mobileNumber == undefined || this.registerDetails.mobileNumber == "") {
        this.toastr.showError('Please enter mobile number.', '');
        return;
      }
      if (this.registerDetails.whatsappNumber == null || this.registerDetails.whatsappNumber == undefined || this.registerDetails.whatsappNumber == "") {
        this.toastr.showError('Please enter whatsapp number.', '');
        return;
      }
      if (this.registerDetails.email == null || this.registerDetails.email == undefined || this.registerDetails.email == "") {
        this.toastr.showError('Please enter email.', ' ');
        return;
      }
      if (this.registerDetails.plotNo == null || this.registerDetails.plotNo == undefined || this.registerDetails.plotNo == "") {
        this.toastr.showError('Please enter plat number.', ' ');
        return;
      }
      if (this.registerDetails.roadNo == null || this.registerDetails.roadNo == undefined || this.registerDetails.roadNo == "") {
        this.toastr.showError('Please enter road number.', ' ');
        return;
      }
      if (!this.selected) {
        this.toastr.showError('Please agree to terms and conditions.', '');
        return;
      }
      if (this.registerDetails.memberId == "" || this.registerDetails.memberId == undefined ) {
        this.registerDetails.memberId = null;
      }
      this.registerDetails.memberStatus="New";
      this.memberService.register(this.registerDetails).subscribe((response:any) =>{
        if (response.status == "200") {
          this.toastr.showSuccess(response.message, ' ');
          this.router.navigate(['/memberLogin']);
        } else {
          this.toastr.showError(response.message, ' ');
          this.router.navigate(['/memberLogin']);
        }
      },error =>{
        this.toastr.showError('An error has occurred while retriving  data.' ,'');
        this.router.navigate(['/memberLogin']);
      })
  }

  settings = {
    columns: {
      id: {
        title: 'ID'
      },
      name: {
        title: 'Full Name'
      },
      username: {
        title: 'User Name'
      },
      email: {
        title: 'Email'
      }
    }
  };
  


}
