<div class="container-fluid">
    <!-- <div class="row" *ngIf="isAdmin"> -->
    <div class="row" *ngIf="isAdmin">

      <div class="col-md-2 my-3">
        <side-nav-bar></side-nav-bar>
      </div>

      <div class="col-md-10 my-3">


        <div class="container-fluid px-0">
            <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
                <div class="text-center jh-heading" >Poll Management</div>
                <div class="col-md-12">
                    <div class="row justify-content-md-center">
                        <div class="col-md-8 text-center">
                            <h6>Please Enter Your Question</h6>
                            <div class="row mt-3" >
                                <div class="col-md-3">
                                    <label for="" class=" col-form-label">Question</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="email" class="form-control col-md-9"  placeholder="Please Enter Question">
                                </div>
                            </div>
                            <div class="row mt-3" >
                                <!-- <div class="form-label col-md-3">Question</div> -->
                                <div class="col-md-3">
                                    <label for="" class=" col-form-label">Option 1</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="email" class="form-control col-md-9"  placeholder="Option 1">
                                </div>
                            </div>
                            <div class="row mt-3" >
                                <!-- <div class="form-label col-md-3">Question</div> -->
                                <div class="col-md-3">
                                    <label for="" class=" col-form-label">Option 2</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="email" class="form-control col-md-9"  placeholder="Option 2">
                                </div>
                            </div>
                            <div class="row mt-3" >
                                <!-- <div class="form-label col-md-3">Question</div> -->
                                <div class="col-md-3">
                                    <label for="" class=" col-form-label">Option 3</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="email" class="form-control col-md-9"  placeholder="Option 3">
                                </div>
                            </div>
                            <div class="row mt-3" >
                                <!-- <div class="form-label col-md-3">Question</div> -->
                                <div class="col-md-3">
                                    <label for="" class=" col-form-label">Option 4</label>
                                </div>
                                <div class="col-md-9">
                                    <input type="email" class="form-control col-md-9"  placeholder="Option 4">
                                </div>
                            </div>
                            <div class="col-auto">
                                <button type="submit" class="btn jh-btn-primary submit-btn my-4 shadow col-auto px-3">Submit
                                  <!-- <div class="spinner-border text-success" role="status" *ngIf ="showLoader">
                                      <span class="sr-only">Loading...</span>
                                    </div> -->
                                </button>
              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- <div class="row" *ngIf="isAdmin"> -->
    <div class="row" *ngIf="isMember">
        <div class="col-md-2 my-3">
            <div id="mySidenav" class="sidenav">
                <a href="/Dashboard" routerLink="/Dashboard">Dashboard</a>
                <a href="">Profile</a>
                <a href="">Change password</a>
                <a href="/requests" routerLink="/requests">Requests</a>
                <a href="/minutes-of-meeting" routerLink="/minutes-of-meeting">Minutes of meeting</a>
                <!-- <a href="" >Poll Management</a> -->
                <!-- <a href="/memberLogin">Logout</a> -->
            </div>
        </div>
        <div class="col-md-10 my-3">
            <div class="container-fluid px-0">
                <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
                    <div class="text-center jh-heading" >Poll Management</div>
                    <div class="col-md-12">
                        <div class="row justify-content-md-center">
                            <div class="col-md-8 text-center">
                                <h6>Please Answer Your Choice</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center mt-5">
                        <div class="col-md-8">
                            <h5>1. Who want to be your next president</h5>
                            <ul style="list-style-type: none;" >
                                <li><label><input type="radio" name="question0" value="A"><span>Person 1</span></label></li>
                                <li><label><input type="radio" name="question0" value="B"><span>Person 2</span></label></li>
                                <li><label><input type="radio" name="question0" value="C"><span>Person 3</span></label></li>
                                <li><label><input type="radio" name="question0" value="D"><span>Person 4</span></label></li>
                              </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
