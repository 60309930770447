
<div id="mySidenav" class="sidenav">
    <a href="/dashboard" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
    <a href="/newRegistrations" routerLink="/newRegistrations" >Registrations</a>
    <a href="/members" routerLink="/members">Members list</a>
    <a href="/list-notifications" routerLink="/list-notifications">Notifications</a>
    <a href="/requests" routerLink="/requests">Requests</a>
    <a href="/member-transfer" routerLink="/member-transfer">Member Transfer</a>
    <!-- <a href="/poll-management" routerLink="/poll-management">Poll Management</a> -->

    <!-- <a href="/staffLogin">Logout</a> -->
</div>
