<div class="container-fluid ">
  <div class="row">
    <div class="col-md-2 my-3">
      <side-nav-bar></side-nav-bar>
    </div>
    <div class="col-md-10 my-3">
      <div class="container-fluid px-0">
        <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
          <div class="text-center jh-heading">Create Notification</div>

          <div class="container-fluid px-0">
            <div class="row  my-3 ">
              <div class="col-md-2 col-form-label">
                Choose Notification
              </div>
              <div class="col-md-3 jh-login-input">
                <div class="col-md-8 jh-login-input">
                  <select class='select-option col-md-12 p-2' #mySelect (change)='onOptionsSelected(mySelect.value)'
                    style="border-radius: 50px; width: 100%;" [(ngModel)]="notificationObj.notificationType"
                    [disabled]="typeDisabled">
                    <option value=''>Select</option>
                    <option value='Notices'>Notices</option>
                    <option value='Latest News'>Latest News</option>
                    <option value='Minutes of Meeting'>Minutes of Meeting</option>
                    <option value='Other'
                      [disabled]="notificationObj.notificationId != null && notificationObj.notificationId != undefined && notificationObj.notificationId != ''">
                      Other</option>
                  </select>
                </div>
              </div>
              <div class="col-md-7 ">
                <div class="row d-flex justify-content-end " *ngIf="!isOther">
                  <div class="col-md-2 btn btn-warning mt-1 mr-1" style="border-radius: 50px; margin-right: 10px;" (click)="saveNotification('save')"
                    *ngIf="!notificationObj.isPublished">
                    Save
                  </div>
                  <div *ngIf="!notificationObj.isPublished " class="col-md-2 btn btn-success mt-1 mr-1" style="border-radius: 50px; margin-right: 10px;" (click)="open(approveModal,'publish')">
                    Publish
                  </div>
                  <div *ngIf="notificationObj.isPublished" class="col-md-2 btn btn-danger mt-1 mr-1" style="border-radius: 50px; margin-right: 10px;" (click)="open(approveModal,'unpublish')">
                    Unpublish
                  </div>
                </div>
                <div class="row" *ngIf="isOther">
                  <div class="col-md-1 btn btn-warning" style="position: absolute;
                  right: 144px;border-radius: 50px;" (click)="clickAction('next')" *ngIf="!showGrid">
                    Next
                  </div>
                  <div *ngIf="showGrid" class="col-md-1 btn btn-warning" style="position: absolute;
                  right: 290px;border-radius: 50px;" (click)="clickAction('prev')">
                    Prev
                  </div>
                  <div *ngIf="showGrid" class="col-md-1 btn btn-success" style="position: absolute;
                  right: 130px;border-radius: 50px;" (click)="open(approveModal,'publish')">
                    Publish
                  </div>



                </div>
              </div>

              <div class="form-group col-md-5 mt-4">
                <input type="text" class="form-control" id="" [(ngModel)]="notificationObj.notificationName"
                  placeholder="Enter Notification Name" style="border-radius: 50px;" maxlength="100">
              </div>

              <div class="form-group col-md-4 mt-3">
                <div class="row">
                  <div class="col-md-4 my-3">
                    <input type="checkbox" class="form-check-input jh-checkbox" id="jh-checkbox-whatsapp"
                      [(ngModel)]="notificationObj.isWhatsappAlert" disabled>
                    <label class="form-check-label ms-2" for="jh-checkbox-whatsapp">Whatsapp</label>
                  </div>

                  <div class="col-md-4 my-3">
                    <input type="checkbox" class="form-check-input jh-checkbox" id="jh-checkbox-email"
                      [(ngModel)]="notificationObj.isEmailAlert">
                    <label class="form-check-label ms-2" for="jh-checkbox-email">Email</label>
                  </div>
                  <div class="col-md-4 my-3">
                    <input type="checkbox" class="form-check-input jh-checkbox" id="jh-checkbox-sms"
                      [(ngModel)]="notificationObj.isSMSAlert" [disabled]="isSmsDisabled">
                    <label class="form-check-label ms-2" for="jh-checkbox-sms">SMS</label>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div *ngIf="!isOther">
            <div class="form-group col-md-8 my-3" *ngFor="let document of documentUrls let i = index;">
              <!-- <label for="formFile" class="form-label">Default file input example</label> -->
              <div class="row">
                <input class="form-control col-md-6" type="file" id="formFile" accept="image/*,.doc,.docx,.txt,.pdf"
                  (change)="onFileSelected($event, i)" Placeholder="please upload the file" style="border-radius: 50px; margin-left: 10px;"
                  title="document{{i}}" name="document{{i}}" *ngIf="document.uploaded=='No'">
                <div class="col-md-6" style="    text-overflow: ellipsis;
               overflow: hidden;
               width: 50%;
               display: -webkit-box;
               -webkit-line-clamp: 1;
               -webkit-box-orient: vertical;
               overflow: hidden; margin-left: 10px;" title="{{document.name}}"> {{document.name}} </div>
                <span class="col-md-3" *ngIf="document.url != null && document.url != undefined && document.url != '' ">
                  <a [href]="document.url" target="_blank"><i class="fa fa-eye"></i></a>

                </span>


                <!-- <div *ngIf="isMember && (requestModel.requestStatus == 'New' ||requestModel.requestStatus == 'MC Review' ||requestModel.requestStatus == 'Documents pending') " class="col-md-auto"> -->
                <!-- <div class="col-md-3">
                  <button *ngIf="documentUrls.length == document.count && document.count!=5 && document.url != ''"
                    (click)="addDocuments()" class="btn btn-success mx-2 mt-1">Add</button>
                  <button *ngIf="documentUrls.length == 1&& document.url != ''&& showRemove" (click)="clearDocument()"
                    class="btn btn-danger mx-2 mt-1">Clear</button>
                  <button *ngIf="document.count!=1 && documentUrls.length == document.count && showRemove"
                    (click)="removeDocument(i)" class="btn btn-danger mx-2 mt-1">Remove</button>
                </div> -->
              </div>
            </div>
            <editor [(ngModel)]="notificationObj.content" apiKey={{apiKey}} [init]="tinymceInit" class="mt-4">
            </editor>
          </div>
          <div *ngIf="isOther && !showGrid">
            <!-- <input type="text" class="form-control" id=""  style="width:100%" [(ngModel)]="notificationObj.content"
            placeholder="" autocomplete="off"> -->

            <textarea name="text message" id="" cols="80" rows="6" [(ngModel)]="notificationObj.content"
              placeholder="Enter message here" autocomplete="off"></textarea>
            <div class="col-md-9 p-2">
              <div class="my-2" *ngFor="let document of documentUrls let i = index;">
                <div class="d-flex">
                  <input class="col-md-9 " type="file" accept="image/*,.doc,.docx,.txt,.pdf" [(ngModel)]="document.url"
                    style="    text-overflow: ellipsis;
               overflow: hidden;
               width: 50%;
               display: -webkit-box;
               max-width: 500px;
               -webkit-line-clamp: 1;
               -webkit-box-orient: vertical;
               overflow: hidden;" title="document{{i}}" name="document{{i}}" *ngIf="document.uploaded=='No'"
                    (change)="onFileSelected($event, i)">

                  <div style="    text-overflow: ellipsis;
               overflow: hidden;
               width: 50%;
               display: -webkit-box;
               max-width: 500px;
               -webkit-line-clamp: 1;
               -webkit-box-orient: vertical;
               overflow: hidden;" title="{{document.name}}"> {{document.name}} </div>
                  <span class="px-3" *ngIf="document.url != null && document.url != undefined && document.url != '' ">
                    <a [href]="document.url" target="_blank"><i class="fa fa-eye"></i></a>

                  </span>


                  <!-- <div *ngIf="isMember && (requestModel.requestStatus == 'New' ||requestModel.requestStatus == 'MC Review' ||requestModel.requestStatus == 'Documents pending') " class="col-md-auto"> -->
                  <div class="col-md-auto">
                    <button *ngIf="documentUrls.length == document.count && document.count!=5 && document.url != ''"
                      (click)="addDocuments()" class="btn btn-success mx-2">Add</button>
                    <button *ngIf="documentUrls.length == 1&& document.url != ''&& showRemove" (click)="clearDocument()"
                      class="btn btn-danger mx-2">Clear</button>
                    <button *ngIf="document.count!=1 && documentUrls.length == document.count && showRemove"
                      (click)="removeDocument(i)" class="btn btn-danger mx-2">Remove</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="showGrid">
            <ng2-smart-table [settings]="settings" [source]="membersData" (userRowSelect)="onUserRowSelect($event)"
              #table></ng2-smart-table>
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #approveModal let-modal>
  <div class="modal-header jh-member-approve">
    <h4 class="modal-title" id="modal-basic-title">Are you sure</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    Do you want to {{publishStatus}}?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="action();modal.close('Save click')">Ok</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>
<div class="d-flex justify-content-center" *ngIf="showLoader"
  style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index: 1000;background: #00000024;align-items: center;">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>