import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AlertService } from '../sevice/alert.service';
import { AuthService } from '../sevice/auth.service';
import { DbServiceService } from '../sevice/db-service.service';
import { UsersService } from '../sevice/users.service';

export class loginDetails {
  loginId;
  password;
  activeStatus;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /**************************************************Attributes starts here********************************************** */  
  loginDetails: loginDetails;
  showLoader:boolean = false;
  headerTitle: string = "Staff login";
  routerTitle:string = "staffLogin";
  usernameLabel:string = "Staff id";
  showForgetAndMemberRegistration :boolean = false;

 /**************************************************Attributes ends here********************************************** */
  constructor(private userService: UsersService, private toastr: AlertService, public route: ActivatedRoute,private authService : AuthService, public router: Router, private db_service: DbServiceService) { }

  /**************************Init method will trigger and initialize the attributes and required methods */
  ngOnInit(): void {
    this.loginDetails = new loginDetails();
    if(window.location.href.indexOf("staffLogin") > -1){
      this.headerTitle = "Staff Login";
      this.routerTitle="staffLogin";
      this.usernameLabel = "Staff id";
      this.showForgetAndMemberRegistration = false;
    }if(window.location.href.indexOf("memberLogin") > -1){
      this.headerTitle = "Member Login"; 
      this.routerTitle="memberLogin";
      this.usernameLabel = "Member id";
      this.showForgetAndMemberRegistration = true;
    }

  }
 //**Method for login and verify user */
 loginUser() {
  this.showLoader=true;
  if (this.loginDetails.loginId == null || this.loginDetails.loginId == undefined || this.loginDetails.loginId == "") {
    this.toastr.showError('Please enter valid id.', "");
    this.showLoader=false;
    return;
  }
  if (this.loginDetails.password == null || this.loginDetails.password == undefined || this.loginDetails.password == "") {
    this.toastr.showError( 'Please enter password.', "");
    this.showLoader=false;
    return;
  }
  this.loginDetails.activeStatus = true;
  this.authService.login(this.loginDetails,this.routerTitle);
  this.loginDetails= new loginDetails();
  this.showLoader=false;
 
}
}
