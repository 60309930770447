import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AlertService } from '../../sevice/alert.service';
import { MembersService } from '../../sevice/members.service';

@Component({
  selector: 'app-new-registration',
  templateUrl: './new-registration.component.html',
  styleUrls: ['./new-registration.component.scss']
})
export class NewRegistrationComponent implements OnInit {
  settings = {
    columns: {
      memberId: {
        title: 'Member Id',
        filter: true,
      },
      firstName: {
        title: 'First Name',
        filter: true,
      },
      lastName: {
        title: 'Last Name',
        filter: true,
      },
      email: {
        title: 'Email',
        filter: true,
      },
      mobileNumber: {
        title: 'Mobile no',
        filter: true,
      },
      memberStatus: {
        title: 'Member Status',
        filter: false,
        valuePrepareFunction: (status) => {
          if (status == " " || status == null || status == undefined)
            return "New"
          else
          return status
        }

      },
      plotNo: {
        title: 'Plot no',
        filter: true,
      },
      roadNo: {
        title: 'Road no',
        filter: true,
      },
    },
    actions: {
      columnTitle: 'More',
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      filter: false,
      custom: [{ name: 'verify',type: 'html', title: '<i class="fa fa-user grid-icons" title ="Verfiy"></i>' }]
    }
  };

  membersData = [];
  constructor(private memberService: MembersService, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
  ngOnInit(): void {
    this.getMemberDetails();
  }

  /***getMemberDetails */
  getMemberDetails() {
    this.memberService.getMemberDetails("newRegistered").subscribe((response: any) => {
      if (response['data'].length != 0)
        this.membersData = response['data'];
      else
        this.membersData = []
       this.membersData.reverse(); 
    })
  }
  /***based on grid More actions selection will navigate the user */
  onCustomAction(event) {
    switch (event.action) {
      case 'verify':
            if(event.data.memberStatus == "Rejected" ||event.data.memberStatus == "Transferred"  ){
              this.toastr.showError("Unable to preview the Rejected/Transferred member.","")
            return;
           }else {
            this.router.navigate(['/member-verify',event.data.id]);
           } 
           break;
 

    }
  }
 

}
