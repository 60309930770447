<div class="container-fluid">
    <div class="row align-items-start text-center">
      <div class="col-md-2 order-md-1 my-auto ">
        <div class="js-hr-ads col my-5">
            <app-horizontal-ads></app-horizontal-ads>
          </div>
      </div>
      <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded jh-login-form">
        <!-- <div class="col-md-12">
          <div class="row">
          <div   class="col-md-auto">
              <button class="btn border jh-btn-primary bg-secondary text-white" routerLink="/memberLogin" >Back</button>
            </div>
            <div class="text-center jh-heading col">
              REGISTRATION
            </div>

          </div>
      </div> -->

         <div class="text-center jh-heading" >Create password</div>     
         <div class="col-md-5 mx-auto">
            
            <div class="my-4 jh-login-input">
                <div class="form-label text-start ms-4">Id</div>
                <input type="email" class="form-control" [(ngModel)] ="createPasswordModel.memberId" [ngModelOptions]="{standalone: true}"  id="exampleFormControlInput1" placeholder="id" autocomplete="off" disabled>
            </div>
            
            <div class="my-4 jh-login-input">
                <div class="form-label text-start ms-4">Password</div>
                <input type="password" class="form-control" [(ngModel)] ="createPasswordModel.password" [ngModelOptions]="{standalone: true}"  id="exampleFormControlInput1" placeholder="Password" autocomplete="off">
            </div>
            <div class="my-4 jh-login-input">
                <div class="form-label text-start ms-4">Confirm Password</div>
                <input type="password" class="form-control" [(ngModel)] ="createPasswordModel.confirmPassword" [ngModelOptions]="{standalone: true}"  id="exampleFormControlInput1" placeholder="Confirm Password" autocomplete="off">
            </div>
              <div class="col-auto">
                  <button type="submit" class="btn jh-btn-primary submit-btn my-4 shadow col-auto px-3" (click) ="verifyUser()">Submit</button>
              </div>          
         </div> 
         <div class="col-md-12 text-center jh-mem-log-reg">
             <div class="row">
                <div class="col" routerLink="/memberLogin">Back to login</div>
            </div>
         </div>    
      </div>
      <div class="col-md-2 order-md-3 my-auto">
        <div class="js-hr-ads col my-5">
            <app-horizontal-add2></app-horizontal-add2>
          </div>
      </div>
    </div>
  
  </div>
