import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class Globalconfig {


    // readonly CURRENT_SERVER_URL = "http://localhost:4200/";
    // readonly SERVER_URL = "http://localhost:8080";


    // readonly SERVER_URL = "https://dev.v-bill.in/jhchbsservice";
    // readonly CURRENT_SERVER_URL = "https://dev.v-bill.in/";

    // readonly SERVER_URL = "https://jhchbs.com/jhchbsservice";
    // readonly CURRENT_SERVER_URL = "https://jhchbs.com/";
  //prod
    readonly SERVER_URL = "https://jhchsl.com/jhchbsservice";
    readonly CURRENT_SERVER_URL = "https://jhchsl.com/";

    // prod
    // readonly SERVER_URL = "https://dev.jhchsl.com/jhchbsservice";
    // readonly CURRENT_SERVER_URL = "https://dev.jhchsl.com/";

     // aws constantsdEV
     readonly accessKeyId: string = 'AKIAZXR4BJ4IEZI4AJQM';
     readonly secretAccessKey: string = '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';
    readonly bucketname: string = 'vod-dest-streaming';
   readonly cf_url: string = 'https://d13m95frd7cvoq.cloudfront.net'

     // aws constantsPROD
    //  readonly accessKeyId: string = 'AKIARK5WXFWMQFAKIJ3X';
    //  readonly secretAccessKey: string = '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';
    //  readonly bucketname: string = 'jhchbs-data';
     readonly region: string = 'ap-south-1';
    //  readonly cf_url: string = 'https://doem2lrr38idu.cloudfront.net';
     readonly upload_url='https://jhchbs-data.s3.ap-south-1.amazonaws.com';
     
    //  readonly tinyMceApiKey="b4rh9rogiehdgd2uhrhsmar7tiermw4m1soe63njsf69fmk1"; 
     readonly tinyMceApiKey="p54jommuy2j098v8pue0eg8dcra8e7o1u90iwsfgwtp417j4" 
}
