<div class="container-fluid">
    <div class="row align-items-start text-center">
      <div class="h-100 col-md-11 mx-auto order-md-2 shadow p-5 my-4 rounded jh-forgot-password-form ">
        <div class="row">
          <div class="col-md-auto" style="position: absolute;">
                    <button type="submit" class="btn border jh-btn-primary bg-secondary text-white shadow col-auto px-3" routerLink="/Dashboard">Back
        </button>
          </div>

         <div class="text-center jh-heading col" >Change Password</div>
        </div>
         <div class="col-md-5 mx-auto">
            <div class="my-4 jh-forgot-password-form">
                <div class="col-md-8 mx-auto jh-login-input">
                <div class="form-label text-start ms-4">Current password</div>
                <input type="password" class="form-control" [(ngModel)] ="current_password" id="exampleFormControlInput1" placeholder="Current password" autocomplete="off">
                </div>
              </div>

            <div class="my-4 jh-forgot-password-form-input jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
                <div class="form-label text-start ms-4">New Password</div>
                <input type="password" class="form-control" [(ngModel)] ="new_password"  id="exampleFormControlInput1" placeholder="New password" autocomplete="off">
              </div>
              </div>

            <div class="my-4 jh-forgot-password-form-input jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
                <div class="form-label text-start ms-4">Confirm New Passord</div>
                <input type="password" class="form-control" [(ngModel)] ="confirm_password" id="exampleFormControlInput1" placeholder="Confirm password" autocomplete="off">
              </div>

              </div>
              <div class="col-auto">
                  <button type="submit" class="btn jh-btn-primary submit-btn my-4 shadow col-auto px-3" (click) ="validateUser()">Submit
                    <div class="spinner-border text-success" role="status" *ngIf ="showLoader">
                        <span class="sr-only">Loading...</span>
                      </div>
                  </button>

              </div>
         </div>

      </div>
    </div>

  </div>
