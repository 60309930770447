<section>
  <div class="container-fluid">
    <div class="row" *ngIf="isMember">
      <div class="col-md-2 my-3">
        <div id="mySidenav" class="sidenav">
            <a href="/Dashboard" routerLink="/Dashboard">Dashboard</a>
           <a href="/member-profile/{{memberId}};" routerLink="/member-profile/{{memberId}}">Profile</a>
            <a href="/change-password/{{memberId}}" routerLink="/change-password/{{memberId}}">Change password</a>
           <a href="/requests" routerLink="/requests" class="jh-nav-req-active">Requests</a> 
           <a href="/minutes-of-meeting" routerLink="/minutes-of-meeting">Minutes of meeting</a>
           <!-- <a href="/poll-management" routerLink="/poll-management">Poll Management</a> -->
       
          <!-- <a href="/memberLogin">Logout</a> -->
        </div>
      </div>

      <div class="col-md-10 my-3">
        <div class="container-fluid px-0">
          <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
            <div class="text-center jh-heading">Requests</div>
            <div class="col-md-2">
              <div class="row">
                <button (click)="requestAction('new')" class="jh-btn-primary">Raise request</button>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <ng2-smart-table [settings]="settings" [source]="requestsData" (custom)="onCustomAction($event)">
                </ng2-smart-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isAdmin">
      <div class="col-md-2 my-3">
        <side-nav-bar></side-nav-bar>
      </div>
      <div class="col-md-10 my-3">
        <div class="container-fluid px-0">
          <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
            <div class="text-center jh-heading">Requests</div>
            <div class="col-md-12">
              <div class="row">
                <ng2-smart-table [settings]="settings" [source]="requestsData" (custom)="onCustomAction($event)">
                </ng2-smart-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="staticBackdrop" *ngIf="showRequestDetails" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="background: #24242499;
    backdrop-filter: blur(1px);">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title col text-center" id="staticBackdropLabel">Request Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="closeModel()"></button>
        </div>
        <div class="modal-body" *ngIf="showServcieDetails">
          <div class="container-fluid my-2">
            <div class="row">
              <div class="col-md-3 form-label fw-bolder">Service Name :</div>
              <div class="col-md-auto">
                <select class="form-label p-2" (change)="onDropdownChange('serviceType',$event)"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceType">
                  <option value="" [disabled]="true">--Select--</option>
                  <option *ngFor="let service of serviceTyepsData" [value]="service.serviceType">{{service.serviceType}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="container-fluid my-2" *ngIf="servicesData.length != 0 &&  showRequestPreview == false">
            <div class="row">
              <div class="col-md-3 form-label fw-bolder">{{serviceTypeName}}:</div>
              <div class="col-md-auto">
                <select class="form-label p-2" (change)="onDropdownChange('service',$event)"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="requestModel.serviceId">
                  <option value="" [disabled]="true">--Select--</option>
                  <option *ngFor="let service of servicesData" [value]="service.serviceId">{{service.serviceName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="container-fluid my-2" *ngIf="servicesData.length != 0 &&  showRequestPreview == false">
            <div class="row">
              <div class="col-md-7 my-0"
                *ngIf="serviceModel.serviceId != null &&serviceModel.serviceId != undefined && serviceModel.serviceId != ''">
                <button class="btn jh-btn-green ms-2" (click)="createRequestPrview()">Create Request</button>
              </div>
            </div>
          </div>
        </div>
        <div class="my-2" *ngIf="showRequestPreview">
          <ul ngbNav #nav="ngbNav" class="nav-tabs jh-request-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>ServiceDetails</a>
              <ng-template ngbNavContent>
                <div class="container-fluid my-2">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">Service Name :</div>
                    <div class="col-md-auto">{{serviceModel.serviceName}}</div>
                  </div>
                </div>
                <div class="container-fluid my-2">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">Service description :</div>
                    <div class="col-md-auto">{{serviceModel.serviceDescription}}</div>
                  </div>
                </div>
                <div class="container-fluid my-2">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">Service amount :</div>
                    <div class="col-md-8">
                        <span>{{requestModel.amount}}/- </span>
                        <span *ngIf="serviceModel.serviceName == 'Carpenter' || serviceModel.serviceName == 'Plumber' || serviceModel.serviceName == 'Electrician' ">Per Hour+Service charge</span>
                        <span *ngIf="serviceModel.serviceName == 'Deep Cleaning'">per Sq. Feet</span>
                        <!-- <div *ngIf="requestModel.amount != 0" class="col-md-auto"> (Note: Subject to change from time to time. GST of 18% will be applied to the above charges)</div> -->
                      </div>
                  </div>
                </div>
                <div class="container-fluid my-2"
                  *ngIf="(dbRequeststatus == 'Inprogress' || dbRequeststatus == 'Completed') && requestModel.paymentId != null">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">PaymentId :</div>
                    <div class="col-md-auto">{{requestModel.paymentId}}</div>
                  </div>
                </div>
                <div class="container-fluid my-2">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">Payment status :</div>
                    <div class="col-md-auto">{{requestModel.paymentStatus}}</div>
                  </div>
                </div>

                <div class="container-fluid my-2">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">Request status :</div>
                    <div *ngIf="isMember" class="col-md-auto">{{requestModel.requestStatus}}</div>
                    <div *ngIf="isAdmin" class="col-md-auto">
                      <select class="form-label" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="requestModel.requestStatus">
                        <option value="" [disabled]="true">--Select--</option>
                        <option value="New" [disabled]="true">New</option>
                        <option value="Inprogress" [disabled]="true">Inprogress</option>
                        <option value="Documents pending"
                          [disabled]="serviceModel.serviceType=='Maintanance Services' ||dbRequeststatus == 'Inprogress' || dbRequeststatus == 'Approved' || dbRequeststatus == 'Completed'|| dbRequeststatus == 'Rejected'">
                          Documents pending</option>
                        <option value="MC Review"
                          [disabled]=" serviceModel.serviceType=='Maintanance Services' || dbRequeststatus == 'Inprogress' || dbRequeststatus == 'Approved' || dbRequeststatus == 'Completed'|| dbRequeststatus == 'Rejected'">
                          MC Review</option>
                        <option value="Rejected"
                          [disabled]="dbRequeststatus == 'Inprogress' || dbRequeststatus == 'Approved' || dbRequeststatus == 'Completed'|| dbRequeststatus == 'Rejected'">
                          Rejected</option>
                        <option value="Approved"
                          [disabled]="dbRequeststatus == 'Inprogress' || dbRequeststatus == 'Approved' || dbRequeststatus == 'Completed'|| dbRequeststatus == 'Rejected' ">
                          Approved</option>
                        <option value="Completed"
                          [disabled]=" dbRequeststatus == 'Completed' || dbRequeststatus == 'Rejected' ">Completed
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="container-fluid my-2"
                  *ngIf="isMember || isAdmin">
                  <div class="row">
                    <div class="col-md-3 form-label fw-bolder">Comments :</div>
                    <textarea class="form-control" rows="3" placeholder="Add Comment" name="comments" required="comments"
                      [(ngModel)]="requestModel.comments"></textarea>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="serviceTypeName != 'Maintanance Services' || serviceModel.serviceType !='Maintanance Services'">
              <a ngbNavLink>Documents</a>
              <ng-template ngbNavContent>
                <div class="container-fluid">
                  <div class="row">
                    <div class="p-2" *ngIf="isAdmin == false"> Please upload the below documents</div>
                    <div class="p-2" *ngIf="isAdmin == false"> AAdhar Card </div>
                    <div class="p-2" *ngIf="isAdmin == false"> Pan Card or PassPort</div>
                  </div>
                </div>

                <div class="container-fluid">
                  <div class="row">



                    <div class="p-2"
                      *ngIf="serviceModel.doc_url != undefined && serviceModel.doc_url != '' && serviceModel.doc_url != null && isAdmin == false">
                      Please download the form. <a [href]="serviceModel.doc_url" target="_blank">Form Link</a></div>
                    <div class="col-md-3 form-label fw-bolder p-2" *ngIf="isAdmin == false  && (requestModel.id == null || requestModel.id == ''|| requestModel.id == undefined) ">Add Document :</div>
                    <div class="col-md-3 form-label fw-bolder p-2" *ngIf="isAdmin == false  && (requestModel.id != null && requestModel.id != '' && requestModel.id != undefined) ">Document :</div>
                    <div class="col-md-3 form-label fw-bolder p-2" *ngIf="isAdmin == true">Document :</div>


                    <div class="col-md-9 p-2">
                      <div class="my-2" *ngFor="let document of documentUrls let i = index;">
                        <div class="d-flex">
                          <input class="col-md-9 " type="file" accept="image/*,.doc,.docx,.txt,.pdf"
                            [(ngModel)]="document.url" style="    text-overflow: ellipsis;
                       overflow: hidden;
                       width: 50%;
                       display: -webkit-box;
                       max-width: 500px;
                       -webkit-line-clamp: 1;
                       -webkit-box-orient: vertical;
                       overflow: hidden;" title="document{{i}}" name="document{{i}}"
                            [disabled]="(isAdmin || (isMember && (requestModel.requestStatus != 'New' && requestModel.requestStatus != 'Documents pending'   && requestModel.requestStatus != 'MC Review')))?true:false"
                            *ngIf="(isAdmin == false && ((requestModel.requestStatus == 'New' || requestModel.requestStatus == 'Documents pending' || requestModel.requestStatus == 'MC Review') && (document.uploaded == 'No') ))"
                            (change)="onFileSelected($event, i)">

                          <div *ngIf="(requestModel.id != null && requestModel.id != ''&& requestModel.id != undefined) || (document.uploaded != 'No')"
                            style="    text-overflow: ellipsis;
                       overflow: hidden;
                       width: 50%;
                       display: -webkit-box;
                       max-width: 500px;
                       -webkit-line-clamp: 1;
                       -webkit-box-orient: vertical;
                       overflow: hidden;" title="{{document.name}}"> {{document.name}} </div>
                          <span class="px-3"
                            *ngIf="document.url != null && document.url != undefined && document.url != '' ">
                            <a [href]="document.url" target="_blank"><i class="fa fa-eye"></i></a>

                          </span>


                          <!-- <div *ngIf="isMember && (requestModel.requestStatus == 'New' ||requestModel.requestStatus == 'MC Review' ||requestModel.requestStatus == 'Documents pending') " class="col-md-auto"> -->
                          <div
                          *ngIf="isMember && (requestModel.requestStatus == 'New' ||requestModel.requestStatus == 'MC Review' ||requestModel.requestStatus == 'Documents pending') "
                            class="col-md-auto">
                            <button
                              *ngIf="documentUrls.length == document.count && document.count!=5 && document.url != ''"
                              (click)="addDocuments()" class="btn btn-success mx-2">Add</button>
                            <button *ngIf="documentUrls.length == 1&& document.url != ''&& showRemove" (click)="clearDocument()"
                              class="btn btn-danger mx-2">Clear</button>
                            <button *ngIf="document.count!=1 && documentUrls.length == document.count && showRemove"
                              (click)="removeDocument(i)" class="btn btn-danger mx-2">Remove</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>




            <li [ngbNavItem]="3">
              <a ngbNavLink>Request History</a>
              <ng-template ngbNavContent>


                <div class="container-fluid">
                  <div class="row">

                    <div class="col-md-12 p-2" style="max-height: 300px; overflow-y: scroll;">
                      <div class="my-2" >
                        <!-- <div class="d-flex">
                          <div class="col-md-3 form-label fw-bolder p-2">{{request.updatedBy}},{{request.requestUpdatedDate}},{{request.comment}}, {{request.oldStatus}}, {{request.newStatus}}</div>
                        </div> -->
                        <div>
                          <table class="table">
                            <thead>
                                 <tr>
                                   <th scope="col">Update By</th>
                                   <th scope="col">Updated Date</th>
                                   <th scope="col">Comment</th>
                                   <th scope="col">Old Status</th>
                                   <th scope="col">New Status</th>
                                  </tr>
                            </thead>
                            <tbody>
                                 <tr *ngFor = "let request of requestHistory let i = index;">           
                                   <td>{{request.updatedBy}}</td>
                                   <td>{{request.requestUpdatedDate | date : "dd.MM.y" }}</td>
                                   <td>{{request.comment}}</td>
                                   <td> {{request.oldStatus}}</td>
                                   <td>{{request.newStatus}}</td>      
                                 </tr>
                            </tbody>
                       </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <!-- <a ngbNavLink>Request Cancel</a> -->
            <!-- <li [ngbNavItem]="3" *ngIf="isMember && requestModel.requestId !=null  && (requestModel.requestStatus =='New' || requestModel.requestStatus=='Approved')">
              <a ngbNavLink>Request Cancel</a>
              <ng-template ngbNavContent>
              </ng-template>
            </li> -->
          </ul>

          <div [ngbNavOutlet]="nav"></div>
          <div class="container text-center">
            <button class="btn jh-btn-primary" (click)="saveRequest()"
              *ngIf="((requestModel.requestStatus == 'New' ||requestModel.requestStatus == 'Documents pending' ||requestModel.requestStatus == 'MC Review')  && isMember) || (dbRequeststatus != 'Completed' && isAdmin)">Save
              Request</button>
            <button class="btn jh-btn-primary" (click)="createOrder()"
              *ngIf="requestModel.requestStatus == 'Approved' && requestModel.amount != 0 && serviceModel.isPaidService  && isMember">Pay
              Now</button>
          </div>
        </div>


      </div>
    </div>
  </div>
</section>