import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './components/sevice/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'JHCHBS';
  showCurtain:boolean =true;
  @Output() isUserLogin: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router, private authService:AuthService ){
this.router.events.pipe(filter(e => e instanceof NavigationEnd))
.subscribe((s: NavigationEnd) => {
    if(s['url'] == "/welcome"  || s['url'] == "/welcome_jhchbs" ||s['url'] == "/launch") {
     this.authService.navigate();
    this.showCurtain=true;
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('body-landing');
     } else{
    this.showCurtain=false;
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("body-landing");
    }
});

    router.events.forEach((event) => {
      if (event['url'] == '/' || event['url'].includes('/memberLogin') || event['url'].includes('/home') ||event['url'].includes('/staffLogin')  || event['url'].includes('/reset-password')|| event['url'].includes('/register')) {
        localStorage.setItem('isLoggedIn', "false");
        localStorage.setItem('role', "");
        localStorage.setItem("authenticateUser","");
        this.authService.navigate();
        }
       if(event['url'].includes('/welcome')) 
        this.showCurtain=true;
        else
        this.showCurtain=false;
    })
  }

}
