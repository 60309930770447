import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/components/sevice/alert.service';
import { AuthService } from 'src/app/components/sevice/auth.service';
import { FileUploadService } from 'src/app/components/sevice/file-upload.service';
import { MembersService } from 'src/app/components/sevice/members.service';
import { NotificationService } from 'src/app/components/sevice/notification.service';
import { RequestsService } from 'src/app/components/sevice/requests.service';
import { Globalconfig } from 'src/app/components/utility/global.config';
import  * as _ from 'lodash';
// import { LocalDataSource, Ng2SmartTableComponent } from 'ng2-smart-table';


declare const tinymce: any;

export class notificationObj {
  notificationId;
  notificationType;
  notificationName;
  staffId;
  content;
  contentUrl;
  createdDate;
  updatedDate;
  isPublished;
  isEmailAlert;
  isWhatsappAlert;
  isSMSAlert;
  members:any;
  documentUrls: Object[];
}

@Component({
  selector: 'app-create-notifications',
  templateUrl: './create-notifications.component.html',
  styleUrls: ['./create-notifications.component.scss']
})
export class CreateNotificationsComponent implements OnInit{
  documentUrls: any[] = [{
    count : 1,
    url: "",
    name: "",
    uploaded: "No"
  }];
  notificationObj: notificationObj;
  showLoader:boolean =false;
  constructor( private upload_service: FileUploadService,private requestService: RequestsService,private memberservice:MembersService,private authService:AuthService ,public route: ActivatedRoute, public router: Router,private globals: Globalconfig, private modalService: NgbModal, private toastr: AlertService, private notificationService: NotificationService) { }
  apiKey: string = "";
  tinymceInit: any;
  closeResult: string;
  isOther:boolean=false;
  isSmsDisabled:boolean =false;
  publishStatus: string = "";
  showGrid:boolean =false;
  typeDisabled=false;
  membersData:any=[];
  showRemove:boolean =false;
  // @ViewChild('table') table: Ng2SmartTableComponent;
  // source: LocalDataSource = new LocalDataSource();
  // selectedItems: any[] = [];
  // selectedRows:any=[];
  private sub: any;
  settings = {
    selectMode: 'multi', 
    columns: {
      memberId: {
        title: 'Member Id',
        filter: true,
      },
      firstName: {
        title: 'First Name',
        filter: true,
      },
      lastName: {
        title: 'Last Name',
        filter: true,
      },
      email: {
        title: 'Email',
        filter: true,
      },
      mobileNumber: {
        title: 'Mobile no',
        filter: true,
      },
      plotNo: {
        title: 'Plot no',
        filter: true,
      },
      roadNo: {
        title: 'Road no',
        filter: true,
      },
      memberType: {
        title: 'Member Type',
        filter: true,
      }
    },
    actions: false
  };
  
  ngOnInit(): void {
    this.apiKey = this.globals.tinyMceApiKey;
    this.notificationObj = new notificationObj();
    this.getMemberDetails();
    this.notificationObj.notificationType = '';
    this.tinymceInit = {
      height: 350,
      branding: false,
      // menubar: 'true',
      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
        "insertdatetime media nonbreaking save table contextmenu directionality",
        "emoticons template paste textcolor colorpicker textpattern"
      ],
      mobile: {
        theme: 'mobile',
        menubar: false
      },
      toolbar: 'insert | undo redo | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | preview',
      image_advtab: true,
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        // Note: In modern browsers input[type="file"] is functional without 
        // even adding it to the DOM, but that might not be the case in some older
        // or quirky browsers like IE, so you might want to add it to the DOM
        // just in case, and visually hide it. And do not forget do remove it
        // once you do not need it anymore.

        input.onchange = function () {
          var file = input.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won't be
            // necessary, as we are looking to handle it internally.
            var id = 'blobid' + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = (<string>reader.result).split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    }
    this.sub = this.route.params.subscribe(params => {
      if(params['notificationId'] != null){
          this.getNotification(params['notificationId']);
      }else{
        this.notificationObj = new notificationObj();
        this.notificationObj.notificationType = '';
      }  
     })
  }

  // ngAfterViewChecked(): void {
  //   this.syncTable();
  // }
  // syncTable (): void {
  //   this.table.grid.getRows().forEach((row) => {
  //     if(this.selectedItems.find( r => r.id == row.getData)) {
  //       row.isSelected = true;
  //     }
  //   });

  //   this.cdr.detectChanges();
  // }

  addDocuments() {
    if (this.documentUrls.length > 5) {
      this.toastr.showError('Maximum limit for adding documents reached.', '');
      return;
    }


    this.documentUrls.push({
      count: this.documentUrls.length + 1,
      url: "",
      name: "",
      uploaded: "No"
    })
    this.showRemove=true;
  }
  clearDocument(){
    this.showRemove=false;
    this.documentUrls = [];
    this.isSmsDisabled=false;
    this.documentUrls.push({
      count: this.documentUrls.length + 1,
      url: "",
      name: "",
      uploaded: "No"
    })
  }

  removeDocument(i: number) {
    this.showRemove=false;
    if (this.documentUrls.length > 1)
      this.documentUrls.splice(i, 1);
    else {
      this.toastr.showError("Cannot remove required atleast one document", "")
      return;
    }
  }

  onFileSelected(event, id) {

    const file: File = event.target.files[0];

    const fileExtension = file.name.split('.').pop();

    var allowedExtensions = ["pdf","PDF","txt","ico","TXT","jpg", "jpeg", "png", "JPG", "JPEG", "JFIF", "BMP","doc","docx","Doc"];

    if (this.isInArray(allowedExtensions, fileExtension)) {
    this.upload_service.uploadFileToS3(file, "Services").subscribe(
      response => {

        this.documentUrls[id]["url"] = this.globals.cf_url+'/' + response.Key;
        this.documentUrls[id]["name"] = event.target.files[0]["name"];
        this.documentUrls[id]["uploaded"] = "Yes";
        this.showRemove=true;
        this.notificationObj.contentUrl = this.globals.cf_url+'/' + response.Key;
        if(id == 0){
          this.isSmsDisabled=false;
          this.notificationObj.isSMSAlert=false;
        }
        // this.images[idx]["img_url"] = this.imgUrl;

        this.toastr.showSuccess("Document uploaded successfully.", "");

      },
      error => {
        console.log('An error occured while uploading the image.');

      })
    }else{
      this.toastr.showError("Please upload only valid eztension document.", "");
      this.documentUrls[id]["url"] = "";
      this.documentUrls[id]["name"] = "";
      this.documentUrls[id]["uploaded"] = "No";
      if(id == 0){
        this.isSmsDisabled=false;
      }

      
    }


  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  
  getNotification(id){
      this.notificationService.getNotificationById(id).subscribe((response:any) => {
          this.notificationObj= response['data'];
          this.notificationObj.isEmailAlert=false;
          this.notificationObj.isWhatsappAlert=false;
          this.notificationObj.isSMSAlert=false;
          this.typeDisabled=true;
          this.documentUrls=this.notificationObj.documentUrls;
          if(this.documentUrls.length == 0)
          this.documentUrls.push({
            count: this.documentUrls.length + 1,
            url: "",
            name: "",
            uploaded: "No"
          })
          if(this.notificationObj.notificationType == "Other"){
          
            this.isOther=true;
            this.showGrid=false;
          }
          if(this.documentUrls[0].uploaded == "No")
            this.isSmsDisabled= false;
          else
            this.isSmsDisabled=true;  
      })
  }
  onUserRowSelect(event) {
    this.notificationObj.members = event.selected;
  }
  saveNotification(selection) {
    this.showLoader=true;
    if(selection == "publish"){
    if(this.showGrid){
      if(this.notificationObj.members == null || this.notificationObj.members.length ==0 )
      {
        this.toastr.showError("Please select members","");
        this.showLoader=false;
        return;
      
      }
      if(!this.notificationObj.isSMSAlert && this.notificationObj.notificationType != "Other"){
        this.toastr.showError("Please select sms option","");
        this.showLoader=false;
        return;
      }
    }
    // if(!this.notificationObj.isSMSAlert &&!this.notificationObj.isWhatsappAlert  &&!this.notificationObj.isEmailAlert ){
    //   this.toastr.showError("Please select atleast one option for alert.","");
    //   this.showLoader=false;
    //   return;
    // }
    if(this.notificationObj.notificationName == null || this.notificationObj.notificationName == undefined ||this.notificationObj.notificationName == "")
    {
      this.toastr.showError("Please enter notification name","");
      this.showLoader=false;
      return;
    }
    if(this.notificationObj.notificationType == null || this.notificationObj.notificationType == undefined || this.notificationObj.notificationType == "")
    {
      this.toastr.showError("Please select notification type","");
      this.showLoader=false;
      return;
    }
    if(this.notificationObj.content == null || this.notificationObj.content == undefined || this.notificationObj.content == "")
    {
      this.toastr.showError("Please enter notfication content","");
      this.showLoader=false;
      return;
    }
  }
    var id = 1;
    this.notificationObj.staffId = id;
    
   if(selection != "publish" && selection != "unpublish"){
    if (this.notificationObj.notificationId == '' || this.notificationObj.notificationId == null || this.notificationObj.notificationId == undefined)
      this.notificationObj.isPublished = false;
   }
    if(this.documentUrls[0]['uploaded'] == "No")
        this.notificationObj.documentUrls=[];
    else
        this.notificationObj.documentUrls=this.documentUrls;    
    this.notificationService.saveNotification(this.notificationObj).subscribe((response: any) => {
      if(response['data'] != null){
       if(this.notificationObj == null && this.notificationObj.isPublished) 
           this.toastr.showSuccess("Notification published successfully.", "");
       else if(this.notificationObj == null && !this.notificationObj.isPublished) 
           this.toastr.showSuccess("Notification saved successfully.", ""); 
       else if(this.notificationObj != null && this.notificationObj.isPublished) 
           this.toastr.showSuccess("Notification published successfully.", "");  
       else          
           this.toastr.showSuccess("Notification unpublished and saved successfully.", "");  

      this.authService.action();
      this.showLoader=false;
      if(response['data']['isPublished'] || selection =="save")
      this.router.navigate(['/list-notifications'])
      }else{
        this.showLoader=false;
        this.router.navigate(['/list-notifications'])
      }
    }, error => {
      this.toastr.showError("Failed to save data", "");
    })


  }

  onOptionsSelected(value:string){
    this.isOther=false;
    this.showGrid=false;
    if(this.notificationObj.notificationId == null || this.notificationObj.notificationId == '' || this.notificationObj.notificationId == undefined){
    this.notificationObj.content ="";
    this.notificationObj.notificationName="";
    this.notificationObj.members=[];
    this.notificationObj.isEmailAlert=false;
    this.notificationObj.isWhatsappAlert=false;
    this.notificationObj.isSMSAlert=false;
    }
    if(value == "Other"){
    this.isOther=true;
    this.notificationObj.isWhatsappAlert=false;
    this.notificationObj.isEmailAlert=false;
    }
}

clickAction(selection){
    this.showGrid=false;
    if(selection == "next")
      this.showGrid=true;
}
getMemberDetails() {
  this.memberservice.getMemberDetails("existMembers").subscribe((response: any) => {
    if (response['data'].length != 0){
      this.membersData = response['data'].reverse();
      this.membersData=response['data'].reverse().filter(d => (d.activeStatus == "Approved" || d.activeStatus == "unverified"));
      

    }else
      this.membersData = []
  })
}

  action() {
    // if(this.showGrid){
    //   if(this.notificationObj.members == null || this.notificationObj.members.length ==0 )
    //   {
    //     this.toastr.showError("Please select members","");
    //     return;
    //   }
    //   if(!this.notificationObj.isSMSAlert){
    //     this.toastr.showError("Please select sms option","");
    //     return;
    //   }
    // }
    // if(!this.notificationObj.isSMSAlert &&!this.notificationObj.isWhatsappAlert  &&!this.notificationObj.isEmailAlert ){
    //   this.toastr.showError("Please select atleast one option for alert.","");
    //   this.showLoader=false;
    //   return;
    // }
    // if(this.notificationObj.notificationName == null || this.notificationObj.notificationName == undefined ||this.notificationObj.notificationName == "")
    // {
    //   this.toastr.showError("Please enter name","");
    //   return;
    // }
    // if(this.notificationObj.notificationType == null || this.notificationObj.notificationType == undefined ||this.notificationObj.notificationType == "")
    // {
    //   this.toastr.showError("Please select notification type","");
    //   return;
    // }
    // if(this.notificationObj.content == null || this.notificationObj.content == undefined ||this.notificationObj.content == "")
    // {
    //   this.toastr.showError("Please enter content","");
    //   return;
    // }
    if (this.publishStatus == 'publish')
      this.notificationObj.isPublished = true;
    else
      this.notificationObj.isPublished = false;

    this.saveNotification(this.publishStatus);
  }

  /*************************************Methods to perform the actions on Model popup for confirmation and rejection popups starts here
 *
 */
  open(content, status) {
    if(status == "publish"){
      if(this.showGrid){
        if(this.notificationObj.members == null || this.notificationObj.members.length ==0 )
        {
          this.toastr.showError("Please select members","");
          return;
        }
        if(!this.notificationObj.isSMSAlert && this.notificationObj.notificationType != "Other" ){
          this.toastr.showError("Please select sms option","");
          return;
        }
      }
      // if(!this.notificationObj.isSMSAlert &&!this.notificationObj.isWhatsappAlert  &&!this.notificationObj.isEmailAlert ){
      //   this.toastr.showError("Please select atleast one option for alert.","");
      //   this.showLoader=false;
      //   return;
      // }
      if(this.notificationObj.notificationName == null || this.notificationObj.notificationName == undefined ||this.notificationObj.notificationName == "")
      {
        this.toastr.showError("Please enter  notification name","");
        return;
      }
      if(this.notificationObj.notificationType == null || this.notificationObj.notificationType == undefined ||this.notificationObj.notificationType == "")
      {
        this.toastr.showError("Please select notification type","");
        return;
      }
      if(this.notificationObj.content == null || this.notificationObj.content == undefined ||this.notificationObj.content == "")
      {
        this.toastr.showError("Please enter content","");
        return;
      }
    }
    this.publishStatus = status;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.showLoader=false;
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    this.showLoader=false;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  /*************************************Methods to perform the actions on Model popup for confirmation and rejection popups ends here
 *
 */


}
