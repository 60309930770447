import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../sevice/notification.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  monthlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  constructor(private notificationService: NotificationService,public route: ActivatedRoute, public router: Router) { }
  notificationsData :any;
  noticesData:any;
  ngOnInit(): void {
    this.getLatestNews();
    this.getNotices();
  }
  getLatestNews(){
    this.notificationService.getsavedNotificationAlerts("All","Latest News",null).subscribe((response:any) =>{
      this.notificationsData = response['data'];
      // this.notificationsData = response['data'];
      // this.notificationsData.reverse();
      let customSort = (a, b) => {
        const dateA = a.updatedDate;
        const dateB = b.updatedDate;
        // console.log(dateA, dateB);
        if(dateA < dateB) {
          return 1;
        } else if(dateA > dateB) {
          return -1;
        }
        return 0;
      }

      this.notificationsData =  this.notificationsData.sort(customSort).slice(0,6);
      for(let i = 0; i < this.notificationsData.length;i++){
        let  date = new Date(this.notificationsData[i]['updatedDate']);
        this.notificationsData[i]['month'] = this.monthlist[date.getMonth()];
        if(date.getDate() < 10)
        this.notificationsData[i]['date'] = "0"+date.getDate();
        else
        this.notificationsData[i]['date'] = date.getDate();
        this.notificationsData[i]['year'] = date.getFullYear();
      }
    })
  }
  getNotices(){
    this.notificationService.getsavedNotificationAlerts("All","Notices",null).subscribe((response:any) =>{
      this.noticesData = response['data'];
      // this.noticesData.reverse();

      let customSort = (a, b) => {
        const dateA = a.updatedDate;
        const dateB = b.updatedDate;
        // console.log(dateA, dateB);
        if(dateA < dateB) {
          return 1;
        } else if(dateA > dateB) {
          return -1;
        }
        return 0;
      }

      this.noticesData =  this.noticesData.sort(customSort).slice(0,6);
      for(let i = 0; i < this.noticesData.length;i++){
        let  date = new Date(this.noticesData[i]['updatedDate']);
        this.noticesData[i]['month'] = this.monthlist[date.getMonth()];
        if(date.getDate() < 10)
        this.noticesData[i]['date'] = "0"+date.getDate();
        else
        this.noticesData[i]['date'] = date.getDate();
        this.noticesData[i]['year'] = date.getFullYear();
      }
    })
  }

}
