import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../sevice/notification.service';
export class notificationObj {
  notificationId;
  notificationType;
  notificationName;
  staffId;
  content;
  contentUrl;
  createdDate;
  updatedDate;
  isPublished;
}
@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.scss']
})
export class LatestNewsComponent implements OnInit {
  notificationObj: notificationObj;
  constructor(private notificationService: NotificationService,public route: ActivatedRoute, public router: Router) { }
  notificationsData :any;
  private sub:any;
  showList:boolean =false;
  showSingleNews:boolean =false;
  fileName: any;
  monthlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  ngOnInit(): void {
    this.notificationObj = new notificationObj();
    this.sub = this.route.params.subscribe(params => {
      if(params['notificationId'] != null){
        this.showList = false;
        this.showSingleNews=true
          this.getNewsById(params['notificationId']);
      }else{
        this.showList = true;
        this.showSingleNews=false
        this.getLatestNews();
      }  
     })
   
  }

  showNews(notificationName,notificationId){
    this.router.navigate(['/latest-news',notificationName,notificationId])
  }
  getNewsById(id){
      this.notificationService.getNotificationById(id).subscribe((response:any) =>{
        this.notificationObj = response['data'];
        let url = this.notificationObj.contentUrl;
        this.fileName = url.split('/').pop();
      })
  }

  getLatestNews()
  {
    this.notificationService.getsavedNotificationAlerts("All","Latest News",null).subscribe((response:any) =>{
      // this.notificationsData=response['data'].slice(0,11);
      this.notificationsData=response['data'];

      this.notificationsData.reverse();
      let customSort = (a, b) => {
        const dateA = a.updatedDate;
        const dateB = b.updatedDate;
        // console.log(dateA, dateB);
        if(dateA < dateB) {
          return 1;
        } else if(dateA > dateB) {
          return -1;
        }
        return 0;
      }

      this.notificationsData =  this.notificationsData.sort(customSort);
     
      for(let i = 0; i < this.notificationsData.length;i++){
        let  date = new Date(this.notificationsData[i]['updatedDate']);
        this.notificationsData[i]['month'] = this.monthlist[date.getMonth()];
        if(date.getDate() < 10)
        this.notificationsData[i]['date'] = "0"+date.getDate();
        else
        this.notificationsData[i]['date'] = date.getDate();
        this.notificationsData[i]['year'] = date.getFullYear();
      }
        
    })
  }

}
