import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  showfirst: boolean=false;
  showsecond: boolean=false;
  showthird: boolean=false;
  showforth: boolean=false;

  constructor(public route: ActivatedRoute, public router: Router) { }
sub:any;

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
			this.show( params['selection']);
		})
  }


  show(selection){
    this.showfirst = false;
    this.showsecond = false;
    this.showthird = false;
    this.showforth = false;
    if(selection=='overview')
      this.showfirst=true;
    else if(selection=='officebearers')
      this.showsecond=true;
    else if(selection=='committee-members')
      this.showthird=true;
    else if(selection=='jubilee-hills')
      this.showforth=true;
  }

}
