import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  private API = "/api/members";
  allMembers = [];
  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  register(registerDetails){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"/register",registerDetails);
  }
  getMemberDetails(selection){
    const data =  this.http.get(this.globalUrl.SERVER_URL+this.API+"/"+selection);
    
    return data;
  }
  getMemberDetailsById(selection,Id){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+"/"+selection+"/"+Id);
  }
  transferMember(regMember,ExistingMemberId,NewMemberID){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"/transfer/"+ExistingMemberId +"/"+NewMemberID,regMember);

  }
  checkUserLogin(memberId){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+"/userLogin/"+memberId);
  }
  getMemberDetailsByPasswordToken(passwordToken){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+"/byPasswordToken/"+passwordToken);
  }
  getMemberDetailsByMemberId(selection,memberId){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+"/byMemberId/"+selection+"/"+memberId);
  }
  searchMember(searchValue,searchBy,searchFrom){
     return this.http.get(this.globalUrl.SERVER_URL+this.API+"/search/"+searchValue+"/"+searchBy+"/"+searchFrom);
  }
  updateRegisterMember(modal){
    return this.http.put(this.globalUrl.SERVER_URL+this.API+"/updateregisterMember",modal);
  }
  updateMember(modal){
    return this.http.put(this.globalUrl.SERVER_URL+this.API+"/update",modal);
  }
  approveRegisteredMember(regMember, member) {
    return this.http.put(this.globalUrl.SERVER_URL+this.API+"/updatewrapperMember", {regMember, member});
  }

}
