<div class="container-fluid">
    <div class="row">
      <div class="col-md-2 my-3">
        <side-nav-bar></side-nav-bar>
      </div>
      <div class="col-md-10 my-3">  
        <div class="container-fluid px-0">
            <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
                <div class="col-md-auto">
                    <button class="btn border jh-btn-primary jh-back-btn" (click)="navigateTo(null)">Create Member</button>
                  </div> 
                  <div class="col-md-auto">
                    <button class="btn border jh-btn-primary jh-back-btn" style="
                    position: absolute;
                    right: 50px;
                    top: 325px;
                " (click)="Model('show')">Actions</button>
                  </div> 
                <div class="text-center jh-heading" >Member Details</div>    
                <div class="col-md-12">
                    <div class="row">  
                        <ng2-smart-table [settings]="settings" [source]="membersData" (custom)="onCustomAction($event)" (userRowSelect)="onUserRowSelect($event)"></ng2-smart-table>                   
                </div>         
                </div>     
            </div>
        </div>
        </div>
    </div>

    <div class="modal fade" id="staticBackdrop" *ngIf="showPreview" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="background: #24242499;
    backdrop-filter: blur(1px);">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title col text-center" id="staticBackdropLabel">Actions</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="Model('close')"></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid my-2">
            <div class="row">
              <div class="col-md-3 form-label fw-bolder">Action:</div>
              <div class="col-md-auto">
                <select class="form-label" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="actionSelection" (change)='onOptionsSelected($event)'>
                  <option value="" [disabled]="true">--Select--</option>
                  <option value="Create PDF" >Create PDF</option>
                  <option value="Transfer Member" >Transfer Member</option>
                </select>
              </div>
            </div>
          </div>
          <div class="container-fluid my-2" *ngIf = "actionSelection != '' &&actionSelection != undefined &&actionSelection != null && actionSelection =='Create PDF'  ">
            <div class="row">
              <div class="col-md-3 form-label fw-bolder">Select upto :</div>
              <div class="col-md-auto">
                <input type="number" class="form-control"  [disabled] = "printAll || printSelection" id="" [(ngModel)]="endIndex"
                placeholder="End Index" autocomplete="off">
              </div>
              <div style=" margin-left: 380px; margin-top: -25px;  ">
                <input type="checkbox" [(ngModel)]="printAll"  id="" (change)="select($event,'selectAllBox')">
                <span style="  margin-left: 8px; ">All</span>
              </div>
              <div style=" margin-left: 471px; margin-top: -25px;  ">
                <input type="checkbox"  id="" [(ngModel)]="printSelection" (change)="select($event,'selected')">
                <span style="  margin-left: 8px; ">Selected</span>
              </div>
            </div>
          </div>
          <div class="container-fluid my-2" *ngIf = "actionSelection != '' &&actionSelection != undefined &&actionSelection != null && actionSelection =='Create PDF' ">
            <div class="row">
              <div class="col-md-7 my-0">
                <button class="btn jh-btn-green ms-2" (click)="downloadPdf()">Download PDF</button>
              </div>
            </div>
          </div>
          <div class="container-fluid my-2" *ngIf = "actionSelection != '' &&actionSelection != undefined &&actionSelection != null && actionSelection =='Transfer Member' ">
            <div class="row">
              <div class="col-md-7 my-0 center">
                <button class="btn jh-btn-green ms-2" (click)="transfermemberNavigation()">Proceed</button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>