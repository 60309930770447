import { Component, OnInit } from '@angular/core';
import { AlertService } from '../sevice/alert.service';
import { DbServiceService } from '../sevice/db-service.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  name: string;
  email: string;
  message: string;
  showLoader:boolean = false;
  constructor(private db_service: DbServiceService, private toastr: AlertService) { }

  ngOnInit(): void {
  }

  sendNotification(){
    this.showLoader=true;
    var index = '/api/notifications/emailNotification/contactUs';
    var data ={
      "name":this.name,
      "email": this.email,
      "body": this.message,
      "templateCode":"Jhchbs_IE_002"
    }
    this.db_service.saveDataToDb(index, data).subscribe(reponse=>{
      this.name = "";
      this.email = "";
      this.message = "";
      this.showLoader=false;
      this.toastr.showSuccess('Email sent successfully.', "");
    })

  }

}
