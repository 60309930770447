import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private API = "/api/orders";
  private paymentAPI = "/api/razorpay"; 
  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  createOrder(ordersModel){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"/createOrder",ordersModel);
  }

  updateOrder(paymentModel){
    return this.http.put(this.globalUrl.SERVER_URL+this.paymentAPI+"/updateOrder",paymentModel);
  }

}
