import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-president-desk',
  templateUrl: './president-desk.component.html',
  styleUrls: ['./president-desk.component.scss']
})
export class PresidentDeskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
