import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../sevice/alert.service';
import { FileUploadService } from '../../sevice/file-upload.service';
import { PaymentService } from '../../sevice/payment.service';
import { RequestsService } from '../../sevice/requests.service';
import { WindowRefService } from '../../sevice/window-ref.service';
import { Globalconfig } from '../../utility/global.config';
import  * as _ from 'lodash';
import { filter } from 'rxjs-compat/operator/filter';


declare var Razorpay: any;
export class requestModel {
  id;
  requestId;
  serviceId;
  serviceName;
  amount;
  memberId;
  userId;
  memberName;
  paymentType;
  paymentStatus;
  paymentId;
  requestStatus;
  documentUrl: Object[];
  requestHistory: Object[];
  comments;
  createdDate;
  lastUpdatedDate;
  updatedBy;
  service;
}

export class serviceModel {
  serviceId;
  serviceName;
  serviceDescription;
  serviceType;
  isPaidService;
  isPaylaterService;
  isDocumentRequired;
  isActive;
  amounts: amountsModel;
  createdDate;
  updatedDate;
  doc_url;
}

export class amountsModel {
  amountId;
  serviceId;
  amount;
  createdDate;
  updatedDate;
}

export class OrdersModel {
  orderId;
  memberId;
  userId;
  requestId;
  paymentId;
  invoiceId;
  discountAmount;
  amountWithoutTax;
  taxAmount;
  totalAmount;
  paymentMode;
  isRefunded;
  orderStatus;
  currencyId;
  paymentGatewayOrderId;
  CreatedDate;
  updatedDate;
}


@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  requestModel: requestModel;
  serviceModel: serviceModel;
  amountsModel: amountsModel;
  ordersModel: OrdersModel;
  error: string;
  isAdmin: boolean = false;
  isMember: boolean = false;
  showRequestDetails: boolean = false;
  showServcieDetails: boolean = false;
  showRequestPreview: boolean = false;
  showRemove:boolean = false;
  requestsData: any;
  dbRequeststatus:string ;
  memberId: string;
  userId: number;
  memberMainId;
  closeResult: string;
  serviceTyepsData: any;
  servicesData: any;
  serviceType = "";
  serviceTypeName = "";
  documentUrls: any[] = [{
    count : 1,
    url: "",
    name: "",
    uploaded: "No"
  }
  ];
  requestHistory: any[] = [{
    comment : "",
    oldStatus: "",
    newStatus: "",
    updatedBy: "",
    requestUpdatedDate:""
  }
  ];
  // comments: string;
  settings = {};
  adminGridDetails = {

    columns: {
      requestId: {
        title: 'Request ID',
      },
      memberId: {
        title: 'Member id',
      },
      memberName: {
        title: 'Member name',
      },
      plotNo:{
        title: 'Plot no',
      },
      roadNo:{
        title: 'Road no',
      },
      serviceName: {
        title: 'Service Name',
      },
      paymentType: {
        title: 'Payment Type',
      },

      // serviceCreated: {
      //   title: 'Service created',
      // },
      requestStatus: {
        title: 'Request Status',
      },
      paymentStatus: {
        title: 'Payment Status',
        filter: false,
        type: 'html',
        valuePrepareFunction: (value) => {
          return value;
        }
      }
    },
    actions: {
      columnTitle: 'More',
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      filter: false,
      custom: [{ name: 'verify', type: 'html', title: '<i class="fa fa-eye grid-icons jh-eye"  title ="Verfiy"></i>' }]
    }
  };

  options = {
    "key": "",
    "amount": "",
    "name": "JHCHBS",
    "description": "Jublilee hills corporation housing board society pvt Ltd.",
    "image": "https://www.javachinna.com/wp-content/uploads/2020/02/android-chrome-512x512-1.png",
    "order_id": "",
    "handler": function (response) {
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      window.dispatchEvent(event);
    }
    ,
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#3399cc"
    }
  };

  memberGridDetails = {

    columns: {
      requestId: {
        title: 'Request ID',
      },
      serviceName: {
        title: 'Service Name',
      },
      paymentType: {
        title: 'Payment Type',
      },
      // serviceCreated: {
      //   title: 'Service created',
      // },
      requestStatus: {
        title: 'Request Status',
      },
      paymentStatus: {
        title: 'Payment Status',
        filter: false,
        type: 'html',
        valuePrepareFunction: (value) => {
          return value;
        }
      }
    },
    actions: {
      columnTitle: 'More',
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      filter: false,
      custom: [{ name: 'verify', type: 'html', title: '<i class="fa fa-eye grid-icons jh-eye"  title ="Verfiy"></i>' }]
    }
  };

  constructor(private modalService: NgbModal, private toastr: AlertService, public globals: Globalconfig,public requestService: RequestsService, private upload_service: FileUploadService, private paymentService: PaymentService, private winRef: WindowRefService,) { }

  ngOnInit(): void {
    this.requestModel = new requestModel();
    this.serviceModel = new serviceModel();
    this.amountsModel = new amountsModel();
    this.ordersModel = new OrdersModel();
    this.memberId = localStorage.getItem("authenticateId");
    this.memberMainId = localStorage.getItem("authenticatemainId");
    if (localStorage.getItem("role") == "admin") {
      this.isAdmin = true;
      this.settings = this.adminGridDetails;
    } else {
      this.isMember = true;
      this.settings = this.memberGridDetails;
    this.userId = parseInt(localStorage.getItem("authenticatemainId"));

    } this.servicesData = [];
    // this.requestModel.requestStatus = 'New'
    this.getRequests();
  }

  addDocuments() {
    if (this.documentUrls.length > 5) {
      this.toastr.showError('Maximum limit for adding documents reached.', '');
      return;
    }


    this.documentUrls.push({
      count: this.documentUrls.length + 1,
      url: "",
      name: "",
      uploaded: "No"
    })
    this.showRemove=true;
  }
  clearDocument(){
    this.showRemove=false;
    this.documentUrls = [];
    this.documentUrls.push({
      count: this.documentUrls.length + 1,
      url: "",
      name: "",
      uploaded: "No"
    })
  }

  removeDocument(i: number) {
    this.showRemove=false;
    if (this.documentUrls.length > 1)
      this.documentUrls.splice(i, 1);
    else {
      this.toastr.showError("Cannot remove required atleast one document", "")
      return;
    }
  }

  requestAction(selection) {
    this.getServices("Partial", true, "none");
    if (selection == "new") {
      this.showRequestDetails = true;
      this.showServcieDetails = true;
      this.showRequestPreview = false;
      this.serviceType = "";
    }
    if (selection == "View") {
      this.showRequestDetails = true;
      this.showServcieDetails = true;
      this.showRequestPreview = true;
      this.getServiceByserviceId(this.requestModel.serviceId);
    }
  }
  getServiceByserviceId(id) {
    this.requestService.serviceById(id).subscribe((response: any) => {
      this.serviceModel = response['data'];
      this.serviceTypeName = this.serviceModel.serviceType;
      this.showRequestDetails = true;

      if (this.serviceModel.amounts != null) {
        this.amountsModel = this.serviceModel.amounts;
        this.requestModel.amount = this.amountsModel.amount;
      } else {
        this.amountsModel = null;
        this.requestModel.amount = 0;
        
      }

    })
  }

  async getServices(selection, status, serviceType) {
    let response = await this.requestService.getServices(selection, status, serviceType).toPromise();
    if (serviceType == "none") {
      let flags = [];
      this.serviceTyepsData = [];
      this.servicesData = [];
      for (let i = 0; i < response['data'].length; i++) {
        if (flags[response['data'][i].serviceType]) continue;
        flags[response['data'][i].serviceType] = true;
        var serviceTypeObject = {};
        serviceTypeObject['serviceType'] = response['data'][i].serviceType
        this.serviceTyepsData.push(serviceTypeObject);
      }

    } else {
      this.servicesData = response['data'];
    }

  }
  async getRequests() {
    let response: any = [];
    if (this.isMember){
      response= await this.requestService.getRequests(this.memberId, "Partial", "none").toPromise();
      response['data'] = response['data'].reverse();
     } else  {
      response = await this.requestService.getRequests("All", "All", "All").toPromise();
      response['data'].reverse();
    }
    this.requestsData = response['data'];
    // this.requestsData = this.isMember ? (_.filter(response['data'], ['userId', this.userId])).reverse() : response['data'];
  }

  createRequestPrview() {

    this.documentUrls = [{
      count:1,
      url: "",
      name: "",
      uploaded: "No"
    }
    ];

    this.requestHistory = [{
      comment : "",
      oldStatus: "",
      newStatus: "",
      updatedBy: "",
      requestUpdatedDate:""
    }
    ];

    this.requestModel.serviceName = this.serviceModel.serviceName;


    this.showServcieDetails = false;
    this.showRequestPreview = true;
    this.requestModel.serviceId = this.serviceModel.serviceId;
    this.requestModel.requestStatus = "New";
    this.requestModel.memberId = this.memberId;
    this.requestModel.userId = this.userId;
    this.requestModel.memberName = localStorage.getItem("authenticateUser");
    this.requestModel.updatedBy = localStorage.getItem("authenticateUser");
    if (this.amountsModel != null) {
      if (this.amountsModel.amount == 0 || this.amountsModel.amount == undefined || this.amountsModel.amount == null)
        this.requestModel.amount = 0;
      else
        this.requestModel.amount = this.amountsModel.amount;


    }
    if (this.requestModel.amount == 0)
    this.requestModel.paymentStatus = "Free";
    else
   this.requestModel.paymentStatus = "Not paid"
    this.requestModel.serviceName = this.serviceModel.serviceName;
    if (this.serviceModel.isPaidService)
      this.requestModel.paymentType = "Paid service";
    if (this.serviceModel.isPaylaterService)
      this.requestModel.paymentType = "Paylater service";
    if (!this.serviceModel.isPaidService && !this.serviceModel.isPaylaterService)
      this.requestModel.paymentType = "Free service";
      if (this.serviceModel.isPaidService && this.serviceModel.serviceType == "Maintanance Services")
      this.requestModel.paymentStatus = "Pay post availing service";


  }
  saveRequest() {
    

    if (this.isAdmin) {
      
	   if(this.requestModel.comments == null || this.requestModel.comments == "" || this.requestModel.comments == undefined ){
        this.toastr.showError("Please add comments for the request","");
        return;

      }
	  
      if (this.requestModel.requestStatus == "Approved") {
        if (this.requestModel.amount == 0 || this.requestModel.amount == undefined || this.requestModel.amount == null)
          this.requestModel.paymentStatus = "Free"
        else{
          if(this.serviceModel.serviceType =="Maintanance Services")
          this.requestModel.paymentStatus = "Pay post availing service";
          else
          this.requestModel.paymentStatus = "Pay"+'  '+this.requestModel.amount + "/-";

        }
   
      }
      else if (this.requestModel.requestStatus == "MC Review") {
        
      }
      else if (this.requestModel.requestStatus == "Inprogress") {
        if (this.requestModel.amount == 0 || this.requestModel.amount == undefined || this.requestModel.amount == null)
          this.requestModel.paymentStatus = "Free"
        else
          this.requestModel.paymentStatus = "Paid" +' '+ this.requestModel.amount + "/-";
      } else if (this.requestModel.requestStatus == "Documents pending") {
        if (this.requestModel.amount == 0 || this.requestModel.amount == undefined || this.requestModel.amount == null)
          this.requestModel.paymentStatus = "Free"
        else
          this.requestModel.paymentStatus = "Not Paid";
      } else {
        if (this.requestModel.requestStatus == "Rejected") {
          if (this.requestModel.paymentStatus == "Paid" && this.requestModel.paymentStatus == "Free" && this.requestModel.amount == 0){
            this.toastr.showError("Unable change status to rejected .", "");
          return
          }else{
            this.requestModel.paymentStatus="NA";
            
          }
        }
        if (this.requestModel.requestStatus == "Completed") {
          if (this.requestModel.paymentStatus != "Paid" && this.requestModel.paymentStatus != "Free" && this.requestModel.amount != 0 && this.serviceModel.serviceType != "Maintanance Services"){
            this.toastr.showError("Unable perform action payment is incomplete.", "");
          return
          }
          if (this.requestModel.amount == 0 || this.requestModel.amount == undefined || this.requestModel.amount == null)
          this.requestModel.paymentStatus = "Free"
          else
          this.requestModel.paymentStatus = "Paid" +' '+ this.requestModel.amount + "/-";
        }
      
      }


    }
   if(this.documentUrls.length != 0){ 
    if(this.documentUrls.length != 1){ 
    for(let dataurl of this.documentUrls){
      if(dataurl['url'] == ''){
      this.toastr.showError("Please check all documents are uploaded.","");  
      return;
      }
    }
  }
  }
    this.requestModel.documentUrl = this.documentUrls;
    // if(parseInt(localStorage.getItem("authenticateMainId")) != "NaN" ){
    // this.userId = parseInt(localStorage.getItem("authenticateMainId"));
    // }
    // this.userId = this.isMember ? parseInt(localStorage.getItem("authenticatemainId")) : this.requestModel.userId;
    if(this.requestModel.userId == "null" || this.requestModel.userId == "") {
      this.userId = parseInt(localStorage.getItem("authenticateMainId"))
      this.requestModel.updatedBy = localStorage.getItem("authenticateUser");
    this.requestModel.userId = parseInt(localStorage.getItem("authenticatemainId"));
    }
    this.requestService.saveRequest(this.requestModel).subscribe(response => {

      if (response['status'] == 200 && response['data'] != null)
        this.toastr.showSuccess("Data saved successfully.", "");
      this.closeModel();
      this.getRequests();
    })
  }

  onDropdownChange(selection, event) {
    if (selection == "serviceType") {
      this.getServices("Partial", true, event.target.value);
      this.serviceTypeName = event.target.value;
      this.requestModel = new requestModel();
      this.requestModel.serviceId = "";
    }
    if (selection == "service") {
      this.getServiceByserviceId(+event.target.value);

    }
  }
  onCustomAction(event: any) {
    switch (event.action) {
      case 'verify':
        this.requestModel = event.data;
        this.getServiceByserviceId(this.requestModel.serviceId);
        this.showRemove=false;
        this.documentUrls=[];
        this.documentUrls = event.data.documentUrl;
        this.requestHistory = event.data.requestHistory;
        this.dbRequeststatus=this.requestModel.requestStatus;
        this.userId = this.requestModel.userId;
        this.requestAction("View");
        console.log("is Admin" + this.isAdmin);
        break;

    }

  }

  onFileSelected(event, id) {

    const file: File = event.target.files[0];

    const fileExtension = file.name.split('.').pop();

    var allowedExtensions = ["pdf","PDF","txt","ico","TXT","jpg", "jpeg", "png", "JPG", "JPEG", "JFIF", "BMP","doc","docx","Doc"];

    if (this.isInArray(allowedExtensions, fileExtension)) {
    this.upload_service.uploadFileToS3(file, "Services").subscribe(
      response => {
        console.log(this.globals.cf_url);
        this.documentUrls[id]["url"] = this.globals.cf_url+'/' + response.Key;
        this.documentUrls[id]["name"] = event.target.files[0]["name"];
        this.documentUrls[id]["uploaded"] = "Yes";
        this.showRemove=true;

        // this.images[idx]["img_url"] = this.imgUrl;

        this.toastr.showSuccess("Document uploaded successfully.", "");

      },
      error => {
        console.log('An error occured while uploading the image.');

      })
    }else{
      this.toastr.showError("Please upload only valid eztension document.", "");
      this.documentUrls[id]["url"] = "";
      this.documentUrls[id]["name"] = "";
      this.documentUrls[id]["uploaded"] = "No";
    }


  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }


  createOrder() {
    this.ordersModel.memberId = this.requestModel.memberId;
    this.ordersModel.requestId = this.requestModel.requestId;
    this.ordersModel.discountAmount = 0;
    this.ordersModel.amountWithoutTax = this.requestModel.amount;

    this.paymentService.createOrder(this.ordersModel).subscribe((response: any) => {
      if (response['status'] == 200) {
        this.options.key = response['data']['Key'];
        this.options.order_id = response['data']['paymentGatewayOrderId'];
        this.options.amount = response['data']['totalAmount'];
        this.options.prefill.name = this.requestModel.memberName;
        this.options.prefill.contact = "";
        this.options.prefill.email = "";
        var rzp = new Razorpay(this.options);
        rzp.open();

        rzp.on('payment.failed', function (response) {
          // Todo - store this information in the server
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);
          this.error = response.error.reason;

          var data = {}
          data['razorpayPaymentId'] = "";
          data['razorpayOrderId'] = "";
          data['razorpaySignature'] = "";
          data['totalAmount'] = this.options.amount;
          data['currencyId'] = "INR";
          data['status'] = "failed";
          data['memberId'] = this.requestModel.memberId;
          data['userId'] = this.requestModel.userId;
          data['requestId'] = this.requestModel.requestId;
          data['paymentGatewayOrderId'] = this.options.order_id;
          this.paymentService.updateOrder(data).subscribe(
            data => {
              console.log(data['message']);
              this.ngOnInit();
            }
            ,
            err => {
              this.error = err.error.message;
            }
          );
        });
      }
    }, error => {
      this.error = error.error.message;
    })

  }


  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event): void {
    var data = {}
    data['razorpayPaymentId'] = event.detail['razorpay_payment_id'];
    data['razorpayOrderId'] = event.detail['razorpay_order_id']
    data['razorpaySignature'] = event.detail['razorpay_signature'];
    data['totalAmount'] = this.options.amount;
    data['currencyId'] = "INR";
    data['status'] = "success";
    data['memberId'] = this.requestModel.memberId;
    data['userId'] = this.requestModel.userId;
    data['requestId'] = this.requestModel.requestId;
    data['paymentGatewayOrderId'] = this.options.order_id;
    this.paymentService.updateOrder(data).subscribe(
      data => {
        console.log(data['message']);
        this.closeModel();
        this.ngOnInit()
      }
      ,
      err => {
        this.error = err.error.message;
      }
    );
  }

  /*************************************Methods to perform the actions on Model popup for confirmation and rejection popups starts here
   *
   */
  closeModel() {
    this.showRequestDetails = false;
    this.showRequestPreview = false;
    this.showServcieDetails = false;
    this.requestModel = new requestModel();
    this.serviceModel = new serviceModel();
    this.amountsModel = new amountsModel();
    this.getRequests();
  }

  /*************************************Methods to perform the actions on Model popup for confirmation and rejection popups ends here
 *
 */




}
