<div class="container-fluid">
    <div class="row">
      <div class="col-md-2 my-3">
        <side-nav-bar></side-nav-bar>
      </div>
      <div class="col-md-10 my-3">  
        <div class="container-fluid px-0">
            <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded ">
                <div class="text-end">
                    <button class="btn border jh-btn-primary jh-back-btn " routerLink="/create-notifications" >Create New</button>
                  </div> 
             
                <div class="text-center jh-heading" >Notifications</div>    
                <div class="col-md-12">
                    <div class="row table-responsive">  
                        <ng2-smart-table [settings]="settings" [source]="notificationData" (custom)="onCustomAction($event)"></ng2-smart-table>                   
                </div>         
                </div>     
            </div>
        </div>
        </div>
    </div>
</div>