<div id="wrapper" class="container-fluid">


    <div class="intro-text col-md-8"  *ngIf = "BannerDetails == 'show'">
        <div> <img _ngcontent-lyk-c183="shadow" src="assets/images/jh-logo.png" alt="" width="120px" class="p-3" style="background: white;
            border-radius: 50%;"></div>
       <div>Jubilee Hills Co-Operative House Building Society</div> <div>Website Launch By</div> <div><b>"{{Label}}"</b></div><div style ="font-size: 22px;">{{Designation}}</div>
    </div>


    <div id="effect">
   
     <img src="assets/images/pink-curtain (1).jpg" [@changeDivSize1]=currentState  id="curtain1">
     <img src="assets/images/pink-curtain (1).jpg" [@changeDivSize2]=currentState  id="curtain2">
    </div>
    
    <div id="curtain_buttons">
     <input type="button" value="Launch" (click)="open_curtain();" style="cursor: pointer !important;" *ngIf = "BannerDetails == 'show'">
    </div>