import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private API = "/api/notifications/";
  private API2= "/api/notificationAlerts/"
  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  sendEmail(emailModel){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"email",emailModel);
  }
  sendResetPasswordEmail(emailModel){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"passwordreset",emailModel);
  }
  getsavedNotificationAlerts(selection,from,to){
    return this.http.get(this.globalUrl.SERVER_URL+this.API2+selection+'/'+from+'/'+to);
  }
  saveNotification(notificationModel){
    return this.http.post(this.globalUrl.SERVER_URL+this.API2+"save",notificationModel);
  }
  getNotificationById(id){
    return this.http.get(this.globalUrl.SERVER_URL+this.API2+id);
  }
}
