import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private API = "/api/users/"
  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  login(loginDetails){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"login",loginDetails);
  }
  createPassword(ResetPassword){
    return this.http.post(this.globalUrl.SERVER_URL+this.API+"createPassword",ResetPassword);
  }
  forgotPassword(memberId,mobileNo){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+"forgotPassword/"+memberId+"/"+mobileNo);
  }
}
