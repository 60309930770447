import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../sevice/notification.service';
export class notificationObj {
  notificationId;
  notificationType;
  notificationName;
  staffId;
  content;
  contentUrl;
  createdDate;
  updatedDate;
  isPublished;
}
@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-notice-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.scss']
})
export class NoticeBoardComponent implements OnInit {
  notificationObj: notificationObj;
  monthlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  constructor(private notificationService: NotificationService,public route: ActivatedRoute, public router: Router) { }
  noticesData :any;
  showList:boolean =false;
  showSingleNotice:boolean =false;
  fileName:any;
  private sub:any
  ngOnInit(): void {
    this.notificationObj = new notificationObj();
    
    this.sub = this.route.params.subscribe(params => {
      if(params['notificationId'] != null){
        this.showList = false;
        this.showSingleNotice=true
          this.getNoticeById(params['notificationId']);
      }else{
        this.showList = true;
        this.showSingleNotice=false
        this.getNotices();
      }  
     })
     
  }
  showNews(notificationName,notificationId){
    this.router.navigate(['/notice-board',notificationName,notificationId])
  }
  getNotices(){
    this.notificationService.getsavedNotificationAlerts("All","Notices",null).subscribe((response:any) =>{
      // this.noticesData= response['data'].slice(0,6);
      this.noticesData= response['data'];
      this.noticesData.reverse();
      let customSort = (a, b) => {
        const dateA = a.updatedDate;
        const dateB = b.updatedDate;
        // console.log(dateA, dateB);
        if(dateA < dateB) {
          return 1;
        } else if(dateA > dateB) {
          return -1;
        }
        return 0;
      }

      this.noticesData =  this.noticesData.sort(customSort);
      for(let i = 0; i < this.noticesData.length;i++){
        let  date = new Date(this.noticesData[i]['updatedDate']);
        this.noticesData[i]['month'] = this.monthlist[date.getMonth()];
        if(date.getDate() < 10)
        this.noticesData[i]['date'] = "0"+date.getDate();
        else
        this.noticesData[i]['date'] = date.getDate();
        this.noticesData[i]['year'] = date.getFullYear();
        
      }
    })
  }

  getNoticeById(id){
    this.notificationService.getNotificationById(id).subscribe((response:any) =>{
      this.notificationObj = response['data'];
      let url = this.notificationObj.contentUrl;
    this.fileName = url.split('/').pop();
    })
}
}
