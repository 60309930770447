import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globalconfig } from '../utility/global.config';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  private API= "/api/charts/"
  constructor(private http : HttpClient,private globalUrl : Globalconfig) { }
  create(selection,memberId){
    return this.http.get(this.globalUrl.SERVER_URL+this.API+selection+"/"+memberId);
  }
  
}
