import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../sevice/alert.service';
import { NotificationService } from '../../sevice/notification.service';
export class notificationObj {
  notificationId;
  notificationType;
  notificationName;
  staffId;
  content;
  contentUrl;
  createdDate;
  updatedDate;
  isPublished;
}
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-minutes-of-meeting',
  templateUrl: './minutes-of-meeting.component.html',
  styleUrls: ['./minutes-of-meeting.component.scss']
})
export class MinutesOfMeetingComponent implements OnInit {

  settings = {
    columns: {
      notificationId: {
        title: 'Meeting Id',
        filter: true,
      },
      notificationName: {
        title: 'Meeting Name',
        filter: true,
      },
      Date: {
        title: 'Posted Date',
        filter: true,
      }
    },
    actions: {
      columnTitle: 'More',
      add: false,
      edit: false,
      delete: false,
      position: 'right',
      filter: false,
      custom: [{ name: 'verify', type: 'html', title: '<i class="fa fa-eye grid-icons" title ="Verfiy"></i>' }]
    }
  };
  notificationObj: notificationObj;
  constructor(private notificationService: NotificationService, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
  meetingData: any;
  memberId;
  showList: boolean = false;
  showSingleMeeting: boolean = false;
  private sub: any;
  ngOnInit(): void {
    this.memberId = localStorage.getItem("authenticateId");
    this.notificationObj = new notificationObj();
    this.sub = this.route.params.subscribe(params => {
      if (params['notificationId'] != null) {
        this.showList = false;
        this.showSingleMeeting = true
        this.getMeetingById(params['notificationId']);
      } else {
        this.showList = true;
        this.showSingleMeeting = false
        this.getNotifications();
      }
    })

  }
  getMeetingById(id) {
    this.notificationService.getNotificationById(id).subscribe((response: any) => {
      this.notificationObj = response['data'];
    })
  }

  getNotifications() {
    this.notificationService.getsavedNotificationAlerts("All", "Minutes of Meeting", null).subscribe((response: any) => {
      if (response['data'] != null) {
        this.meetingData = response['data'].reverse();
        for (let i = 0; i < this.meetingData.length; i++) {
          let date = new Date(this.meetingData[i]['updatedDate']);
          if (date.getDate() < 10)
            this.meetingData[i]['Date'] = "0" + date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
          else
            this.meetingData[i]['Date'] = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();

        }
      }
    }, error => {
      this.toastr.showError("Failed to retrive data", "");
    })

  }

  onCustomAction(event) {
    switch (event.action) {
      case 'verify':
        this.router.navigate(['/minutes-of-meeting', event.data.notificationName ,event.data.notificationId]);
        break;

    }
  }

}
