<section>
    <div class="container-fluid">
      <div class="row"  >
        <div class="col-md-2 my-3">
          <div id="mySidenav" class="sidenav">
            <a href="/Dashboard" routerLink="/Dashboard">Dashboard</a>
             <a href="/member-profile/{{memberId}};" routerLink="/member-profile/{{memberId}}">Profile</a>
              <a href="/change-password/{{memberId}}" routerLink="/change-password/{{memberId}}">Change password</a>
             <a href="/requests" routerLink="/requests">Requests</a> 
             <a href="/minutes-of-meeting" routerLink="/minutes-of-meeting" class="jh-nav-req-active">Minutes of meeting</a>
             <!-- <a href="/poll-management" routerLink="/poll-management">Poll Management</a> -->
            <!-- <a href="/memberLogin">Logout</a> -->
          </div>
        </div>
  
        <div class="col-md-10 my-3" *ngIf="showList">
          <div class="container-fluid px-0">
            <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
              <div class="text-center jh-heading">Minutes of meeting</div>
              <div class="col-md-12">
                <div class="row">
                  <ng2-smart-table [settings]="settings" [source]="meetingData" (custom)="onCustomAction($event)">
                  </ng2-smart-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-10 my-3" *ngIf ="showSingleMeeting">
            <div class="container-fluid px-0">
          
            <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
              <div class="text-center jh-heading"><h3>{{notificationObj.notificationName}}</h3></div>     
              <div [innerHTML] = "notificationObj.content | safeHtml"> </div>   
            </div>
        </div>
        </div>
      </div>
 
      </div>
  </section>