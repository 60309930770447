import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globalconfig } from '../utility/global.config';
import { HttpClient } from "@angular/common/http";
import { S3 } from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  payLoad: any;
  payLoadObj: any;
  body: any;
  Job: any;
  Settings: any;
  OutputGroups: object[];
  ObjOutputGroups;
  OutputGroupSettings;
  FileGroupSettings;
  Destination: String;

  constructor(private HttpClient: HttpClient, private globals: Globalconfig) { }

  uploadFileToS3(file, key): Observable<any> {

    const bucket = new S3(
      {
        accessKeyId: this.globals.accessKeyId,
        secretAccessKey: this.globals.secretAccessKey,
        region: this.globals.region
      }
    );
    let extn = file.name.split('.').pop();
    let contentType = 'application/octet-stream';
    if (extn == 'html') contentType = "text/html";
    if (extn == 'css') contentType = "text/css";
    if (extn == 'js') contentType = "application/javascript";
    if (extn == 'pdf') contentType = "application/pdf";
    if (extn == 'mp4') contentType = "video/mp4";
    if (extn == 'png' || extn == 'jpg' || extn == 'gif') contentType = "image/" + extn;
    var options = { partSize: 100 * 1024 * 1024, queueSize: 3 };
    var fileName = file.name.replace(/ /g, "_");
    const params = {
      Bucket: this.globals.bucketname,
      Key: key + "/" + fileName,
      Body: file,
      ContentType: contentType
    };
    var Obj = this;
    return Observable.create(observer => {
      bucket.upload(params, options).on('httpUploadProgress', function (evt) {
        console.log("Uploaded :: " + ((evt.loaded * 100) / evt.total) + '%');
      }).send(function (err, data) {
        if (err) {
          console.log(err);
          return observer.next(err);

        }
        if (data) {
            return observer.next(data);
        }

      })
    })
  }
}
