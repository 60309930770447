import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
import { NbSidebarModule,NbLayoutModule, NbThemeModule, NbToastrModule } from '@nebular/theme';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NewRegistrationComponent } from './components/Member/new-registration/new-registration.component';
import { MembersComponent } from './components/Member/members/members.component';
import { VerticalAdsComponent } from './components/ads/vertical-ads/vertical-ads.component';
import { HorizontalAdsComponent } from './components/ads/horizontal-ads/horizontal-ads.component';
import { VerifyComponent } from './components/Member/verify/verify.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreatePasswordComponent } from './components/login/Password-Verify/create-password/create-password/create-password.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { PresidentDeskComponent } from './components/president-desk/president-desk.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { FooterComponent } from './components/footer/footer.component';
import { LatestNewsComponent, SafeHtmlPipe } from './components/latest-news/latest-news.component';
import { NoticeBoardComponent } from './components/notice-board/notice-board.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ForgotPasswordComponent } from './components/login/Forgot-password/forgot-password/forgot-password.component';
import { MemberProfileComponent } from './components/Member/member-profile/member-profile.component';
import { SideNavBarComponent } from './components/Member/side-nav-bar/side-nav-bar.component';
import { DashboardComponent } from './components/Member/dashboard/dashboard.component';
import { RequestsComponent } from './components/Member/requests/requests.component';
import { ModalModule } from 'angular-custom-modal';
import { JwtInterceptor } from './components/sevice/jwt-interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './components/Member/change-password/change-password.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CreateNotificationsComponent } from './components/Member/notifications/create-notifications/create-notifications.component';
import { ListNotificationsComponent } from './components/Member/notifications/list-notifications/list-notifications.component';
import { MinutesOfMeetingComponent } from './components/Member/minutes-of-meeting/minutes-of-meeting.component';
import { MemberDashboardComponent } from './components/Member/member-dashboard/member-dashboard.component';
import { LandingpageComponent } from './components/landingpage/landingpage.component';
import { HorizontalAdd2Component } from './components/ads/horizontal-add2/horizontal-add2.component';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { TransferMemberComponent } from './components/Member/transfer-member/transfer-member.component';
import { TransferMemComponent } from './components/Member/transfer-mem/transfer-mem.component';
import { PollManagementComponent } from './components/Member/poll-management/poll-management.component';
import { Phase4Component } from './phase4/phase4.component';





@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    NewRegistrationComponent,
    MembersComponent,
    MemberProfileComponent,
    VerticalAdsComponent,
    HorizontalAdsComponent,
    VerifyComponent,
    CreatePasswordComponent,
    AboutusComponent,
    PresidentDeskComponent,
    OurServicesComponent,
    FooterComponent,
    LatestNewsComponent,
    NoticeBoardComponent,
    PhotoGalleryComponent,
    ContactUsComponent,
    ForgotPasswordComponent,
    SideNavBarComponent,
    DashboardComponent,
    RequestsComponent,
    ChangePasswordComponent,
    CreateNotificationsComponent,
    ListNotificationsComponent,
    MinutesOfMeetingComponent,
    MemberDashboardComponent,
    LandingpageComponent,
    HorizontalAdd2Component,
    TermsandconditionsComponent,
    TransferMemberComponent,
    TransferMemComponent,
    PollManagementComponent,
    Phase4Component

  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    EditorModule,
    Ng2SmartTableModule,
    NgbModule,
    ReactiveFormsModule,
    NbLayoutModule,
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    TooltipModule.forRoot(),
     // this will enable the default theme, you can change this by passing `{ name: 'dark' }` to enable the dark theme
     NbThemeModule.forRoot(),
    AppRoutingModule,
    ModalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
