import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AlertService } from '../../../sevice/alert.service';
import { UsersService } from '../../../sevice/users.service';
import { NotificationService } from '../../../sevice/notification.service';
import { Globalconfig } from '../../../utility/global.config';

export class forgotPasswordModel {
  loginId:string;
  mobile:string;
}

@Component({
  selector: 'app-login',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  /**************************************************Attributes starts here********************************************** */  
  forgotPasswordModel: forgotPasswordModel;
  showLoader:boolean = false;
 /**************************************************Attributes ends here********************************************** */
  constructor(private notificationService: NotificationService, private config: Globalconfig, private userService: UsersService, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }

  /**************************Init method will trigger and initialize the attributes and required methods */
  ngOnInit(): void {
    this.forgotPasswordModel = new forgotPasswordModel();

  }
  //**Method for validating user and calling backend API */
  validateUser() {
    if(this.forgotPasswordModel.loginId == null) {
      this.toastr.showError("Enter Member Id","");
      return;
    }
    if(this.forgotPasswordModel.mobile == null || this.forgotPasswordModel.mobile.length < 10 ) {
      this.toastr.showError("Enter Valid 10 digit mobile no","");
      return;
    }

    // CALLING Backend API that validates the member and member registration tables and updates reset_password table
    this.userService.forgotPassword(this.forgotPasswordModel.loginId,this.forgotPasswordModel.mobile).subscribe((response:any) =>{
      this.forgotPasswordModel = new forgotPasswordModel();
      if((response['status'] == "200")){
        if(response['data'] == null) {
					this.toastr.showError( response['message'],"Error");	
				} 
        else {
          this.toastr.showSuccess("Password reset success","");
          let emailModel = {};
          emailModel['templateCode'] = "Jhchbs_IE_001";
          emailModel['id'] = response["data"];
          emailModel['link'] = this.config.CURRENT_SERVER_URL + "create-password/";
    
    this.notificationService.sendResetPasswordEmail(emailModel).subscribe((response: any) => {
      this.forgotPasswordModel = new forgotPasswordModel();
      this.toastr.showSuccess("Password reset mail sent","");
      this.router.navigate(['/login'])
    }, error => {
      this.toastr.showError("Error sending Password reset mail","");
      this.router.navigate(['/login'])
    })

        }
      }
    },error =>{
      this.toastr.showError( "Internal Server Error","Error");
    })
  }

}

