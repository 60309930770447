<div class="container-fluid">
  <div class="row align-items-start text-center1">

    <div class="col-md-2 order-md-1 js-hr-ads">
      <div class=" col my-5">
        <app-horizontal-ads></app-horizontal-ads>
      </div>
    </div>
    <div class="col-md-8 order-md-2  shadow p-5 my-4 rounded d-none ">
      <div class="container-fluid vision-div scroller">
        <div class="row fixed">
          <div class="col-md-12">
            <div class="text-center jh-heading">Latest News</div>

          </div>

          <div class="col-md-12 border-bottom blogShort my-2">
            <article class="d-flex mb-3">
              <div class="my-auto col-md-1">
                <p class="fs-6 fw-bolder">August 15, 2021</p>
              </div>

              <div class="row">
                <div class="px-2 text-start fw-bolder ms-3 fs-6">Happy 75th Independence Day</div>
                <div class="col-md-12 text-start ms-3">
                  Jubilee Hills society wishes all its members and families a Happy Independence day 2021
                </div>
                <!-- <a class="btn text-start ms-3" href="#">READ MORE</a> -->
              </div>
            </article>
          </div>
          <div class="col-md-12 border-bottom blogShort my-2">
            <article class="d-flex mb-3">
              <div class="my-auto col-md-1">
                <p class="fs-6 fw-bolder">April 16, 2021</p>
              </div>

              <div class="row">
                <div class="px-2 text-start fw-bolder ms-3 fs-6">Notice to waitlisted members</div>
                <div class="col-md-12 text-start ms-3">
                  Waitlisted members are requested to update their contact Information with the Jubilee Hills Society
                  Office at the earliest. Check the notice board for details.
                </div>
                <!-- <a class="btn text-start ms-3" href="#">READ MORE</a> -->
              </div>
            </article>
          </div>
          <div class="col-md-12 border-bottom blogShort my-2">
            <article class="d-flex mb-3">
              <div class="my-auto col-md-1">
                <p class="fs-6 fw-bolder">April 11, 2021</p>
              </div>

              <div class="row">
                <div class="px-2 text-start fw-bolder ms-3 fs-6">Renovation of society office</div>
                <div class="col-md-12 text-start ms-3">
                  Office renovation has been taken up for member’s convenience. A new reception area has been set up to
                  receive members cordially.
                </div>
                <!-- <a class="btn text-start ms-3" href="#">READ MORE</a> -->
              </div>
            </article>
          </div>
          
          <div class="col-md-12 border-bottom blogShort my-2">
            <article class="d-flex mb-3">
              <div class="my-auto col-md-1">
                <p class="fs-6 fw-bolder">May 5, 2021</p>
              </div>

              <div class="row">
                <div class="px-2 text-start fw-bolder ms-3 fs-6">FIR filed against Narendra Chowdary</div>
                <div class="col-md-12 text-start ms-3">
                  A case has been filed against T Narendra Chowdary for registering a plot using forged and fabricated
                  documents.
                </div>
                <a class="btn text-start ms-3" href="#">READ MORE</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded" *ngIf="showSingleNews">
      <div class="text-center jh-heading">
        <h3>{{notificationObj.notificationName}}</h3>
      </div>
      <div [innerHTML]="notificationObj.content | safeHtml"> </div>
      <div><a [href]=notificationObj.contentUrl target="_blank">{{fileName}}</a></div>
    </div>
    <div class=" col-md-8 order-md-2 shadow  rounded scroller my-4" *ngIf="showList">
      <div class="container-fluid vision-div ">
        <div class="row ">
          <div class="col-md-12  p-2 pt-4 my-2 fixed">
            <div class="text-center jh-heading">Latest News</div>

          </div>

          <div class="col-md-12 border-bottom blogShort my-2 " *ngFor="let notification of notificationsData">
            <article class="row mb-3">
              <div class="my-auto col-md-2 text-center ">
                <p class="fs-6 fw-bolder m-0 p-1">{{notification.month}} {{notification.date}}, {{notification.year}}
                </p>
              </div>

              <div class="col-md-10 text-center text-md-start">
                <div class="px-2 fw-bolder fs-5 my-auto">{{notification.notificationName}}</div>

                <a class="px-2" href="javascript:void(0)"
                  (click)="showNews(notification.notificationName,notification.notificationId)">READ MORE</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 order-md-3 js-hr-ads">
      <div class="col my-5">
        <app-horizontal-add2></app-horizontal-add2>
      </div>
    </div>
  </div>
</div>