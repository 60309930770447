import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../sevice/alert.service';
import { ChartsService } from '../../sevice/charts.service';
import { MembersService } from '../../sevice/members.service';
export class existMember {
  id;
  memberId;
  salutation;
  firstName;
  lastName;
  mobileNumber;
  whatsappNumber;
  designation;
  email;
  address;
  approvedBy;
  approvedDate;
  updatedDate;
  updateApprovedBy;
  activeStatus;
  transferredFrom;
  transferredDate;
  password;
  passwordUpdated;
  userType;
  plotNo;
  roadNo;
  plotarea;
  plotadmdate;
  memberType;
  profileImage;
}
@Component({
  selector: 'app-member-dashboard',
  templateUrl: './member-dashboard.component.html',
  styleUrls: ['./member-dashboard.component.scss']
})
export class MemberDashboardComponent implements OnInit {

  constructor(private chartService: ChartsService, private memberService: MembersService, private toastr: AlertService, public route: ActivatedRoute, public router: Router) { }
  memberDetails: any;
  membersTypeChart: any;
  existMember: existMember;
  requestsData: any;
  isAdmin:boolean=false;
  isMember:boolean=false;
  maintananeData:any;
  memberId;
  monthAndYear;
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

  ngOnInit(): void {
    this.existMember = new existMember();///Initializing the class from existMember model
    this.memberId=localStorage.getItem("authenticateId");
  
    this.monthAndYear=""+(this.monthNames[((new Date()).getMonth())]+","+((new Date()).getFullYear()))
    am4core.useTheme(am4themes_material);
    am4core.useTheme(am4themes_animated);

    let requestschart = am4core.create("requestsData", am4charts.XYChart);
    requestschart.events.on('hit', function(ev){
      this.router.navigate(['/requests']);
    },this)
    
    let requestTitle = requestschart.titles.create();
        requestTitle.text = "Request Details";
        requestTitle.fontSize = 25;
        requestTitle.marginBottom = 30;
        
 
    this.createCharts("memberRequests").then(data => {
      this.requestsData = data;
      requestschart.data = this.requestsData;
      this.getMemberDetailsByMemberId("existMember",this.memberId);
    })
    let categoryAxis = requestschart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "details";
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.fontSize = 11;
  
    categoryAxis.renderer.labels.template.dy = 5;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.baseGrid.disabled = true;
    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    
    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;

    
    let valueAxis = requestschart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.maxPrecision = 0;
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.grid.template.disabled=true;

    function createGrid(value) {
      var range = valueAxis.axisRanges.create();      
      range.setValue = value;
      range.label.text = "{value}";

      var range1 = categoryAxis.axisRanges.create();      
      range1.setValue = value;
      range1.label.text = "{value}";



   
    }




    
    createGrid(0);
    createGrid(25);
    createGrid(100);

    requestschart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    let series = requestschart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "details";
    series.dataFields.valueY = "count";
    series.columns.template.tooltipText = "{valueY.value}";
    series.columns.template.tooltipY = 0;
    series.columns.template.strokeOpacity = 0;
    // series.columns.template.strokeWidth = 0;
    series.columns.template.width = am4core.percent(40);
    requestschart.colors.list = [
      am4core.color("#ffd11a"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#ff3300"),
      am4core.color("#ff6600"),
      am4core.color("#009933"),
      am4core.color("#1a53ff")
    ];

   

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function (fill, target) {
      return requestschart.colors.getIndex(target.dataItem.index);
    });
  }
  vewProile(){
    this.router.navigate(['/member-profile',this.memberId]);
  }
   
  getMemberDetailsByMemberId(selection, memberId) {
    this.memberService.getMemberDetailsByMemberId(selection, memberId).subscribe((response: any) => {
      this.existMember = response['data'];
      if(this.existMember.profileImage == null || this.existMember.profileImage == undefined || this.existMember.profileImage == "")
      this.existMember.profileImage="/assets/images/images/Profile2.png";
  

    }, error => {
      this.toastr.showError("Failed to retrive data", "");
    })

  }
 

  async createCharts(selection) {
    let data = await this.chartService.create(selection,this.memberId).toPromise();
    return data;
  }





}
