import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        if(localStorage.getItem("accesstoken") != null &&localStorage.getItem("accesstoken") != undefined &&localStorage.getItem("accesstoken") != ''){
        const token  = atob(localStorage.getItem("accesstoken"));
      
        if (token != null  && token != "") {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
    }
        return next.handle(request);
    }
}