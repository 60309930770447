<div class="col-md-11 mx-auto shadow p-4 py-5 my-3">
  <div class="col-md-12 mx-auto">
    <div class="row">

      <div class="col-md-12">
          <div class="row">
          <div   class="col-md-auto">
              <button class="btn border jh-btn-primary bg-secondary text-white" *ngIf="isAdmin"  routerLink="/members">Back</button>
              <button class="btn border jh-btn-primary bg-secondary text-white" *ngIf="isAdmin == false"  routerLink="/Dashboard">Back</button>
            </div>
            <div class="text-center jh-heading col">
              Member Profile
            </div>
            <div   class="col-md-auto" *ngIf = "showUpdateMember && existMember.activeStatus!='Transferred' ">
              <button class="btn border jh-btn-primary jh-back-btn" (click) ="updateMember()" >Update</button>
            </div>
            <div   class="col-md-auto" *ngIf = "isNew">
              <button class="btn border jh-btn-primary jh-back-btn" (click) ="updateMember()" *ngIf="isAdmin">Save</button>
            </div>
          </div>
      </div>
      <div class="col-md-12 text-center">
          <form action="upload.php" method="post" enctype="multipart/form-data">
            <label for="fileToUpload">
          <div class="profile-pic shadow"  [ngStyle]="{ 'background': 'url(' + existMember.profileImage + ')' }">
              <span class="glyphicon glyphicon-camera"></span>
              <span *ngIf ="isAdmin">Change Image</span>
          </div>
          </label>
          <input type="File" name="fileToUpload" id="fileToUpload"  accept="image/*" (change)="onFileSelected($event)" >
          <!-- <input  type="file" accept="image/*,.doc,.docx,.txt,.pdf"
          [(ngModel)]="existMember.profileImage"  title="profilepic" name="profilePic" (change)="onFileSelected($event)"> -->
        </form>
      </div>

        <div class="col-md-10 mx-auto ">
          <div class="row">
          <div class="col-md-6 my-3 jh-login-input" *ngIf="isAdmin">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 required">Salutation</div>
            <select class='select-option col-md-12' [(ngModel)]="existMember.salutation" >
              <option value=''>Select</option>
              <option value='Mr'>Mr</option>
              <option value='Mrs'>Mrs</option>
              <option value='Ms'>Ms</option>
            </select>
            </div>
          </div>
          <div class="col-md-6 my-3 ">
            <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 required">First Name</div>
              <input type="text" class="form-control" id="" [(ngModel)]="existMember.firstName"
                placeholder="First Name" autocomplete="off"   [disabled] = "isAdmin==false" >
            </div>
          </div>
          <div class="col-md-6 my-3">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 required">Last Name</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.lastName"
              placeholder="Last Name" autocomplete="off"  [disabled] = "isAdmin==false">
              </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4">Email</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.email" placeholder="Email"
              autocomplete="off"  [disabled] = "isAdmin==false">
          </div>
          </div>
          
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 required">Member Id</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.memberId" placeholder="Member Id" autocomplete="off"  [disabled] = "!isNew">
          </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 required">Mobile Number</div>
            <input type="number" class="form-control" minlength="10" maxlength="10" id="" [(ngModel)]="existMember.mobileNumber"
              placeholder="Mobile number" autocomplete="off"   [disabled] = "isAdmin==false">
          </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 required">Whatsapp Number</div>
            <input type="number" class="form-control" id=""  [(ngModel)]="existMember.whatsappNumber"
              placeholder="Whatsapp number" autocomplete="off"  [disabled] = "isAdmin==false">
            </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 required">Address</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.address"
              placeholder="Address" autocomplete="off"  [disabled] = "isAdmin==false">
            </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 ">Plot No</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.plotNo"
              placeholder="Plot number" autocomplete="off"  [disabled] = "isAdmin==false">
            </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 ">Road No</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.roadNo"
              placeholder="Road number" autocomplete="off"  [disabled] = "isAdmin==false">
            </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input" *ngIf = "!isNew">
            <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 ">Member status</div>
              <input type="text" class="form-control" id=""  [(ngModel)]="existMember.activeStatus"
                placeholder=" Member status" autocomplete="off" disabled>
            </div>
            </div>
            <div class="col-md-6 my-3 jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 required">Member Type</div>
              <select class='select-option col-md-12' [(ngModel)]="existMember.memberType"  [disabled] = "isAdmin==false">
                <option value=''>Select</option>
                <option value='Member_resident'>Member_resident</option>
                <option value='Member_non_resident'>Member_non_resident</option>
                <option value='Non_member_resident'>Non_member_resident</option>
                <option value='Waitlisted_member'>Waitlisted_member</option>
              </select>
            </div>
            </div>
            <div class="col-md-6 my-3 jh-login-input" *ngIf = "existMember.transferredDate != null && existMember.transferredDate != '' &&existMember.transferredDate != undefined">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4">Transferred Date</div>
              <input class="form-control" id="" (click)="d.toggle()" placeholder="yyyy-mm-dd" ngbDatepicker
                #d="ngbDatepicker" [(ngModel)]="dateModel.transferredDate" disabled >
              <div class="input-group-append jh-dt-icon d-none">
                <button class="btn btn-outline-secondary calendar" type="button"></button>
              </div>
              </div>
            </div>
            <div class="col-md-6 my-3 jh-login-input"  *ngIf = "existMember.transferredFrom != null && existMember.transferredFrom != '' &&existMember.transferredFrom != undefined">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 " *ngIf = "existMember.activeStatus == 'Approved' ">Transferred from</div>
              <div class="form-label text-start ms-4 "  *ngIf = "existMember.activeStatus == 'Transferred' ">Transferred to</div>
              <input type="text" class="form-control" id="" [(ngModel)]="existMember.transferredFrom"
                placeholder="Transferred from" autocomplete="off" disabled>
              </div>
            </div>
            <!-- <div class="col-md-6 my-3 jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 ">Member type</div>
              <input type="text" class="form-control" id="" [(ngModel)]="existMember.userType"
                placeholder="User type" autocomplete="off" disabled>
              </div>
            </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4">Transferred Date</div>
            <input class="form-control" id="" (click)="d.toggle()" placeholder="yyyy-mm-dd" ngbDatepicker
              #d="ngbDatepicker" [(ngModel)]="dateModel.transferredDate" disabled>
            <div class="input-group-append jh-dt-icon d-none">
              <button class="btn btn-outline-secondary calendar" type="button"></button>
            </div>
            </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
            <div class="col-md-8 mx-auto jh-login-input">
            <div class="form-label text-start ms-4 ">Transferred from</div>
            <input type="text" class="form-control" id="" [(ngModel)]="existMember.transferredFrom"
              placeholder="Transferred from" autocomplete="off" disabled>
            </div>
          </div>
          <div class="col-md-6 my-3 jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 ">Approved By</div>
              <input type="text" class="form-control" id="" [(ngModel)]="existMember.approvedBy"
                placeholder="Approved By" autocomplete="off" disabled>
              </div>
            </div>
            <div class="col-md-6 my-3 jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 ">Approved date</div>
              <input type="text" class="form-control" id="" value="{{existMember.approvedDate | date : 'dd-MM-yyyy'}}"
                placeholder="Approved Date" autocomplete="off" disabled>
              </div>
            </div>
            <div class="col-md-6 my-3 jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 ">Update approved by</div>
              <input type="text" class="form-control" id="" [(ngModel)]="existMember.updateApprovedBy"
                placeholder="Update approved by" autocomplete="off" disabled>
              </div>
            </div>
            <div class="col-md-6 my-3 jh-login-input">
              <div class="col-md-8 mx-auto jh-login-input">
              <div class="form-label text-start ms-4 ">Update approved date</div>
              <input type="text" class="form-control" id="" value="{{existMember.updatedDate | date : 'dd-MM-yyyy'}}"
                placeholder="Updated approved updated" autocomplete="off" disabled>
              </div>
            </div> -->
          </div>
        </div>

    </div>

  </div>
</div>
