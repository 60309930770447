<marquee class="scrolling" behavior="" direction="" onmouseover="this.stop()" onmouseout="this.start()">
  <p >
    <a  href="javascript:void(0)"  *ngFor ="let notification of notificationsData" (click) = "showNews(notification.notificationName,notification.notificationId)">{{notification.notificationName}} </a>
  </p>
</marquee>

<nav class="navbar-expand-lg navbar-light jh-bg-dark ">
  <div class="container-fluid " [ngClass]="{'jh-menu-bar' : router.url === '/'}">
    <div class="row">
          <div class="col-md-2 text-center logo-div">
              <a class="navbar-brand" href="#">
              <img class="p-3" src="assets/images/jh-logo.png" alt="" width="120px">
            </a>
            <div class="jh-cursor" routerLink="/" >
              <p class="my-0">JUBILEE HILLS CO-OPERATIVE </p>
              <p class="my-0">HOUSE BUILDING SOCIETY </p>
            </div>

          </div>
          <div class="col-md-10 text-end">
            <div class="col-md-9 justify-content-end py-3 jh-vertical-ad">
              <app-vertical-ads></app-vertical-ads>
            </div>
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#jhNavBar" aria-controls="jhNavBar" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon bg-warning rounded"></span>
          </button>

          <div class="collapse navbar-collapse justify-content-center" id="jhNavBar">
            <ul class="nav justify-content-center" >
              <li class="nav-item" >
                <a class="nav-link"  (click)= "navigateTo('home')"  routerLink="/"  href="#"  routerLinkActive="active" >Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/aboutus" routerLinkActive="active">
                  <div class="dropdown">
                    <div class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      About Us
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li (click)= "navigateTo('overview')" ><a class="dropdown-item" href="#" >Overview</a></li>
                      <li (click)= "navigateTo('officebearers')" ><a class="dropdown-item" href="/about">Office Bearers</a></li>
                      <li (click)= "navigateTo('committee-members')" ><a class="dropdown-item" href="/about">Committee Members</a></li>
                      <li (click)= "navigateTo('jubilee-hills')" ><a class="dropdown-item" href="/about">Jubilee Hills</a></li>
                    </ul>
                  </div>

                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/phase4" routerLinkActive="active" >Phase IV</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/president-desk" routerLinkActive="active" >President's Desk</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/latest-news" routerLinkActive="active">Latest News</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/notice-board" routerLinkActive="active">Notice Board</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/our-services" routerLinkActive="active" >Our Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/photo-gallery" routerLinkActive="active">Photo Gallery</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
              </li>

              <li class="nav-item">
                <!-- <a class="nav-link" routerLink="/login" routerLinkActive="active">Login</a> -->

                <a class="nav-link" (click)= "navigateTo('none')"  routerLink="/login" routerLinkActive="active" *ngIf="!isLogedin">
                  <div class="dropdown">
                    <div class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Login
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" (click)= "navigateTo('StaffLogin')"  href="#">Staff Login</a></li>
                      <li><a class="dropdown-item" (click)= "navigateTo('MemberLogin')" href="#">Member Login</a></li>
                    </ul>
                  </div>

                </a>
                <a class="nav-link" (click)= "navigateTo('none')"   routerLinkActive="active" *ngIf="isLogedin">
                  <div class="dropdown">
                    <div class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Profile
                    </div>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" routerLink="/dashboard"  *ngIf="isAdmin">Dashboard</a></li>
                      <li><a class="dropdown-item" routerLink="/Dashboard"  *ngIf="!isAdmin">Dashboard</a></li>
                      <li><a class="dropdown-item" routerLink="/staffLogin"  (click)= "navigateTo('logout')" >Logout</a></li>
                    </ul>

                  </div>

                </a>

              </li>

            </ul>
          </div>
    </div>
  </div>
</nav>

<!-- notification -->

<span class="fa-stack fa-3x jh-bell-notifi d-none" data-count="28">
  <i class="fa fa-circle fa-stack-1x"></i>
  <i class="fa fa-bell fa-stack fa-inverse"></i>
</span>
