<div class="container-fluid notice-board-pg ">
  <div class="row align-items-start text-center1">

      <div class="col-md-2 order-md-1 js-hr-ads">
          <div class="col my-5">
              <app-horizontal-ads></app-horizontal-ads>
          </div>
      </div>
      <div class="col-md-8 order-md-2 shadow p-5 my-4 rounded " *ngIf ="showSingleNotice">
        <div class="text-center jh-heading"><h3>{{notificationObj.notificationName}}</h3></div>     
        <div [innerHTML] = "notificationObj.content | safeHtml"></div>
        <div><a [href]=notificationObj.contentUrl target="_blank">{{fileName}}</a></div>
      </div>
      <div class="h-100 col-md-8 order-md-2 shadow  my-4 rounded scroller" *ngIf ="showList">
          <div class="container-fluid vision-div">
              <div class="row">
                  <div class="col-md-12 p-2 pt-4 my-2 fixed">
                      <div class="text-center jh-heading">Notice Board</div>
                  </div>

                  <div class="col-md-4 my-4 blogShort my-2 " *ngFor= "let notice of noticesData">
                    <div class="col-md-12 p-3 shadow jh-noticeboard">
                    <article class="d-flex  text-center">
                      <div class="row">
                        <div class="px-2  fw-bolder fs-6">{{notice.month}} {{notice.date}}, {{notice.year}}</div>
                        <div class="col-md-12 ">
                          {{notice.notificationName}}
                        </div>
                        <a class="" href="javascript:void(0)" (click) = "showNews(notice.notificationName,notice.notificationId)">READ MORE</a>
                      </div>
                      </article>
                      </div>
                  </div>
                  

              </div>
          </div>
      </div>
      <div class="col-md-2 order-md-3 js-hr-ads">
          <div class="col my-5">
              <app-horizontal-add2></app-horizontal-add2>
          </div>
      </div>
  </div>
</div>

