<div class="container-fluid">
    <div class="row align-items-start text-center">
      <div class="col-md-2 order-md-1 my-auto ">
        <div class="js-hr-ads col my-5">
            <app-horizontal-ads></app-horizontal-ads>
        </div>
      </div>
      <div class="h-100 col-md-8 order-md-2 shadow p-5 my-4 rounded jh-login-form">
        <div class="col-md-12">
            <div class="row">
            <div   class="col-md-auto">
                <button class="btn border jh-btn-primary bg-secondary text-white" routerLink="/memberLogin" >Back</button>
              </div>
              <div class="text-center jh-heading col">
                REGISTRATION
              </div>

            </div>
        </div>   
         <div class="col-md-11 mx-auto">
             <div class="row">    
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Salutation</div>
                    <select class='select-option col-md-12' [(ngModel)]="registerDetails.salutation" >
                      <option value=''>Select</option>
                      <option value='Mr'>Mr</option>
                      <option value='Mrs'>Mrs</option>
                      <option value='Ms'>Ms</option>
                    </select>
                    </div>       
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">First Name</div>
                    <input type="text" class="form-control" [(ngModel)] ="registerDetails.firstName" [ngModelOptions]="{standalone: true}" id="" placeholder="First Name" autocomplete="off">
                </div>               
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Last Name</div>
                    <input type="text" class="form-control" [(ngModel)] ="registerDetails.lastName" [ngModelOptions]="{standalone: true}" id="" placeholder="Last Name" autocomplete="off">
                </div>
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Email</div>
                    <input type="text" class="form-control" [(ngModel)] ="registerDetails.email" [ngModelOptions]="{standalone: true}" id="" placeholder="Email" autocomplete="off">
                </div>
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4">Member Id</div>
                    <input type="text" class="form-control" id="" [(ngModel)] ="registerDetails.memberId" [ngModelOptions]="{standalone: true}" placeholder="Member Id" autocomplete="off">
                </div>
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Mobile Number</div>
                    <input type="number" class="form-control" id="" [(ngModel)] ="registerDetails.mobileNumber" [ngModelOptions]="{standalone: true}" placeholder="Mobile number" autocomplete="off">
                </div>
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Whatsapp Number</div>
                    <input type="number" class="form-control" id="" [(ngModel)] ="registerDetails.whatsappNumber" [ngModelOptions]="{standalone: true}" placeholder="Whatsapp number" autocomplete="off">
                </div>
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Plot No</div>
                    <input type="text" class="form-control" id="" [(ngModel)] ="registerDetails.plotNo" [ngModelOptions]="{standalone: true}" placeholder="Plot number" autocomplete="off">
                </div>
                <div class="col-md-6 my-3 jh-login-input">
                    <div class="form-label text-start ms-4 required">Road No</div>
                    <input type="text" class="form-control" id="" [(ngModel)] ="registerDetails.roadNo" [ngModelOptions]="{standalone: true}" placeholder="Road number" autocomplete="off">
                </div> 
                <div class="col-12">
                    <div class="captcha"></div> 
                </div> 
            </div>   
            <div class="col-auto">
                <div class ="col-12">
                    <input type ="checkbox" class ="px-1"  [(ngModel)]="selected"><span class ="px-1">I agree to the</span><a  class ="px-1" [routerLink]="" (click)="goto()">Terms and Conditions</a>
                </div>
                <button type="submit" class="btn jh-btn-primary submit-btn my-3 shadow col-auto px-3" (click) ="register()">Submit</button>
            </div>       
         </div>     
      </div>
      <div class="col-md-2 order-md-3 my-auto">
        <div class="js-hr-ads col my-5">
            <app-horizontal-add2></app-horizontal-add2>
        </div>
      </div>
    </div>
  
  </div>


  <!-- New code here -->



