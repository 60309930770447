import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
  animations: [
    trigger('changeDivSize1', [
      state('Closed', style({
        top:'0px',
        position:'absolute',
        height: '1838px',
        width:'50%',
        opacity:'1'
      })),
      state('Open', style({
        top:'0px',
        position:'absolute',
        height: '1838px',
        width:'2%',
        opacity:'0'

      })),
      transition('Closed=>Open', animate('6007ms'))
    ]),
    trigger('changeDivSize2', [
      state('Closed', style({
        top:'0px',
        position:'absolute',
        height: '1838px',
        width:'50%',
        opacity:'1'
      })),
      state('Open', style({
        top:'0px',
        position:'absolute',
        height: '1838px',
        width:'2%',
        opacity:'0'
      })),
      transition('Closed=>Open', animate('6007ms'))
    ]),
  ]
  
})
export class LandingpageComponent implements OnInit {
   audio = new Audio();

  constructor(public router: Router) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
.subscribe((s: NavigationEnd) => {
    if(s['url'] == "/welcome") {  
       this.Label = "Sri. K.T. Rama Rao Garu.";
       this.Designation = "(Hon’ble Minister for IT, Industries, MA & UD)"
     } else if(s['url'] == "/launch") { 
      this.Label = "Sri M. Veera Brahmaiah Garu, IAS";
      this.Designation = "(Commissioner for Co-operation & Registrar of Co-operative Societies)"
      }
     else{
        this.Label= "Sri. S. Niranjan Reddy Garu.";
        this.Designation= "(Minister of Agriculture, Co-operation and Marketing)";
    }
});
   }
  currentState = "Closed";
  BannerDetails = "show";
  Label = "";
  Designation ="";
  ngOnInit(): void {
   this.audio.src = "assets/images/music-v1.mp3";
    // console.log(this.router.url);
  }

 open_curtain()
{
 
  this.BannerDetails="hide";
  setTimeout( () => {
    this.playAudio();
    this.currentState= "Open";
  } ,500);
  
  setTimeout( () => 
  {  
    // this.stopAudio();
    this.router.navigate(['/home'])

  }, 6008);

}
playAudio(){
 this.audio.load();
  this.audio.play();
}
stopAudio(){
  this.audio.pause();
}

}
