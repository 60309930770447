<div class="container-fluid">
    <div class="row">
        <div class="col-md-2 my-3" *ngIf = "isAdmin">
            <side-nav-bar></side-nav-bar>
        </div>
        <div class="col-md-2 my-3" *ngIf="isMember">
            <div id="mySidenav" class="sidenav">
                <a href="/dashboard" class="jh-nav-req-active" routerLink="/dashboard">Dashboard</a>
               <a href="/member-profile/{{memberId}};" routerLink="/member-profile/{{memberId}}">Profile</a>
                <a href="/change-password/{{memberId}}" routerLink="/change-password/{{memberId}}">Change password</a>
               <a href="/requests" routerLink="/requests" >Raise a request</a> 
               <a href="/minutes-of-meeting" routerLink="/minutes-of-meeting">Minutes of meeting</a>
           
              <!-- <a href="/memberLogin">Logout</a> -->
            </div>
          </div>

        <div class="col-md-10 my-3">


            <div class="container-fluid px-0">
                <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
                    <div class="text-center jh-heading">Dashboard</div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 jh-grh-dash" >
                                <div class="col-md-11 shadow mx-auto my-3 p-3" id="memberDetails"></div>
                            </div>
                            <div class="col-md-6 jh-grh-dash">
                                <div class="col-md-11 shadow mx-auto my-3 p-3" id="memberTypesChart"></div>
                            </div>
                            <div class="col-md-6 jh-grh-dash">
                                <div class="col-md-11 shadow mx-auto my-3 p-3" id="requestsData"></div>
                            </div>
                        
                            <div class="col-md-6 jh-grh-dash">
                                <!-- <input type="text" id="fname"  [(ngModel)]="monthAndYear"name="fname" style="position: absolute;right: 80px;transform: translate(-16px, 16px);" disabled> -->
                                <div class="col-md-11 shadow mx-auto my-3 p-3" id="maintananceData">
                                </div>
                            </div>
                        </div>
                        <div class="row"  *ngIf = "!isAdmin">
                            <div class="col-md-12 jh-grh-dash" >
                                <div class="col-md-11 shadow mx-auto my-3 p-3" id="requestsData"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>