<div class="container-fluid">
    <div class="row">

        <div class="col-md-2 my-3">
            <div id="mySidenav" class="sidenav">
                <a href="/Dashboard" routerLink="/Dashboard">Dashboard</a>
                <a href="/member-profile/{{memberId}};" routerLink="/member-profile/{{memberId}}">Profile</a>
                <a href="/change-password/{{memberId}}" routerLink="/change-password/{{memberId}}">Change password</a>
                <a href="/requests" routerLink="/requests">Requests</a>
                <a href="/minutes-of-meeting" routerLink="/minutes-of-meeting">Minutes of meeting</a>
                <!-- <a href="/poll-management" routerLink="/poll-management">Poll Management</a> -->
                <!-- <a href="/memberLogin">Logout</a> -->
            </div>
        </div>

        <div class="col-md-10 my-3">


            <div class="container-fluid px-0">
                <div class="h-100 col-md-12 mx-auto shadow p-5 my-4 rounded">
                    <div class="text-center jh-heading">Dashboard</div>
                    <div class="col-md-12">
                        <div class="row">

                            <div class="col-md-6 jh-grh-dash">
                                <div class="col-md-11 mx-auto my-3 card shadow">

                                        <div class="card-header bg-dark">
                                            <!-- <div class=" profile-pic-wrappe text-center" ">
                                                <img [src]="existMember.profileImage" class="rounded-circle img-thumbnail " width="100" height="100"
                                                    alt="Mads Obel">
                                            </div> -->

                                            
                                            <div class="profile-pic" style="transform: translate(0px, 68px);">
                                                <label class="-label" for="file">

                                                </label>
                                                <!-- <input id="file" type="file" onchange="loadFile(event)"/> -->
                                                <img [src]="existMember.profileImage" id="output" width="200" />
                                            </div>

                                        </div>

                                        <div class="card-body text-center" style="margin-top: 62px;">
                                            <h5 class="card-title my-3" style="font-weight: 800;">{{existMember.firstName}} {{existMember.lastName}}</h5>
                                            <div class="col-md-auto" style="font-size: 14px;">
                                                <p class="my-2"><b>MemberId:</b> {{existMember.memberId}}</p>
                                                <p class="my-2"><b>MemberType:</b> {{existMember.memberType}}</p>
                                                <p class="my-2"><b>Email:</b> {{existMember.email}}</p>
                                                <p class="my-2"><b>Contact:</b> {{existMember.mobileNumber}}</p>
                                                <!-- <p class="my-2"><b>PlotNo:</b> {{existMember.plotNo}}</p> -->
                                                <!-- <p class="my-2"><b>RoadNo:</b> {{existMember.roadNo}}</p> -->
                                            </div>
                                            <button class="btn border jh-btn-primary jh-back-btn my-1" (click) ="vewProile()">View Profile</button>
                                        </div>

                                   
                                </div>
                            </div>
                            <div class="col-md-6 jh-grh-dash">
                                <div class="col-md-11 shadow mx-auto my-3 p-3" id="requestsData"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>