<section class="jh-footer">
<div class="getintouch">
  <div class="col-md-12 p-4">
      <div class="row">
          <div class="col-md-10">
            <h4 class="px-2 text-start fw-bold p-2">Get in Touch!</h4>
              <div class="px-2 fw-bolder">Email or Call us with any enquiries, We would be happy to answer your queries.</div>
          </div>
          <div class="col-md-2 text-end fs-6">
            <div class="row">
              <div class="d-flex my-2">
                <i class="fa fa-phone my-auto" aria-hidden="true"></i>
                <span class="mx-2"> 040 23540642</span></div>
              <div class="d-flex my-2">
                <i class="fa fa-envelope my-auto" aria-hidden="true"></i>
                <span class="mx-2"> info@jhchbs.com</span>
              </div>

            </div>

          </div>
      </div>
  </div>
</div>


<div class="copyright">
  <div class="col-md-12 text-center" >
    <a  class ="px-1" [routerLink]="" (click)="goto()">Terms and Conditions</a>
  </div>
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-6">
              <span>Copyrights ©2021 Jubilee Hills Co-Operative House Building Society Ltd. All rights reserved</span>
          </div>
          <div class="col-md-6 text-end">
            <span>Designed by VSaaS Techonologies Pvt Ltd.</span>
          </div>
      </div>
  </div>
</div>

</section>
